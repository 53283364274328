import "./style/index.css";
import "./style/user-search-results.css";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Lesson from "./lesson";
import Message from "../common/message";
import SearchResultCommunityMessage from "./user-search-result-community-message";
import { setSearchResultsView } from "../../redux/userReducer";
import { v4 as uuid4 } from "uuid";
import SearchResultsFiles from "./user-search-result-files-desktop";

const SearchResults = ({ setShowView }) => {
  const [videoResults, setVideoResults] = useState([]);
  const [communityResults, setCommunityResults] = useState([]);
  const [filesResults, setFilesResults] = useState([]); //[{id, title, text, files, files_size}
  const [searchParams, setSearchParams] = useState({}); //[inVideo, inFiles, inCommunity, search
  const lessonsRedux = useSelector((state) => state.user.value.lessons);
  const messagesRedux = useSelector(
    (state) => state.allPermission.value.messages
  );
  const filesRedux = useSelector((state) => state.user.value.files);
  const searchParamsRedux = useSelector(
    (state) => state.user.value.searchParams
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSearchResultsView({ searchResultsView: true }));
    return () => {
      dispatch(setSearchResultsView({ searchResultsView: false }));
    };
  }, []);

  useEffect(() => {
    setSearchParams(searchParamsRedux);
  }, [searchParamsRedux]);
  useEffect(() => {
    setVideoResults([]);
    setCommunityResults([]);
    setFilesResults([]);

    if (searchParams.inVideo) {
      // search in video lessons
      lessonsRedux.forEach((obj) => {
        obj.lessons.forEach((lesson) => {
          if (
            lesson.title !== null &&
            lesson.title && // Check if lesson.title is defined
            lesson.title?.includes(searchParams.search)
          ) {
            setVideoResults((results) => [...results, lesson]);
          }
        });
      });
    }
    if (searchParams.inFiles) {
      // search in file lessons
      filesRedux.forEach((file) => {
        if (
          file.lesson_title &&
          file.lesson_title?.includes(searchParams.search)
        ) {
          setFilesResults((results) => [...results, file]);
        }
      });
    }
    if (searchParams.inCommunity) {
      // search in community messages

      messagesRedux.forEach((message) => {
        if (message.title && message.title?.includes(searchParams.search)) {
          setCommunityResults((results) => [...results, message]);
        }
      });
    }
  }, [searchParams]);

  return (
    <>
      <div className="search-results-wrapper">
        <div className="title">Search Results:</div>
        <div className={"results"}>
          {searchParams.inVideo && (
            <div
              className="video-results"
              style={{ height: !videoResults.length ? "20vh" : "60vh" }}
            >
              <h3>In Video:</h3>
              <div className="video-wrapper">
                {videoResults.length ? (
                  <>
                    {videoResults.map((lesson) => {
                      return (
                        <Lesson
                          setShowView={setShowView}
                          key={uuid4()}
                          lesson={lesson}
                        />
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div className="no-results">No Match </div>
                  </>
                )}
              </div>
            </div>
          )}
          {searchParams.inFiles && (
            <div
              className="files-results"
              style={{ height: !filesResults.length ? "20vh" : "50vh" }}
            >
              <h3>In Files:</h3>
              <div className="files-wrapper">
                {filesResults.length ? (
                  <>
                    {filesResults.map((file) => {
                      return <SearchResultsFiles file={file} key={uuid4()} />;
                    })}
                  </>
                ) : (
                  <>
                    <div className="no-results">No Match </div>
                  </>
                )}
              </div>{" "}
            </div>
          )}

          {searchParams.inCommunity && (
            <div
              className="community-results"
              style={{ height: !communityResults.length ? "20vh " : "40vh" }}
            >
              <h3>In Community:</h3>
              <div className="community-wrapper">
                {communityResults.length ? (
                  <>
                    {communityResults.map((message) => {
                      return (
                        <SearchResultCommunityMessage
                          message={message}
                          key={uuid4()}
                        />
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div className="no-results">No Match </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>{" "}
      </div>
    </>
  );
};

export default SearchResults;
