import "./style/login.css";

import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import LoginForm from "./loginForm";
import { useMediaQuery } from "react-responsive";
import RegisterForm from "./registerForm";
import { useNavigate } from "react-router-dom";
import Logos from "./logos";
// import { redirect } from "react-router-dom";

const Login = () => {
  const [mobileTab, setMobileTab] = useState("login");
  const navigate = useNavigate();
  const desktopView = useMediaQuery({ query: "(min-width:600px)" });
  useEffect(() => {
    // localStorage.getItem();
  }, []);
  return (
    <>
      <div className="login-container">
        {/* redefine logos  */}
        <Logos />
        <h1 className="login-headline">Nice to MeatU</h1>

        {desktopView ? (
          <>
            <main className="login-boxes">
              <div className="login">
                <h3>Login</h3>

                <LoginForm formClass="desktop" inputClass="desktop" />
              </div>
              <div className="register">
                <h3> Register New User</h3>

                <RegisterForm formClass="desktop" inputClass="desktop" />
              </div>
            </main>
          </>
        ) : (
          // mobile view
          <div className="mobile-tabs">
            <Tabs
              id="controlled-tab-example"
              activeKey={mobileTab}
              defaultActiveKey={"login"}
              onSelect={(k) => setMobileTab(k)}
              className="tabs-wrapper"
            >
              <Tab eventKey="login" title="Login">
                <LoginForm formClass="mobile" inputClass="mobile" />
                <div className="forgot-password">
                  <button
                    className="forgot-password-btn"
                    onClick={() => navigate("/reset-password")}
                  >
                    Forgot Password?
                  </button>
                </div>
              </Tab>

              <Tab eventKey="register" title="Register">
                <RegisterForm formClass="mobile" inputClass="mobile" />
              </Tab>
            </Tabs>
          </div>
        )}

        <div className="credit">Created with Love by Hippocampus</div>
      </div>
    </>
  );
};

export default Login;
