import logo1 from "../img/logo.png";
import logo2 from "../img/logo2.png";
import { slide as Menu } from "react-burger-menu";
import { useState } from "react";
import { RiDownloadFill } from "react-icons/ri";
import { MdOutlineMessage } from "react-icons/md";
import { TiThList } from "react-icons/ti";
import { IconContext } from "react-icons";
import "./style/user-sidenav-mobile.css";
import { useMediaQuery } from "react-responsive";
import { setMobileMenu } from "../../redux/userReducer";
import { useDispatch } from "react-redux";
import { MdOutlineAttachEmail } from "react-icons/md";
import { SlLogout } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
const UserMobileNavbar = ({ setShowView }) => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const dispatch = useDispatch();
  // const widthForSidebarItem = useMediaQuery({ query: "(min-width:500px)" });
  const navigate = useNavigate();
  const handleSideNavOpen = () => {
    dispatch(setMobileMenu({ mobileMenuOpen: true }));
    setIsSideNavOpen(!isSideNavOpen);
  };
  const handleIsSideNavClosed = () => {
    dispatch(setMobileMenu({ mobileMenuOpen: false }));

    setIsSideNavOpen(false);
  };
  const handleMenuClicked = (context) => {
    setShowView(context);
    handleIsSideNavClosed();
  };

  const handleContactUs = (e) => {
    const tempLink = document.createElement("a");
    tempLink.href = "mailto:meatu@redefinemea.com";
    tempLink.style.display = "none"; // Hide the element
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  };
  const handleSignOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    navigate("/login");
  };
  return (
    <>
      <div className="user-mobile-navbar">
        <Menu
          className="user"
          isOpen={isSideNavOpen}
          onOpen={handleSideNavOpen}
          onClose={handleIsSideNavClosed}
        >
          <div className="menu-wrapper">
            <div
              className="menu-item"
              onClick={() => handleMenuClicked("all-section")}
            >
              <IconContext.Provider
                value={{ color: "#ffffff", size: "1.5rem" }}
              >
                <TiThList />
              </IconContext.Provider>
              <span> All sections</span>
            </div>
            <div
              className="menu-item all-sections "
              onClick={() => handleMenuClicked("user-mobile-files-downloads")}
            >
              <IconContext.Provider
                value={{ color: "#ffffff", size: "1.5rem" }}
              >
                <RiDownloadFill />
              </IconContext.Provider>

              <span> Files and downloads</span>
            </div>
            <div
              className="menu-item"
              onClick={() => handleMenuClicked("user-mobile-community")}
            >
              <IconContext.Provider
                value={{ color: "#ffffff", size: "1.5rem" }}
              >
                <MdOutlineMessage />
              </IconContext.Provider>
              <span> Community</span>
            </div>

            <div className="menu-item" onClick={handleContactUs}>
              <IconContext.Provider
                value={{ color: "#ffffff", size: "1.5rem" }}
              >
                <MdOutlineAttachEmail />
              </IconContext.Provider>
              <span>Contact Us</span>
            </div>
            <div className="menu-item" onClick={handleSignOut}>
              <IconContext.Provider
                value={{ color: "#ffffff", size: "1.5rem" }}
              >
                <SlLogout />
              </IconContext.Provider>
              <span>Sign Out</span>
            </div>
          </div>
        </Menu>
        <div>
          <img src={logo1} className="logo-center" alt="" />
        </div>
        <div>
          <img src={logo2} alt="" />
        </div>
      </div>
    </>
  );
};

export default UserMobileNavbar;
