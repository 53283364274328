import "./style/message-edit-delete.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setMessageEditDeletePopup,
  setDeleteFilePopup,
  setDeleteFileDetails,
  setMessages,
  setCurrentUserEmail,
} from "../../redux/reducer";
import { IconContext } from "react-icons";
import { IoMdClose } from "react-icons/io";
import { AiOutlineEye } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import Select from "react-select";
import { useMediaQuery } from "react-responsive";
import { BsUpload } from "react-icons/bs";
import { sliceText } from "../../utils/sliceText";
import fileService from "../../services/fileService";
import messageService from "../../services/messageService";
import { toast } from "react-toastify";
import { file } from "jszip";

const MessageEditDeletePopup = () => {
  const desktopView = useMediaQuery({ query: "(min-width:740px)" });
  const mobileView = useMediaQuery({ query: "(max-width:500px)" });

  const [errorMessage, setErrorMessage] = useState("");
  const [messageToEdit, setMessageToEdit] = useState({
    title: "", // Controlled input with an initial empty string
    location: "", // Controlled input with an initial empty string
    message: "", // Controlled input with an initial empty string
    directed_to1: null, // Uncontrolled input with an initial value of null
  });
  // const [multiple, setMultiple] = useState([]);
  const [filesFromMessage, setFilesFromMessage] = useState([]);
  const [newUploadedFiles, setNewUploadedFiles] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [databaseSelectedDistributor, setDatabaseSelectedDistributor] =
    useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const dispatch = useDispatch();
  const distributorsRedux = useSelector(
    (state) => state.superAdminSlice.value.distributorsList
  );
  const authorization = useSelector(
    (state) => state.allPermission.value.authorization
  );

  const formContextRedux = useSelector(
    (state) => state.allPermission.value.messageEditDeleteContext
  );
  const messageDetailsRedux = useSelector(
    (state) => state.allPermission.value.messageEditDeleteDetails
  );
  // const messageEditDeletePopupOpen = useSelector(
  //   (state) => state.allPermission.value.messageEditDeletePopupOpen
  // );
  // delete file popup if open change background color
  const deleteFilePopupOpen = useSelector(
    (state) => state.allPermission.value.deleteFilePopupOpen
  );

  // set message details
  useEffect(() => {
    setMessageToEdit({});
    if (Object.keys(messageDetailsRedux).length) {
      setMessageToEdit(messageDetailsRedux);
      setFilesFromMessage([]);
    }
  }, [formContextRedux, messageDetailsRedux]);
  useEffect(() => {
    if (messageToEdit?.files?.length) {
      let id = 0;
      const updatedFilesArray = messageToEdit.files.map((fileName) => ({
        id: id++,
        file: fileName,
      }));
      setFilesFromMessage(updatedFilesArray);
    }
  }, [messageToEdit.files]);
  // if super admin, set distributors
  useEffect(() => {
    if (authorization === "super-admin") {
      setDistributors(distributorsRedux);
    }
  }, [distributorsRedux]);

  // if super admin, set select options for directed_to1
  useEffect(() => {
    if (distributors.length) {
      setSelectOptions([{ value: 0, label: "All Users" }]);
      distributors.forEach((distributor) => {
        setSelectOptions((selectOptions) => [
          ...selectOptions,
          { value: distributor.id, label: distributor.distributor_name },
        ]);
      });
    }
  }, [distributors, messageToEdit]);

  // if super admin set "directed to" initial value
  useEffect(() => {
    if (selectOptions.length) {
      let selectedDistributor;
      if (Array.isArray(messageToEdit.directed_to1)) {
        // If it's already an array, use it as is
        selectedDistributor = messageToEdit.directed_to1;
      } else if (messageToEdit?.directed_to1.includes(",")) {
        const initialDistributors = messageToEdit.directed_to1
          .split(",")
          .map((item) => item.trim());
        selectedDistributor = selectOptions.filter((option) =>
          initialDistributors.includes(option.label.trim())
        );
      } else {
        const singleDistributor = selectOptions.filter(
          (option) => option.label.trim() === messageToEdit.directed_to1.trim()
        );
        selectedDistributor = singleDistributor;
      }
      // Use the spread operator to set the initial value
      setDatabaseSelectedDistributor([...selectedDistributor]);
    }
  }, [selectOptions]);

  const handleMessageFormClose = async (e = null) => {
    if (e !== null) {
      e.preventDefault();
    }

    dispatch(
      setMessageEditDeletePopup({
        messageEditDeletePopupOpen: false,
      })
    );
  };
  const handleFileChange = (e) => {
    setErrorMessage("");
    const selectedFiles = e.target.files;
    const filesArray = Array.from(selectedFiles);

    setNewUploadedFiles(filesArray);
  };

  const handleFormChanges = (e) => {
    setErrorMessage("");
    setMessageToEdit((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFileDelete = async (fileName) => {
    dispatch(
      setDeleteFileDetails({
        fileName,
        id: messageDetailsRedux.id,
        context: "message",
      })
    );
    dispatch(setDeleteFilePopup({ deleteFilePopupOpen: true }));
  };

  const handleUpdateMessage = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    // check if title is empty
    if (messageToEdit.title === "") {
      setErrorMessage("Title is required");
      return;
    }
    // check if message is empty
    if (messageToEdit.message === "") {
      setErrorMessage("Message is required");
      return;
    }

    // check if directed to is empty

    if (
      authorization === "super-admin" &&
      !databaseSelectedDistributor.length
    ) {
      setErrorMessage("Directed to is required");
      return;
    }
    // if (!databaseSelectedDistributor.length) {
    //   setErrorMessage("Directed to is required");
    //   return;
    // }

    // if includes "," then before update the message has multiple distributors
    let currentDistributers = [];
    if (messageToEdit?.directed_to1.includes(",")) {
      currentDistributers = messageToEdit.directed_to1
        .split(",")
        .map((item) => item.trim());
    } else {
      // if not includes "," then before update the message has single distributor
      currentDistributers = [messageToEdit.directed_to1];
    }

    // check if distributor is changed
    const distributorUpdated =
      JSON.stringify(databaseSelectedDistributor) !==
      JSON.stringify(currentDistributers);

    let messageUpdated = false;
    if (messageToEdit.message !== messageDetailsRedux.message) {
      messageUpdated = true;
    }

    let titleUpdated = false;
    if (messageToEdit.title !== messageDetailsRedux.title) {
      titleUpdated = true;
    }

    // check for new files
    let filesAdded = false;
    if (newUploadedFiles.length) {
      filesAdded = true;
    }
    if (
      !messageUpdated &&
      !titleUpdated &&
      !distributorUpdated &&
      !filesAdded &&
      messageToEdit.location === messageDetailsRedux.location
    ) {
      setErrorMessage("Nothing to update");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("title", messageToEdit.title);
      formData.append("location", messageToEdit.location);
      formData.append("messageBody", messageToEdit.message);

      if (newUploadedFiles.length) {
        for (let i = 0; i < newUploadedFiles.length; i++) {
          const filename = newUploadedFiles[i].name.replace(/\s+/g, "_");

          const fileBlob = new Blob([newUploadedFiles[i]], {
            type: newUploadedFiles[i].type,
          });
          formData.append("file", fileBlob, filename);
        }
      }

      if (distributorUpdated) {
        formData.append(
          "distributors",
          JSON.stringify(databaseSelectedDistributor)
        );
      } else {
        formData.append("distributors", JSON.stringify(currentDistributers));
      }

      // update message

      const updatedMessage = await messageService.updateMessage(
        formData,
        messageDetailsRedux.id
      );
      if (updatedMessage.message === "success") {
        // update redux
        dispatch(setMessages({ messages: updatedMessage.messages }));
        dispatch(
          setCurrentUserEmail({
            currentUserEmail: updatedMessage.currentUserEmail,
          })
        );
        handleMessageFormClose();
        toast.success("Message Updated ");
      }
    } catch (err) {
      toast.error("Something went wrong try again later");
      console.log(err, "err in update message");
    }
  };
  const handleDeleteMessage = async () => {
    try {
      let files = null;
      if (messageDetailsRedux?.files?.length) {
        files = messageDetailsRedux.files;
      }
      const data = {
        files,
        directed_to1: messageDetailsRedux.directed_to1,
      };
      const deletedMessage = await messageService.deleteMessage(
        messageDetailsRedux.id,
        files,
        messageDetailsRedux.directed_to1
      );
      if (deletedMessage?.message === "success") {
        dispatch(setMessages({ messages: deletedMessage.messages }));
        toast.success("Message Deleted");
        handleMessageFormClose();
      } else {
        toast.error("Failed to delete message");
      }
    } catch (err) {
      console.log(err, "err in delete message");
    }
  };
  return (
    <>
      <div
        className={
          deleteFilePopupOpen
            ? "delete-file-is-open message-edit-delete-popup-container"
            : "message-edit-delete-popup-container"
        }
        style={{ height: formContextRedux === "edit" ? "80vh" : "" }}
      >
        <div className="header">
          <div onClick={handleMessageFormClose}>
            <IconContext.Provider
              value={{
                color: "#000000",
                size: "2rem",
              }}
            >
              <IoMdClose />
            </IconContext.Provider>
          </div>
        </div>

        {formContextRedux === "delete" ? (
          // delete message
          <>
            <div className="edit-delete-title">
              <h1>Are you sure you want to delete this message?</h1>
            </div>
            <div className="message-delete-buttons">
              <button className="message-delete" onClick={handleDeleteMessage}>
                Yes, delete
              </button>
              <button
                className="message-delete-cancel"
                onClick={handleMessageFormClose}
              >
                No, keep it
              </button>
            </div>
          </>
        ) : (
          // edit message
          <>
            <div className="edit-delete-title">
              <h1>Edit Message</h1>
            </div>

            <form className="message-edit-form">
              <div className="form-wrapper">
                <label htmlFor="">Title:</label>
                <input
                  name="title"
                  type="text"
                  className=""
                  value={messageToEdit.title}
                  onChange={handleFormChanges}
                />
              </div>
              <div className="form-wrapper">
                <label htmlFor="">Location:</label>
                <input
                  onChange={handleFormChanges}
                  name="location"
                  value={messageToEdit.location}
                  type="text"
                  className=""
                />
              </div>
              {authorization === "super-admin" && (
                <>
                  <div className="form-wrapper">
                    <label htmlFor="">Directed To:</label>
                    <Select
                      isMulti
                      onChange={(e) => setDatabaseSelectedDistributor(e)}
                      options={selectOptions || []}
                      value={databaseSelectedDistributor || []} // Use an empty array as default if no values are present
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          height: "60px",
                          width: "40vw",
                          maxWidth: "400px",
                          backgroundColor: "rgba(242, 242, 242, 1)",
                          borderBottom: "1px solid #000000",
                          borderRadius: "12px 12px 0 0",
                        }),

                        indicatorsContainer: (baseStyles, state) => ({
                          ...baseStyles,
                          display: "none",
                        }),
                      }}
                    />
                  </div>
                </>
              )}

              <div className="form-wrapper">
                <label htmlFor="">Message:</label>

                <textarea
                  onChange={handleFormChanges}
                  value={messageToEdit.message}
                  name="message"
                  id=""
                ></textarea>
              </div>

              <div className="form-wrapper">
                <p>Files:</p>
                <div className="files">
                  {filesFromMessage?.length ? (
                    <>
                      {filesFromMessage.map((obj) => {
                        return (
                          <div className="file" key={obj.id}>
                            <div className="file-name">
                              {mobileView ? (
                                <span>{sliceText(obj.file, 5)}</span>
                              ) : (
                                <span>{sliceText(obj.file, 10)}</span>
                              )}
                            </div>
                            <div className="file-actions">
                              <div
                                className="file-preview"
                                onClick={() =>
                                  window.open(
                                    `https://s3.eu-north-1.amazonaws.com/meatu-img.redefinemeat.com/${obj.file}`,
                                    "_blank"
                                  )
                                }
                              >
                                <IconContext.Provider
                                  className="message-delete-icon"
                                  value={{
                                    color: "#000000",
                                    size: mobileView ? "0.8rem" : "1.2rem",
                                  }}
                                >
                                  <AiOutlineEye className="message-delete-icon" />
                                </IconContext.Provider>
                              </div>
                              <div
                                className="file-delete"
                                onClick={() => handleFileDelete(obj.file)}
                              >
                                <IconContext.Provider
                                  className="message-delete-icon"
                                  value={{
                                    color: "#000000",
                                    size: mobileView ? "0.8rem" : "1.2rem",
                                  }}
                                >
                                  <RiDeleteBin6Line className="message-delete-icon" />
                                </IconContext.Provider>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <p>No Files </p>
                    </>
                  )}
                </div>
              </div>

              {newUploadedFiles?.length ? (
                <>
                  <div className="form-wrapper ">
                    <p className="files-added">
                      {`Files added:${newUploadedFiles.length}`}{" "}
                    </p>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="form-wrapper">
                <div className="upload">
                  <IconContext.Provider
                    value={{
                      color: "#ffffff",
                      className: "global-class-name",
                      size: "20px",
                    }}
                  >
                    <BsUpload />
                  </IconContext.Provider>
                  <label className="add-files">
                    <input
                      type="file"
                      value=""
                      onChange={handleFileChange}
                      className="hidden"
                      multiple
                      // accept=".pdf"
                      name="file"
                      id="add-message-files"
                    />
                    <span>Add files</span>
                  </label>
                </div>
              </div>
              {errorMessage && (
                <>
                  <div className="error"> {errorMessage}</div>
                </>
              )}
              <div className="message-delete-buttons">
                <button
                  className="save-message"
                  onClick={(e) => handleUpdateMessage(e)}
                >
                  Save
                </button>
                <button
                  className="message-delete-cancel"
                  onClick={(e) => handleMessageFormClose(e)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
};

export default MessageEditDeletePopup;
