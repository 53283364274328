import { useState, useEffect } from "react";
import "./style/user-community.css";
import { useMediaQuery } from "react-responsive";
import { IconContext } from "react-icons";
import { v4 as uuid4 } from "uuid";
import { useSelector } from "react-redux";
import Message from "../common/message";

const UserCommunity = () => {
  const [newMessages, setNewMessages] = useState([]);
  const [archiveMessages, setArchiveMessages] = useState([]);
  const messagesRedux = useSelector(
    (state) => state.allPermission.value.messages
  );
  useEffect(() => {
    if (messagesRedux?.length) {
      const sortedMessages = [...messagesRedux].sort((a, b) => {
        if (a.pinned && !b.pinned) {
          return -1;
        }
        if (!a.pinned && b.pinned) {
          return 1;
        }

        // Sort by 'created_at' date (latest date first)
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB.getTime() - dateA.getTime();
      });

      sortedMessages.forEach((message) => {
        const currentDate = new Date();
        const givenDate = new Date(message.created_at);

        // Calculate the difference in milliseconds
        const diffrenceInMiliseconds = currentDate - givenDate;

        // Convert the difference in milliseconds to days
        const differenceInDays = diffrenceInMiliseconds / (1000 * 3600 * 24);

        if (differenceInDays > 30) {
          setArchiveMessages((prevArchiveMessages) => [
            ...prevArchiveMessages,
            message,
          ]);
        } else {
          setNewMessages((prevUnreadMessages) => [
            ...prevUnreadMessages,
            message,
          ]);
        }
      });
    }
  }, [messagesRedux]);
  return (
    <>
      <div>
        <h2 className="user-community-title">Community Messages</h2>
      </div>
      <div className="user-top-messages">
        {newMessages.length ? (
          <>
            {newMessages.map((item) => {
              return (
                <Message
                  item={item}
                  key={uuid4()}
                  customClassName="user-new-messages"
                />
              );
            })}
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="separator">
        <span>Archive</span>
      </div>
      <div className="user-archive-messages">
        {archiveMessages.length ? (
          <>
            {archiveMessages.map((item) => {
              return (
                <Message
                  item={item}
                  key={uuid4()}
                  customClassName="user-archive-messages"
                />
              );
            })}
          </>
        ) : (
          <>No messages</>
        )}
      </div>
    </>
  );
};

export default UserCommunity;
