import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconContext } from "react-icons";
import { IoIosAdd } from "react-icons/io";
import "./style/question-container.css";
import Question from "./question";

const QuestionContainer = ({
  lessonId,
  questionId,
  question,
  setError,
  setQuizData,
  quizData,
  indexOfQuestion,
}) => {
  return (
    <>
      <div className="question-container">
        <form>
          <Question
            setError={setError}
            question={question}
            questionId={questionId}
            setQuizData={setQuizData}
            quizData={quizData}
            indexOfQuestion={indexOfQuestion}
          />
        </form>
      </div>
    </>
  );
};

export default QuestionContainer;
