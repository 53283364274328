// import { IconContext } from "react-icons";
// import { BiMessageDetail } from "react-icons/bi";
// import { AiOutlinePieChart } from "react-icons/ai";
import "./style/mobile-footer.css";
import { useSelector } from "react-redux";
// import { BiUser } from "react-icons/bi";
const MobileFooter = () => {
  const isPopupOpen = useSelector(
    (state) => state.superAdminSlice.value.messagePopupOpen
  );
  return (
    <>
      <div
        className={isPopupOpen ? "mobile-footer dark-mode" : "mobile-footer"}
      >
        <div className="credit">Created with Love by Hippocampus</div>
      </div>
    </>
  );
};

export default MobileFooter;
