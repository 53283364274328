import "./style/rate-lesson.css";
import ReactStars from "react-rating-stars-component";
import CustomRatingIcon from "./rating-icon";
import { IconContext } from "react-icons";
import { BsArrowLeft } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import BackButton from "../common/backButton";
import lessonsService from "../../services/lessonsService";
import { setUserRatingIDFromDB } from "../../redux/userReducer";
const RateLesson = ({ setShowView, desktopView }) => {
  const changeSize = useMediaQuery({ query: "(min-width:450px)" });
  const [message, setMessage] = useState("");
  const [rating, setRating] = useState(0);
  const [currentLessonId, setCurrentLessonId] = useState(0);
  const [amountOfQuestionsInQuiz, setAmountOfQuestionsInQuiz] = useState(0);
  const [numberOfCorrectAnswers, setNumberOfCorrectAnswers] = useState(0);
  // const currentQuiz = useSelector((state) => state.user.value.currentQuiz);
  const quizInformationRedux = useSelector(
    (state) => state.user.value.quizFinished
  );
  const lessonIdRedux = useSelector(
    (state) => state.user.value.currentLessonDetails.lessonId
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (lessonIdRedux) setCurrentLessonId(lessonIdRedux);
  }, [lessonIdRedux]);
  useEffect(() => {
    if (quizInformationRedux.numberOfQuestionsOfQuiz) {
      setAmountOfQuestionsInQuiz(quizInformationRedux.numberOfQuestionsOfQuiz);
      setNumberOfCorrectAnswers(
        quizInformationRedux.numberOfCorrectAnswersOfQuiz
      );
    }
  }, [quizInformationRedux]);

  const handleRatingChange = (newRating) => {
    setMessage("");
    setRating(newRating);
  };

  const formatRating = (rating) => {
    const rated = Math.floor(rating);
    const maxCount = 5;
    return `${rated}/${maxCount}`;
  };

  const handleRating = async () => {
    setMessage("");
    if (rating === 0) return setMessage("Please rate this lesson");

    try {
      // send rating to server
      const rateLesson = await lessonsService.rateLesson(
        currentLessonId,
        rating,
        numberOfCorrectAnswers,
        amountOfQuestionsInQuiz
      );

      if (rateLesson.message === "success") {
        dispatch(
          setUserRatingIDFromDB({
            userRatingIDFromDB: rateLesson.recordId,
          })
        );
        setShowView("feedback");
      } else {
        console.log(rateLesson.message, "rate lesson message");
      }
    } catch (err) {
      console.log(err, "error sending rating");
    }
  };
  const handleBackBtn = () => {
    if (amountOfQuestionsInQuiz) {
      setShowView("quiz");
    } else {
      setShowView("video-player");
    }
  };
  return (
    <>
      {desktopView ? (
        <>
          <div className="rate-lesson-desktop-wrapper">
            <div className="rate-desktop-box">
              <div className="title">Please rate this lesson:</div>

              <div className="rating">
                <div className="hamburgers">
                  <ReactStars
                    classNames="desktop-hamburger"
                    count={5}
                    size={70}
                    a11y={true}
                    activeColor="#CB2A27"
                    filledIcon={<CustomRatingIcon />}
                    emptyIcon={<CustomRatingIcon />}
                    onChange={handleRatingChange}
                  />
                </div>
              </div>

              <div className="rating-in-numbers">
                <p>{formatRating(rating)}</p>
              </div>
              <div className="bottom">
                <div className="back" onClick={handleBackBtn}>
                  <IconContext.Provider
                    value={{
                      color: "#ffffff",
                      size: "1.4rem",
                      style: { marginTop: "1px", marginRight: "20px" },
                    }}
                  >
                    <BsArrowLeft />
                  </IconContext.Provider>
                  <span>Back</span>
                </div>

                <div className="error">{message}</div>
                <div className="submit" onClick={handleRating}>
                  <button>{`Next >>>`}</button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="rate-lesson-wrapper ">
            <div className="top">
              <div className="back" onClick={handleBackBtn}>
                <IconContext.Provider
                  value={{
                    color: "#ffffff",
                    size: "1.4rem",
                    style: { marginTop: "1px", marginRight: "20px" },
                  }}
                >
                  <BsArrowLeft />
                </IconContext.Provider>
                <span>Back</span>
              </div>
            </div>
            <div className="main">
              <div className="title">Please rate this lesson</div>

              <div className="rating">
                <div className="hamburgers">
                  <ReactStars
                    classNames="mobile-hamburger"
                    count={5}
                    size={40}
                    a11y={true}
                    activeColor="#CB2A27"
                    filledIcon={<CustomRatingIcon />}
                    emptyIcon={<CustomRatingIcon />}
                    onChange={handleRatingChange}
                  />
                </div>

                <div className="rating-in-numbers">
                  <p>{formatRating(rating)}</p>
                </div>
                <div
                  className="error"
                  style={{ textAlign: "center", fontSize: "1.2rem" }}
                >
                  {message}
                </div>
              </div>
              <div className="submit" onClick={handleRating}>
                <button>Next</button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RateLesson;
