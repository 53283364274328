import { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useMediaQuery } from "react-responsive";
import "../admin/style/admin-users.css";
import "./style/super-admin-users-dustributor.css";
import InviteMemberForm from "../common/invite-member-form";
import { IconContext } from "react-icons";
import { TbEditCircle } from "react-icons/tb";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Spinner from "react-bootstrap/Spinner";
import BackButton from "../common/backButton";
import { v4 as uuid4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import InviteDistributor from "./invite-distributor";
import { sliceText } from "../../utils/sliceText";
import {
  setEditFormType,
  setEditFormCompanyName,
  editFormProperties,
} from "../../redux/reducer";
import { FaCommentsDollar } from "react-icons/fa";
// import SuperInviteDistributorFormMobile from "./super-invite-distributor-form-mobile";

export const SuperAdminUsers = ({
  setShowView,
  setIsEditFormOpen,
  handleInvitationForm,
  inviteDistributorFormOpen,
  memberArrow,
  distributorArrow,
  inviteMemberFormOpenDesktop,
  // showMembersForm,
  setDistributorSelectOptions,
  distributorSelectOptions,
  showView,
}) => {
  const desktopView = useMediaQuery({ query: "(min-width:740px)" });

  const [usersAndDistributors, setUsersAndDistributors] = useState({});
  const [pendingUsers, setPendingUsers] = useState(null);

  // const [distributorSelectOptions, setDistributorSelectOptions] = useState([]);
  const [distributorsArray, setDistributorsArray] = useState([]);
  const [usersCompletionPercentage, setUsersCompletionPercentage] = useState(
    []
  );
  const distributorsAndUsersRedux = useSelector(
    (state) => state.superAdminSlice.value.distributorsAndAssociatedUsers
  );
  const superAdminStatsRedux = useSelector(
    (state) => state.superAdminSlice.value.superAdminStats
  );

  useEffect(() => {
    const uniqueNames = new Set();
    if (distributorsAndUsersRedux) {
      setDistributorsArray([]);
      const resultArray = [];

      for (const distributorStats of superAdminStatsRedux) {
        const distributorId = distributorStats.distributor_id;
        const distributorTitle = distributorStats.distributor_title;
        if (distributorTitle !== "Redefine") {
          for (const user of distributorStats.user_ids) {
            resultArray.push({
              userId: user.id,
              completionPercentage: user.completionPercentage,
              distributorId,
              distributorTitle,
            });
          }
        }
      }

      setUsersCompletionPercentage(resultArray);

      Object.keys(distributorsAndUsersRedux).forEach((nameOfDistributor) => {
        if (!uniqueNames.has(nameOfDistributor)) {
          uniqueNames.add(nameOfDistributor);
          const amountOfUsers =
            distributorsAndUsersRedux[nameOfDistributor].length;
          setDistributorsArray((prev) => [
            ...prev,
            {
              name: nameOfDistributor,
              id: uuid4(),
              amountOfUsers,
            },
          ]);
          // setUsersAndDistributors(distributorsAndUsersRedux);
          setDistributorSelectOptions(distributorSelectOptions);
        }
      });
    }
  }, [
    distributorsAndUsersRedux,
    desktopView,

    distributorSelectOptions,
    showView,
  ]);

  useEffect(() => {
    setUsersAndDistributors([]);
    setPendingUsers(null); // Initialize the pendingUsers state as an empty object

    const resultObject = {};
    const pendingUsersObject = {}; // Create a new object for pending users

    // Loop over the distributorsAndUsersRedux
    for (const key in distributorsAndUsersRedux) {
      const entries = distributorsAndUsersRedux[key];
      const registered = [];
      const pending = [];

      // Categorize entries into "registered" and "pending"
      for (const entry of entries) {
        if (entry?.active === 1) {
          registered.push(entry);
        } else {
          pending.push(entry);
        }
      }

      // Create a new object for the key with "registered" and "pending" arrays
      resultObject[key] = {
        registered,
        pending,
      };

      if (pending.length > 0) {
        pendingUsersObject[key] = pending;
      }
    }

    setPendingUsers(pendingUsersObject);
    setUsersAndDistributors(resultObject);
  }, [distributorsArray]);

  const dispatch = useDispatch();

  const handleEditForm = (item, context) => {
    // context = distributor / user
    dispatch(setEditFormType({ formType: context }));
    setIsEditFormOpen(true);
    const obj = {};
    if (item.name) {
      //open distributor edit popup and fetch distributor
      dispatch(setEditFormCompanyName({ companyName: item.name }));
      return;
    } else {
      // open user edit popup
      obj.name = `${item.first_name} ${item.last_name}`;
      obj.email = item.email;
      obj.phone = item.phone;
      obj.language = item.language;
    }

    dispatch(editFormProperties(obj));
  };

  return (
    <>
      <div className="super-admin-users admin-users">
        {desktopView ? (
          <>
            <h3>Users:</h3>

            <div
              className="invite-distributor"
              style={{
                borderRadius: inviteDistributorFormOpen
                  ? "12px 12px 0 0"
                  : "12px",
              }}
              onClick={() => handleInvitationForm("distributor")}
            >
              <div>
                <button>Create A New Distributor:</button>

                <IconContext.Provider
                  value={{
                    color: "#ffffff",
                    size: "1.3rem",
                  }}
                >
                  {distributorArrow ? <IoIosArrowDown /> : <IoIosArrowUp />}
                </IconContext.Provider>
              </div>
            </div>
            {inviteDistributorFormOpen && (
              <InviteDistributor
                inviteDistributorFormOpen={inviteDistributorFormOpen}
              />
            )}
            <div
              className="invite-member"
              style={{
                borderRadius: inviteMemberFormOpenDesktop
                  ? "12px 12px 0 0"
                  : "12px",
              }}
              onClick={() => handleInvitationForm("member")}
            >
              <div>
                <button>Invite New Member:</button>
                <IconContext.Provider
                  value={{
                    color: "#ffffff",
                    size: "1.3rem",
                  }}
                >
                  {memberArrow ? <IoIosArrowDown /> : <IoIosArrowUp />}
                </IconContext.Provider>
              </div>
            </div>
            {inviteMemberFormOpenDesktop && (
              <InviteMemberForm
                inviteMemberFormOpenDesktop={inviteMemberFormOpenDesktop}
                context="super"
                // showMembersForm={showMembersForm}
                distributorSelectOptions={distributorSelectOptions}
              />
            )}
          </>
        ) : (
          <BackButton
            setShowView={setShowView}
            title="Users"
            context="super-admin"
          />
        )}

        <div
          className={
            desktopView ? "accordion-wrapper" : "mobile-accordion-wrapper"
          }
        >
          <div className="admin-accordion">
            <>
              {distributorsArray.length ? (
                <>
                  {distributorsArray.map((item) => {
                    return (
                      <div key={item.id}>
                        <Accordion defaultActiveKey="1">
                          <Accordion.Item
                            eventKey={item.id}
                            className="admin-accordion-item"
                          >
                            <Accordion.Header className="admin-accordion-header">
                              <div>
                                <IconContext.Provider
                                  value={{
                                    color: "#ffffff",
                                    size: "1rem",
                                  }}
                                >
                                  <TbEditCircle
                                    onClick={() =>
                                      handleEditForm(item, "Distributor:")
                                    }
                                  />
                                </IconContext.Provider>
                                <span> {item.name} </span>
                                <span> | </span>
                                <span> {item.amountOfUsers}</span>
                              </div>
                            </Accordion.Header>

                            <Accordion.Body
                              className={
                                desktopView
                                  ? "super-accordion-abody"
                                  : "mobile-super-admin-accordion-body"
                              }
                            >
                              {usersAndDistributors[item.name]?.registered
                                .length ? (
                                <>
                                  {usersAndDistributors[
                                    item.name
                                  ]?.registered.map((member) => {
                                    return (
                                      <>
                                        <div
                                          key={member.user_id}
                                          className="member"
                                        >
                                          <div className="member-name">
                                            <p>
                                              {sliceText(member.first_name, 7)}
                                            </p>
                                          </div>
                                          <div className="member-email">
                                            {sliceText(member.email, 9)}
                                          </div>
                                          <div className="auth_level">
                                            {parseInt(member.auth_level) === 1
                                              ? "user"
                                              : "admin"}
                                          </div>
                                          <div className="percent">
                                            {usersCompletionPercentage.find(
                                              (user) =>
                                                user?.userId === member.user_id
                                            )?.completionPercentage ?? 0}
                                            %
                                          </div>
                                          <div className="member-percent">
                                            <div
                                              onClick={() =>
                                                handleEditForm(member, "User:")
                                              }
                                            >
                                              <IconContext.Provider
                                                value={{
                                                  color: "#CB2A27",
                                                  size: "1.2em",
                                                }}
                                              >
                                                <TbEditCircle />
                                              </IconContext.Provider>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <div className="loading">
                                    <h1>No Registered Users </h1>
                                  </div>
                                </>
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <div className="loading">
                    <h1>Loading...</h1>
                    <Spinner animation="border" role="status">
                      <span className="sr-only"></span>
                    </Spinner>
                  </div>
                </>
              )}
              {pendingUsers !== null && (
                <div
                  className="pending-users"
                  style={{
                    backgroundColor: desktopView ? "#000000" : "#cb2a27",
                  }}
                >
                  <h3>Pending users </h3>
                  <div className="header">
                    <div>Distributor</div>
                    <div>Invited User</div>
                    <div>Invited At</div>
                  </div>

                  {Object.keys(pendingUsers).map((distributor) => {
                    return pendingUsers[distributor].map((user) => {
                      return (
                        <div className="pending-user">
                          <div className="pending-user-name">
                            {user.distributor_name}
                          </div>
                          <div className="pending-user-email">{user.email}</div>

                          <div className="pending-user-date">
                            {new Date(user.invitation_date).toLocaleString()}
                          </div>
                        </div>
                      );
                    });
                  })}
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

// export default SuperAdminUsers;
