import { createSlice } from "@reduxjs/toolkit";

export const allPermissionSlice = createSlice({
  name: "allPermission",
  initialState: {
    value: {
      editForm: {
        name: "",
        email: "",
        phone: "",
        language: "",
        formTitle: "",
        formType: "",
        companyName: "",
      },
      messages: [],
      notifications: [],
      selectedNotification: {
        message: "",
        id: "",
        created_by: "",
        type: "",
      },
      currentUserEmail: "",
      searchItemMobile: [],
      listOfAlreadyReadNotifications: null,
      messagePopupOpen: false,
      messageEditDeletePopupOpen: false,
      messageEditDeleteContext: null,
      messageEditDeleteDetails: {},
      deleteFilePopupOpen: false,
      deleteFileDetails: {
        fileName: "",
        id: " ",
        context: "",
        sectionId: null,
        deletionContext: "",
      },
      loggedIn: false,
      title: "test",
      body: "",
      notificationsPopupIsOpen: false,
      authorization: "",
      editFormIsOpen: false,
    },
  },

  reducers: {
    setListOfAlreadyReadNotifications: (state, action) => {
      const { listOfAlreadyReadNotifications } = action.payload;

      state.value.listOfAlreadyReadNotifications =
        listOfAlreadyReadNotifications;
    },
    setSelectedNotification: (state, action) => {
      const { id, message, created_by, type } = action.payload;
      state.value.selectedNotification = {
        id,
        message,
        created_by,
        type,
      };
    },
    setMessages: (state, action) => {
      const { messages } = action.payload;
      state.value.messages = messages;
    },
    setNotifications: (state, action) => {
      const { notifications } = action.payload;

      state.value.notifications = notifications;
    },
    setNotificationsPopupIsOpen: (state, action) => {
      const { notificationsPopupIsOpen } = action.payload;
      state.value.notificationsPopupIsOpen = notificationsPopupIsOpen;
    },

    checkEditFormIsOpen: (state, action) => {
      const { editFormIsOpen } = action.payload;
      state.value.editFormIsOpen = editFormIsOpen;
    },
    editFormProperties: (state, action) => {
      const { name, email, phone, language, formTitle } = action.payload;
      state.value.editForm = {
        name,
        email,
        phone,
        language,
        formTitle,
      };
    },
    setEditFormCompanyName: (state, action) => {
      const { companyName } = action.payload;
      state.value.editForm.companyName = companyName;
    },
    setEditFormType: (state, action) => {
      const { formType } = action.payload;
      state.value.editForm.formType = formType;
    },
    setAuthorization: (state, action) => {
      const { authorization } = action.payload;
      state.value.authorization = authorization;
    },

    createNewMessagePopup: (state, action) => {
      const {
        newMessage: { title, location, message, directedTo, files },
      } = action.payload;
      state.value.newMessage = {
        title,
        location,
        message,
        directedTo,
        files,
      };
    },
    setMessagePopup: (state, action) => {
      const { messagePopupOpen } = action.payload;
      state.value.messagePopupOpen = messagePopupOpen;
    },
    setSearchItemMobile: (state, action) => {
      const { searchItemMobile } = action.payload;
      state.value.searchItemMobile = searchItemMobile;
    },
    setMessageEditDeletePopup: (state, action) => {
      const { messageEditDeletePopupOpen } = action.payload;
      state.value.messageEditDeletePopupOpen = messageEditDeletePopupOpen;
    },
    setMessageEditDeleteContext: (state, action) => {
      const { messageEditDeleteContext } = action.payload;
      state.value.messageEditDeleteContext = messageEditDeleteContext;
    },
    setMessageEditDeleteDetails: (state, action) => {
      const { messageEditDeleteDetails } = action.payload;
      state.value.messageEditDeleteDetails = messageEditDeleteDetails;
    },
    setDeleteFilePopup: (state, action) => {
      const { deleteFilePopupOpen } = action.payload;
      state.value.deleteFilePopupOpen = deleteFilePopupOpen;
    },
    setDeleteFileDetails: (state, action) => {
      const {
        fileName,
        id,
        context,
        sectionId = null,
        deletionContext = null,
      } = action.payload;
      state.value.deleteFileDetails = {
        fileName,
        id,
        context,
        sectionId,
        deletionContext,
      };
    },
    setCurrentUserEmail: (state, action) => {
      const { currentUserEmail } = action.payload;
      state.value.currentUserEmail = currentUserEmail;
    },
  },
});

export const {
  setCurrentUserEmail,
  setDeleteFileDetails,
  setDeleteFilePopup,
  setMessageEditDeleteDetails,
  setMessageEditDeleteContext,
  setMessageEditDeletePopup,
  notificationsPopup,
  editFormProperties,
  checkEditFormIsOpen,
  setAuthorization,
  setEditFormType,
  setEditFormCompanyName,
  setMessagePopup,
  setMessages,
  setNotifications,
  setNotificationsPopupIsOpen,
  setSelectedNotification,
  setListOfAlreadyReadNotifications,
  setSearchItemMobile,
} = allPermissionSlice.actions;

export default allPermissionSlice.reducer;
