import "./style/invite-distributor.css";
import { useState } from "react";
import services from "../../services/userService";
import { toast } from "react-toastify";
import { setDistributorsList } from "../../redux/superAdminReducer";
import { useDispatch } from "react-redux";
const InviteDistributor = () => {
  const [distributorError, setDistributorError] = useState("");
  const [distributorName, setDistributorName] = useState("");
  const dispatch = useDispatch();
  const handleDistributorInvite = async (e) => {
    e.preventDefault();

    if (distributorName === "") {
      setDistributorError("Enter Company Name");
      return;
    } else if (distributorName.length < 2) {
      setDistributorError("Company name 2 characters Minimum");
      return;
    } else {
      setDistributorError("");
    }

    try {
      const request = await services.createDistributor(distributorName);

      if (request.message === "success") {
        toast.success("New Distributor Created");

        dispatch(
          setDistributorsList({ distributorsList: request.distributors })
        );
      }
    } catch (err) {
      console.log(err, "invite distributor");
      toast.error(err);
    }
  };
  return (
    <>
      <form className="invite-distributor-form">
        <div className="form-wrapper">
          <input
            type="text"
            placeholder="Company Name:"
            value={distributorName}
            onChange={(e) => setDistributorName(e.target.value)}
          />
          <span className="error ">{distributorError}</span>
        </div>

        <div className="form-wrapper-btn">
          <input
            type="submit"
            onClick={(e) => handleDistributorInvite(e)}
            value="Create Distributor"
          />
        </div>
      </form>
    </>
  );
};

export default InviteDistributor;
