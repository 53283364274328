import { createSlice } from "@reduxjs/toolkit";

export const superAdminSlice = createSlice({
  name: "super-admin",
  initialState: {
    value: {
      distributorsAndAssociatedUsers: {},
      distributorsList: [],
      uploadPopupOpen: false,
      createQuizPopupOpen: false,
      editQuizPopupOpen: false,
      messagePopupOpen: false,
      superAdminStats: [],
      superAdminRating: [],
      amountOfLessons: 0,
      amountOfRegisteredUsers: 0,
      quizzes: [],
      lessonId: "",
      uploadPopupForm: {
        context: "",
        sectionId: "",
        editLesson: {
          id: "",
          title: "",
          subtitle: "",
          text: "",
          video: "",
          files: [],
          video_length: "",
          links: null,
        },
        editSection: {
          title: "",
          description: "",
        },
      },
      newMessage: {
        date: "",
        title: "",
        location: "",
        message: "",
        files: [],
      },
    },
  },
  reducers: {
    createNewMessagePopup: (state, action) => {
      const {
        newMessage: { date, title, location, message, ...files },
      } = action.payload;
      state.value.newMessage = {
        date,
        title,
        location,
        message,
        files,
      };
    },
    checkIfMessagePopupIsOpen: (state, action) => {
      const { messagePopupOpen } = action.payload;

      state.value = {
        messagePopupOpen: messagePopupOpen,
      };
    },
    setDistributorsAndUsers: (state, action) => {
      const { distributorsAndAssociatedUsers } = action.payload;

      state.value.distributorsAndAssociatedUsers =
        distributorsAndAssociatedUsers;
    },
    setDistributorsList: (state, action) => {
      const { distributorsList } = action.payload;
      state.value.distributorsList = distributorsList;
    },
    setUploadPopupOpen: (state, action) => {
      const { uploadPopupOpen } = action.payload;
      state.value.uploadPopupOpen = uploadPopupOpen;
    },

    setUploadPopupForm: (state, action) => {
      const { context, sectionId } = action.payload;
      state.value.uploadPopupForm.context = context;
      state.value.uploadPopupForm.sectionId = sectionId;
      if (context === "edit-lesson") {
        const { id, title, subtitle, text, video, files, video_length, links } =
          action.payload.editLesson;

        state.value.uploadPopupForm.editLesson = {
          id,
          title,
          subtitle,
          text,
          video,
          files,
          video_length,
          links,
        };
      } else if (context === "edit-section") {
        const { title, description } = action.payload.editSection;
        state.value.uploadPopupForm.editSection = {
          title,
          description,
        };
      }
    },

    setCreateQuizPopup: (state, action) => {
      const { createQuizPopupOpen } = action.payload;
      state.value.createQuizPopupOpen = createQuizPopupOpen;
    },
    editQuizPopupOpen: (state, action) => {
      const { editQuizPopupOpen } = action.payload;
      state.value.editQuizPopupOpen = editQuizPopupOpen;
    },

    setQuizzes: (state, action) => {
      const { quizzes } = action.payload;
      state.value.quizzes = quizzes;
    },

    setLessonId: (state, action) => {
      const { lessonId } = action.payload;
      state.value.lessonId = lessonId;
    },
    setSuperAdminStats: (state, action) => {
      const { stats } = action.payload;
      state.value.superAdminStats = stats;
    },
    setAmountOfLessons: (state, action) => {
      const { amountOfLessons } = action.payload;
      state.value.amountOfLessons = amountOfLessons;
    },
    setAmountOfRegisteredUsers: (state, action) => {
      const { amountOfRegisteredUsers } = action.payload;
      state.value.amountOfRegisteredUsers = amountOfRegisteredUsers;
    },

    setSuperAdminRating: (state, action) => {
      const { rating } = action.payload;
      state.value.superAdminRating = rating;
    },
  },
});
export const {
  createNewMessagePopup,
  checkIfMessagePopupIsOpen,
  setDistributorsAndUsers,
  setDistributorsList,
  setUploadPopupOpen,
  setUploadPopupForm,
  setCreateQuizPopup,
  setLessonId,
  setQuizzes,
  editQuizPopupOpen,
  setSuperAdminStats,
  setAmountOfLessons,
  setAmountOfRegisteredUsers,
  setSuperAdminRating,
} = superAdminSlice.actions;
export default superAdminSlice.reducer;
