import "./style/user-links-popup.css";
import { GrClose } from "react-icons/gr";
import { setLinksPopupOpen } from "../../redux/userReducer";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
const LinksPopup = () => {
  const [links, setLinks] = useState([]);
  const dispatch = useDispatch();
  const linksRedux = useSelector((state) => state.user.value.links);
  useEffect(() => {
    if (linksRedux?.length) {
      setLinks(linksRedux);
    } else {
      setLinks([]);
    }
  }, [linksRedux]);
  return (
    <>
      <div className="links_popup_wrapper">
        <div className="header">
          <h1 className="title">Lesson Links</h1>
          <div
            className="close"
            onClick={() => dispatch(setLinksPopupOpen(false))}
          >
            <GrClose size={"25px"} />
          </div>
        </div>
        <div className="body">
          {links.length > 0 ? (
            <>
              {links.map((link, index) => {
                return (
                  <div className="link-wrapper" key={index}>
                    <div
                      className="link"
                      onClick={() => window.open(link.link, "_blank")}
                    >
                      {link.title}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="no-links">No links available</div>
          )}
        </div>

        <div
          className="close-popup"
          onClick={() => dispatch(setLinksPopupOpen(false))}
        >
          Close
        </div>
      </div>
    </>
  );
};

export default LinksPopup;
