import logo1 from "../img/logo2.png";
import logo2 from "../img/logo.png";
// import back from "../img/back.png";
import { IconContext } from "react-icons";
import { AiOutlinePieChart, AiOutlineUser } from "react-icons/ai";
import { MdOutlineMessage } from "react-icons/md";
import { useSelector } from "react-redux";
import "./style/mobile-header.css";
import { slide as Menu } from "react-burger-menu";
import { useState } from "react";

const MobileSideNav = ({ setShowView }) => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  // const body = useSelector((state) => state.allPermission.value.body);

  function handleSideNavOpen() {
    setIsSideNavOpen(!isSideNavOpen);
  }
  function handleIsSideNavClosed() {
    setIsSideNavOpen(false);
  }

  function showSettings(context) {
    setShowView(context);
    handleIsSideNavClosed();
  }
  return (
    <>
      <div className="mobile-navbar">
        <div>
          <Menu
            noOverlay
            isOpen={isSideNavOpen}
            onOpen={handleSideNavOpen}
            onClose={handleIsSideNavClosed}
            width={230}
            className="admin"
          >
            <div
              className="admin-menu-wrapper"
              onClick={(e) => showSettings("mobile-community")}
            >
              <div className="menu-item">
                <IconContext.Provider
                  value={{
                    color: "#ffffff",
                    size: "1.5em",
                  }}
                >
                  <MdOutlineMessage />
                </IconContext.Provider>
              </div>
              <div>Community</div>
            </div>
            <div
              className="menu-item-wrapper mobile-users"
              onClick={(e) => showSettings("mobile-statistics")}
            >
              <div className="menu-item ">
                <IconContext.Provider
                  value={{
                    color: "#ffffff",
                    size: "1.5em",
                  }}
                >
                  <AiOutlinePieChart />
                </IconContext.Provider>
              </div>
              <div>Statistics</div>
            </div>

            <div
              className="menu-item-wrapper"
              onClick={(e) => showSettings("mobile-users")}
            >
              <div className="menu-item">
                <IconContext.Provider
                  value={{
                    color: "#ffffff",
                    size: "1.5em",
                    className: "icon-admin-class",
                  }}
                >
                  <AiOutlineUser />
                </IconContext.Provider>
              </div>
              <div>Users </div>
            </div>
          </Menu>
        </div>

        <div>
          <img src={logo2} className="logo-center" alt="logo" />
        </div>
        <div>
          <img src={logo1} className="logo-right" alt="logo" />
        </div>
      </div>
    </>
  );
};

export default MobileSideNav;
