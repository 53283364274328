import axiosBase from "../utils/axios";
const resetPasswordService = {
  checkUserEmail: async (email) => {
    try {
      const { data } = await axiosBase.post(
        "/reset-password/check-user-email",
        { email }
      );
      return data;
    } catch (err) {
      console.log(err, "check user email error");
    }
  },
  resetPassword: async (newPassword, token) => {
    try {
      const { data } = await axiosBase.put("/reset-password/reset", {
        newPassword,
        token,
      });
      return data;
    } catch (err) {
      console.log(err, "reset password error");
    }
  },
};

export default resetPasswordService;
