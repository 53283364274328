import axiosBaseUrl from "../utils/axios";

const notificationsService = {
  fetchNotifications: async (authorization) => {
    if (!authorization) return { message: "no authorization" };

    try {
      const { data } = await axiosBaseUrl.get(
        `/notifications/all/${authorization}`
      );
      return data;
    } catch (err) {
      console.log(err, "fetch notifications error");
    }
  },
  notificationAlreadyRead: async (id, authorization) => {
    try {
      const { data } = await axiosBaseUrl.put(
        `/notifications/associate/${id}`,
        { authorization }
      );
      return data;
    } catch (err) {
      console.log(err, "associate notification to user error");
    }
  },
};

export default notificationsService;
