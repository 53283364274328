import axiosBaseUrl from "../utils/axios";
const lessonsService = {
  fetchLessons: async () => {
    try {
      const { data } = await axiosBaseUrl.get("/lessons/fetchAll");
      console.log(data, "data from fetch lessons");
      return data;
    } catch (err) {
      console.log(err, "fetch lessons error");
    }
  },
  createSection: async (title, description) => {
    try {
      const { data } = await axiosBaseUrl.post("/lessons/addSection", {
        title,
        description,
      });
      return data;
    } catch (err) {
      console.log(err, "add section error");
    }
  },
  editSection: async (sectionId, title, description) => {
    try {
      const { data } = await axiosBaseUrl.put(
        `/lessons/editSection/${sectionId}`,
        {
          title,
          description,
        }
      );
      return data;
    } catch (err) {
      console.log(err, "edit section error");
    }
  },
  deleteSection: async (sectionId) => {
    try {
      const { data } = await axiosBaseUrl.delete(
        `/lessons/deleteSection/${sectionId}`
      );
      return data;
    } catch (err) {
      console.log(err, "delete section error");
    }
  },
  editLesson: async (formData, lessonId) => {
    try {
      const { data } = await axiosBaseUrl.put(
        `/lessons/editLesson/${lessonId}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return data;
    } catch (err) {
      console.log(err, "edit lesson error");
    }
  },
  createLesson: async (formData, sectionId) => {
    try {
      const { data } = await axiosBaseUrl.post(
        `/lessons/addLesson/${sectionId}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return data;
    } catch (err) {
      console.log(err, "create lesson error");
    }
  },
  deleteLesson: async (lesson) => {
    try {
      const { data } = await axiosBaseUrl.post(
        `/lessons/deleteLesson/${lesson.id}`,
        lesson
      );
      return data;
    } catch (err) {
      console.log(err, "delete lesson error");
    }
  },
  rateLesson: async (
    lessonId,
    rating,
    numberOfCorrectAnswers,
    amountOfQuestionsInQuiz
  ) => {
    try {
      const { data } = await axiosBaseUrl.post(`/lessons/rateLesson`, {
        rating,
        lessonId,
        numberOfCorrectAnswers,
        amountOfQuestionsInQuiz,
      });
      return data;
    } catch (err) {
      console.log(err, "rate lesson error");
    }
  },
  sendFeedback: async (ratingRecordId, feedback) => {
    // add feedback to rating record
    try {
      const { data } = await axiosBaseUrl.put(`/lessons/sendFeedback`, {
        ratingRecordId,
        feedback,
      });
      return data;
    } catch (err) {
      console.log(err, "send feedback error");
    }
  },

  insertWatchedVideo: async (lessonId, section_id = null) => {
    try {
      const { data } = await axiosBaseUrl.post(`/lessons/insertWatchedVideo`, {
        lessonId,
        section_id,
      });
      return data;
    } catch (err) {
      console.log(err, "insert watched video error");
    }
  },

  fetchLastUpdatedLesson: async () => {
    try {
      const { data } = await axiosBaseUrl.get(
        `/lessons/fetchLastUpdatedLesson`
      );
      return data;
    } catch (err) {
      console.log(err, "fetch last updated lesson error");
    }
  },
  lessonEnded: async (lessonId) => {
    try {
      const { data } = await axiosBaseUrl.post(`/lessons/lessonEnded`, {
        lessonId,
      });
      console.log(data, "data from lesson ended");
      return data;
    } catch (err) {
      console.log(err, "lesson ended error");
    }
  },
  changeLessonOrder: async (lesson, sectionId, direction, indexOfLesson) => {
    try {
      const { data } = await axiosBaseUrl.post(`/lessons/changeLessonOrder`, {
        lesson,
        sectionId,
        direction,
        indexOfLesson,
      });
      return data;
    } catch (err) {
      console.log(err, "change lesson order error");
    }
  },
};

export default lessonsService;
