import { createSlice } from "@reduxjs/toolkit";

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    value: {
      users: [],
      pendingUsers: [],
      adminStatistics: [],
      adminRating: {},
      currentUsersCompletion: [],
    },
  },

  reducers: {
    setUsersFromDb: (state, action) => {
      const { users } = action.payload;

      state.value.users = users;
    },

    setAdminStatistics: (state, action) => {
      const { stats } = action.payload;

      state.value.adminStatistics = stats;
    },

    setRating: (state, action) => {
      const rating = action.payload.rating;

      state.value.adminRating = rating;
    },
    setCurrentUsersCompletion: (state, action) => {
      const { currentUsersCompletion } = action.payload;

      state.value.currentUsersCompletion = currentUsersCompletion;
    },
    setPendingUsers: (state, action) => {
      const { pendingUsers } = action.payload;

      state.value.pendingUsers = pendingUsers;
    },
  },
});

export const {
  setUsersFromDb,
  setAdminStatistics,
  setRating,
  setCurrentUsersCompletion,
  setPendingUsers,
} = adminSlice.actions;

export default adminSlice.reducer;
