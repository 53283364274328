import ProgressBar from "react-bootstrap/ProgressBar";
import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Accordion from "react-bootstrap/Accordion";
import UserProgressBar from "./user-progressbar";
import { v4 as uuid4 } from "uuid";
import { useSelector } from "react-redux";
import { IconContext } from "react-icons";
import { HiCheckCircle } from "react-icons/hi";

import Lesson from "./lesson";

const Section = ({ section, setShowView }) => {
  const desktopView = useMediaQuery({ query: "(min-width:750px)" });

  return (
    <>
      {desktopView ? (
        <div className="user-desktop-section">
          <div className="section-title">
            <span className="main">{`Section Number ${section.section_id}`}</span>

            {section.completion === 100 ? (
              <>
                <span className="sub">
                  <IconContext.Provider
                    value={{
                      color: "#ffffff",
                      size: "1.2em",
                    }}
                  >
                    <HiCheckCircle />
                  </IconContext.Provider>

                  <span>Completed</span>
                </span>
              </>
            ) : (
              <>
                <span className="sub">{`${section.completion}% Completed `}</span>
              </>
            )}
          </div>
          <div style={{ width: "95%", margin: "20px auto" }}>
            <ProgressBar
              min={0}
              max={100}
              now={section.completion}
              variant="desktop-custom-css"
            />
          </div>
          <div className="video-wrapper">
            {section.lessons.map((lesson) => {
              return (
                <Lesson
                  section_id={section.section_id}
                  setShowView={setShowView}
                  lesson={lesson}
                  key={uuid4()}
                />
              );
            })}
          </div>
        </div>
      ) : (
        // section for mobile
        <>
          <Accordion defaultActiveKey="1" key={section.section_id}>
            <Accordion.Item
              eventKey={section.section_id}
              className="user-accordion-item accordion-borderless"
            >
              <Accordion.Header className="user-accordion-header">
                <div>
                  <span className="line">
                    {`Section number ${section.section_id}`}
                  </span>
                  <span>{`${section.lessons.length} videos`}</span>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {section.lessons.map((lesson) => {
                  return (
                    <Lesson
                      setShowView={setShowView}
                      lesson={lesson}
                      key={uuid4()}
                      section_id={section.section_id}
                    />
                  );
                })}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <UserProgressBar percentage={section.completion} />
        </>
      )}
    </>
  );
};

export default Section;
