import "./style/upload-popup.css";
import { IconContext } from "react-icons";
import { BsUpload } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import { setUploadPopupOpen } from "../../redux/superAdminReducer";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import LessonEditPopup from "./lesson-edit-popup";
import { v4 as uuid4 } from "uuid";
import AddLessonPopup from "./lesson-add-popup";
import Section from "../user/section";
import SectionEditPopup from "./section-edit-popup";

const UploadPopup = () => {
  const dispatch = useDispatch();

  const uploadFormRedux = useSelector(
    (state) => state.superAdminSlice.value.uploadPopupForm
  );
  const popupOpen = useSelector(
    (state) => state.superAdminSlice.value.uploadPopupOpen
  );
  const deleteFilePopupOpen = useSelector(
    (state) => state.allPermission.value.deleteFilePopupOpen
  );
  const [uploadForm, setUploadForm] = useState({});
  const [popupTitle, setPopupTitle] = useState("");
  const desktopView = useMediaQuery({ query: "(min-width:740px)" });

  useEffect(() => {
    if (!desktopView) {
      dispatch(setUploadPopupOpen({ uploadPopupOpen: false }));
    }
  }, [desktopView]);

  useEffect(() => {
    setPopupTitle("");
    if (uploadFormRedux.context === "edit-section") {
      setPopupTitle(`Edit Section ${uploadFormRedux.sectionId}`);
      setUploadForm({
        ...uploadFormRedux.editSection,
        sectionId: uploadFormRedux.sectionId,
        context: uploadFormRedux.context,
      });
    } else if (uploadFormRedux.context === "add-lesson") {
      setPopupTitle(`Add Lesson To Section ${uploadFormRedux.sectionId}`);
      setUploadForm({
        sectionId: uploadFormRedux.sectionId,
        context: uploadFormRedux.context,
      });
    } else if (uploadFormRedux.context === "edit-lesson") {
      setPopupTitle(`Edit Lesson In Section ${uploadFormRedux.sectionId}`);
      console.log(uploadFormRedux.editLesson);
      setUploadForm({
        ...uploadFormRedux.editLesson,
        sectionId: uploadFormRedux.sectionId,
        context: uploadFormRedux.context,
      });
    }
  }, [uploadFormRedux, popupOpen]);

  return (
    <>
      <div
        className={
          deleteFilePopupOpen
            ? "delete-file-is-open upload-popup-wrapper"
            : "upload-popup-wrapper"
        }
      >
        <div
          className={
            uploadForm.context === "edit-section"
              ? "header header-edit-section"
              : uploadForm.context === "edit-lesson"
              ? "header header-edit-lesson"
              : "header  header-add-lesson"
          }
        >
          <h1 className="title">{popupTitle}</h1>
          <div
            className="close"
            onClick={() => {
              dispatch(setUploadPopupOpen({ uploadPopupOpen: false }));
            }}
          >
            <IconContext.Provider
              value={{
                size: "1.5rem",
                color: "#000000",
              }}
            >
              <GrClose />
            </IconContext.Provider>
          </div>
        </div>
        <div className="body">
          <form>
            {uploadForm.context === "edit-section" && (
              <>
                <SectionEditPopup
                  uploadForm={uploadForm}
                  // handleFormSubmit={handleFormSubmit}
                />
              </>
            )}

            {uploadForm.context === "add-lesson" && (
              <>
                <AddLessonPopup />
              </>
            )}

            {uploadForm.context === "edit-lesson" && (
              <>
                <LessonEditPopup uploadForm={uploadForm} />
              </>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default UploadPopup;
