import "./style/delete-file-popup.css";
import { IconContext } from "react-icons";
import { IoMdClose } from "react-icons/io";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setDeleteFilePopup,
  setMessages,
  setMessageEditDeleteDetails,
} from "../../redux/reducer";
import { setAllLessons } from "../../redux/userReducer";
import fileService from "../../services/fileService";
import { toast } from "react-toastify";
import {
  setUploadPopupForm,
  setUploadPopupOpen,
} from "../../redux/superAdminReducer";
const DeleteFile = () => {
  const [fileName, setFileName] = useState("");
  const [fileId, setFileId] = useState(0);
  const deleteFileDetailsRedux = useSelector(
    (state) => state.allPermission.value.deleteFileDetails
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setFileName(deleteFileDetailsRedux.fileName);
    setFileId(deleteFileDetailsRedux.id);
  }, [deleteFileDetailsRedux]);

  const handleFileOrLinkDeletion = async () => {
    try {
      let response = null;
      if (deleteFileDetailsRedux?.deletionContext === "link") {
        // delete link
        response = await fileService.handleLinkDeletion({
          linkTitle: fileName,
          lessonId: fileId,
          sectionId: deleteFileDetailsRedux.sectionId,
        });
      } else {
        // delete file
        response = await fileService.handleFileDelete({
          fileName: fileName,
          id: fileId,
          context: deleteFileDetailsRedux.context,
          sectionId: deleteFileDetailsRedux.sectionId,
        });
      }

      if (response.message === "success") {
        if (deleteFileDetailsRedux?.deletionContext === "link") {
          const currentSectionId = response.currentSectionId;
          const correctSection = response.lessons.filter(
            (item) => item.section_id === currentSectionId
          );
          const correctLesson = correctSection[0].lessons.filter(
            (item) => item.id === fileId
          );

          dispatch(setAllLessons({ lessons: response.lessons }));

          dispatch(
            setUploadPopupForm({
              context: "edit-lesson",
              sectionId: currentSectionId,
              editLesson: {
                id: correctLesson[0].id,
                title: correctLesson[0].title,
                subtitle: correctLesson[0].subtitle,
                text: correctLesson[0].text,
                video: correctLesson[0].video,
                video_length: correctLesson[0].video_length,
                files: correctLesson[0].files,
                links: correctLesson[0].links,
              },
            })
          );

          toast.success("Link deleted");
          dispatch(setDeleteFilePopup({ deleteFilePopupOpen: false }));
          dispatch(setUploadPopupOpen({ uploadPopupOpen: false }));
        } else if (deleteFileDetailsRedux.context === "lesson") {
          // response from lessons response is object

          if (response.hasOwnProperty("lessons")) {
            const currentSectionId = response.currentSectionId;
            const correctSection = response.lessons.filter(
              (item) => item.section_id === currentSectionId
            );
            let correctLesson = correctSection[0].lessons.filter(
              (item) => item.id === fileId
            );

            dispatch(setAllLessons({ lessons: response.lessons }));
            dispatch(
              setUploadPopupForm({
                context: "edit-lesson",
                sectionId: currentSectionId,
                editLesson: {
                  id: correctLesson[0].id,
                  title: correctLesson[0].title,
                  subtitle: correctLesson[0].subtitle,
                  text: correctLesson[0].text,
                  video: correctLesson[0].video,
                  video_length: correctLesson[0].video_length,
                  files: correctLesson[0].files,
                },
              })
            );

            toast.success("File deleted");
            dispatch(setDeleteFilePopup({ deleteFilePopupOpen: false }));
            dispatch(setUploadPopupOpen({ uploadPopupOpen: false }));
          }
        } else {
          // response from messages response is array
          if (response.messages.length) {
            const correct = response.messages.filter(
              (item) => item.id === fileId
            );
            if (correct.length) {
              dispatch(
                setMessageEditDeleteDetails({
                  messageEditDeleteDetails: correct[0],
                })
              );
            }

            dispatch(setMessages({ messages: response.messages }));

            toast.success("File deleted");
            dispatch(setDeleteFilePopup({ deleteFilePopupOpen: false }));
          } else {
            toast.error("Failed to delete message file");
          }
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err, "failed to delete file");
    }
  };
  return (
    <>
      <div className="delete-file-popup-container">
        <div className="delete-file-popup-wrapper">
          <div className="header">
            <div
              onClick={() =>
                dispatch(setDeleteFilePopup({ deleteFilePopupOpen: false }))
              }
            >
              <IconContext.Provider
                value={{ size: "1.7rem", className: "close-icon" }}
              >
                <IoMdClose />
              </IconContext.Provider>
            </div>
          </div>
          <div className="body">
            <div className="title">
              {`Are you sure you want to delete this ${
                deleteFileDetailsRedux?.deletionContext
                  ? deleteFileDetailsRedux?.deletionContext
                  : "file"
              }?`}
            </div>
            <div className="file-name">{fileName}</div>
            <div className="buttons">
              <button
                className="delete-file"
                onClick={handleFileOrLinkDeletion}
              >
                Delete
              </button>
              <button
                className="cancel-delete-file"
                onClick={() =>
                  dispatch(setDeleteFilePopup({ deleteFilePopupOpen: false }))
                }
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteFile;
