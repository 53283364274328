import { BsUpload } from "react-icons/bs";
import "./style/messageForm.css";
import { IconContext } from "react-icons";
import React from "react";
import { IoMdClose } from "react-icons/io";
import { useState, useEffect } from "react";
// import { popupContext } from "../../App";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { setMessagePopup, setMessages } from "../../redux/reducer";
import axiosBase from "../../utils/axios";
import Select from "react-select";
import messageService from "../../services/messageService";
import { toast } from "react-toastify";

const MessageForm = () => {
  const [files, setFiles] = useState([]);

  const distributorsRedux = useSelector(
    (state) => state.superAdminSlice.value.distributorsList
  );
  const [messageForm, setMessageForm] = useState({
    title: "",
    location: "",
    message: "",
    distributors: "",
  });
  const [distributors, setDistributors] = useState([]);
  const [selectOptions, setSelectOptions] = useState([
    { id: 0, label: "All Users" },
  ]);
  const [errorMessage, setErrorMessage] = useState("");
  const [multiple, setMultiple] = useState([]);
  const authorization = useSelector(
    (state) => state.allPermission.value.authorization
  );

  const desktopView = useMediaQuery({ query: "(min-width:740px)" });
  const dispatch = useDispatch();
  const handlePopupClose = () => {
    dispatch(setMessagePopup({ messagePopupOpen: false }));
  };

  useEffect(() => {
    setDistributors([]);
    if (authorization === "super-admin") {
      setDistributors(distributorsRedux);
    }
  }, [authorization, distributorsRedux]);

  useEffect(() => {
    if (distributors?.length) {
      distributors.forEach((distributor) => {
        setSelectOptions((selectOptions) => [
          ...selectOptions,
          { value: distributor.id, label: distributor.distributor_name },
        ]);
      });
    }
  }, [distributors]);

  const handleChange = (e) => {
    setMessageForm({ ...messageForm, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    const filesArray = Array.from(selectedFiles);
    setFiles(filesArray);
  };
  const handleMessages = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    if (messageForm.title === "") {
      setErrorMessage("Title Is Required");
      return;
    }
    if (authorization === "super-admin") {
      if (!multiple.length) {
        setErrorMessage("Select At least One Distributor");
        return;
      }
    }
    if (messageForm.message === "") {
      setErrorMessage("Message Is Required");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("title", messageForm.title);
      formData.append("location", messageForm.location);

      if (files.length) {
        for (let i = 0; i < files.length; i++) {
          const filename = files[i].name.replace(/\s+/g, "_");

          const fileBlob = new Blob([files[i]], { type: files[i].type });
          formData.append("file", fileBlob, filename);
        }
      }

      formData.append("messageBody", messageForm.message);

      if (authorization === "super-admin") {
        // check if one of the options is "All Users"
        const allUsers = multiple.filter((item) => item.label === "All Users");

        if (allUsers.length) {
          // if all users is selected, send all users and don't send the other options
          formData.append("distributors", JSON.stringify([allUsers[0]]));
        } else {
          // if all users is not selected, send the other options
          formData.append("distributors", JSON.stringify(multiple));
        }
      }

      const responseMessage = await messageService.createMessage(
        formData,
        authorization
      );
      if (responseMessage.message === "success") {
        dispatch(setMessages({ messages: responseMessage.messages }));
        toast.success("Message Created");
        handlePopupClose();
      } else {
        toast.error("Message Creation Failed");
      }
    } catch (err) {
      console.log(err, "error creating message");
    }
  };
  return (
    <>
      <form className="message-form">
        <div className="close-popup" onClick={handlePopupClose}>
          <IconContext.Provider value={{ size: "2rem", color: "#000000" }}>
            <IoMdClose />
          </IconContext.Provider>
        </div>
        <div className="form-wrapper">
          <input
            type="text"
            className=""
            value={messageForm.title}
            onChange={handleChange}
            placeholder="Title:"
            name="title"
            id="admin-message-title"
          />
        </div>
        <div className="form-wrapper">
          <input
            type="text"
            value={messageForm.location}
            onChange={handleChange}
            className=""
            placeholder="Location:"
            name="location"
            id="admin-message-location"
          />
        </div>
        {authorization === "super-admin" && (
          <>
            <div className="form-wrapper">
              <Select
                isMulti
                onChange={(e) => setMultiple(e)}
                placeholder="Directed To:"
                options={selectOptions || []}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: "60px",
                    width: desktopView ? "24vw" : "60vw",
                    backgroundColor: "rgba(242, 242, 242, 1)",
                    borderRadius: "12px 12px 0 0",
                  }),

                  indicatorsContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    display: "none",
                  }),
                }}
              />
            </div>
          </>
        )}

        {files.length ? (
          <>
            <div className="file-addition">
              <p>{`Files added:${files.length}`}</p>
            </div>
          </>
        ) : null}
        <div className="form-wrapper file-upload">
          <div className="upload">
            <IconContext.Provider
              value={{
                color: "#ffffff",
                background: "green",
                className: "global-class-name",
                size: "1em",
              }}
            >
              <BsUpload />
            </IconContext.Provider>
            <label>
              <input
                onChange={handleFileChange}
                type="file"
                className="hidden"
                multiple
                // max="1"
                // accept=".pdf"
                name="file"
                id="admin-message-upload"
              />
              Upload file
            </label>
          </div>
        </div>
        <div className="form-wrapper">
          <textarea
            value={messageForm.message}
            onChange={handleChange}
            name="message"
            id="admin-message-message"
            cols="30"
            placeholder="Message"
          />
          <span className="error"> {errorMessage}</span>
        </div>
        <div className="form-wrapper submit">
          <input
            className=""
            type="submit"
            value="Create"
            id="message-btn"
            onClick={(e) => handleMessages(e)}
          />
        </div>
      </form>
    </>
  );
};

export default MessageForm;
