import { useEffect, useState } from "react";
import { TbDownload, TbDownloadOff } from "react-icons/tb";
import { IconContext } from "react-icons";
import fileService from "../../services/fileService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
const FilesDownload = ({ files, downloadName }) => {
  const [filesLength, setFilesLength] = useState(0);
  const [filesForDownload, setFilesForDownload] = useState([]);

  // const authorization = useSelector(
  //   (state) => state.allPermission.value.authorization
  // );

  useEffect(() => {
    // if null
    if (files === "null" || files === null) return;

    if (typeof files === "string") {
      if (files.includes(",")) {
        const filesArray = files.split(",");
        setFilesLength(filesArray.length);

        setFilesForDownload(filesArray);
      } else {
        setFilesLength(1);
        setFilesForDownload([files]);
      }
    } else {
      // files are array

      if (files.length > 1) {
        setFilesLength(files.length);
      } else {
        setFilesLength(1);
      }
      setFilesForDownload(files);
    }
  }, [files]);

  const handleDownloadMessageFiles = async (currentFiles) => {
    if (!filesLength) return;
    try {
      const response = await fileService.downloadFiles(
        currentFiles,
        "message-files"
      );

      const anchor = document.createElement("a");
      anchor.href = URL.createObjectURL(response);
      anchor.download = `redefine-message-files-${downloadName}.zip`;
      document.body.appendChild(anchor);
      anchor.click();
      URL.revokeObjectURL(anchor.href);
      toast.success(
        ` ${filesLength > 1 ? "Files" : "File"}   Downloaded Successfully`
      );
    } catch (err) {
      console.log(err, "error downloading files");
    }
  };

  return (
    <>
      <div className="files-download">
        <span>{filesLength}</span>|<span>Files</span>
        <span
          onClick={() => {
            handleDownloadMessageFiles(filesForDownload);
          }}
        >
          <IconContext.Provider
            value={{
              color: filesLength ? "#CB2A27" : "#C7C3C3",
              className: "global-class-name",
              size: "1rem",
              style: { cursor: "pointer" },
            }}
          >
            {filesLength ? <TbDownload /> : <TbDownloadOff />}
          </IconContext.Provider>
        </span>
      </div>
    </>
  );
};

export default FilesDownload;
