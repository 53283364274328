import { IconContext } from "react-icons";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { useState, useEffect } from "react";
const QuizAnswer = ({
  answer,
  handleChosenAnswer,
  view,
  amountOfAnswers,
  index,
  handleQuiz,
  handleWatchAgain,
}) => {
  return (
    <>
      {amountOfAnswers === index + 1 && view === "desktop" ? (
        <>
          <div className="box-wrapper-last">
            <div className="box-with-buttons">
              <div
                className="box"
                onClick={(e) => handleChosenAnswer(e.target)}
              >
                {answer.answer}
              </div>
              <div className="watch-again" onClick={handleWatchAgain}>
                <div>
                  <IconContext.Provider value={{ size: "1rem" }}>
                    <BsFillPlayCircleFill />
                  </IconContext.Provider>
                </div>
                <p>Watch again</p>
              </div>
              <div className="next-question" onClick={handleQuiz}>
                <button>Next</button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="box-wrapper">
            <div className="box" onClick={(e) => handleChosenAnswer(e.target)}>
              {answer.answer}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default QuizAnswer;
