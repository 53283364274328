import { useEffect } from "react";
import { IconContext } from "react-icons";
import { BsCheckCircleFill } from "react-icons/bs";
import "../user/style/user-all-sections-mobile.css";
const UserProgressBar = ({ percentage }) => {
  return (
    <>
      <div className="section-progress-mobile">
        <div
          className="section-bar"
          style={{ width: ` ${percentage}% ` }}
        ></div>
      </div>
      <div className="completed">
        {percentage === 100 && (
          <>
            <IconContext.Provider
              value={{ size: "1rem", color: "#ffffff", marginTop: "5px" }}
            >
              <BsCheckCircleFill />
            </IconContext.Provider>
          </>
        )}
        {percentage === 100 ? (
          <div>Completed</div>
        ) : (
          <div>{`Completed ${percentage}%`} </div>
        )}
      </div>
    </>
  );
};

export default UserProgressBar;
