import Answer from "./answer";
import { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { IoIosAdd } from "react-icons/io";
import { v4 as uuid4 } from "uuid";

const Question = ({
  questionId,
  setQuizData,
  quizData,
  question,
  indexOfQuestion,
  setError,
}) => {
  const [answerNumber, setAnswerNumber] = useState([1, 2]);
  useEffect(() => {
    if (answerNumber.length === 2) {
      setQuizData((prevData) => {
        const newQuizData = [...prevData];
        newQuizData[indexOfQuestion] = {
          ...newQuizData[indexOfQuestion],
          options: [
            { answer: "", correct: false, answerId: 1 },
            { answer: "", correct: false, answerId: 2 },
          ],
        };
        return newQuizData;
      });
    } else if (answerNumber.length > 2) {
      setQuizData((prevData) => {
        const newQuizData = [...prevData];
        const lastAnswerId =
          newQuizData[indexOfQuestion].options[
            newQuizData[indexOfQuestion].options.length - 1
          ].answerId;
        newQuizData[indexOfQuestion] = {
          ...newQuizData[indexOfQuestion],
          options: [
            ...newQuizData[indexOfQuestion].options,
            {
              answer: "",
              correct: false,
              answerId: lastAnswerId + 1,
            },
          ],
        };
        return newQuizData;
      });
    }
  }, [answerNumber]);

  const handleQuestionChange = (e, indexOfQuestion) => {
    setError("");
    setQuizData((prevData) => {
      const newQuizData = [...prevData];
      newQuizData[indexOfQuestion] = {
        ...newQuizData[indexOfQuestion],
        question: e.target.value,
      };
      return newQuizData;
    });
  };

  const handleAddAnswer = () => {
    if (quizData[indexOfQuestion]?.options?.length >= 5) return;
    setAnswerNumber([...answerNumber, answerNumber.length + 1]);
  };

  return (
    <>
      <div className="form-wrapper-question">
        <input
          type="text"
          name="question"
          value={question}
          onChange={(e) => handleQuestionChange(e, indexOfQuestion)}
          placeholder={`Question ${questionId}`}
          id="question"
        />
      </div>
      {quizData[indexOfQuestion]?.options?.map(
        ({ answer, correct, answerId }, indexOfAnswer) => {
          return (
            <Answer
              setError={setError}
              answerId={answerId}
              answer={answer}
              correct={correct}
              quizData={quizData}
              setQuizData={setQuizData}
              indexOfQuestion={indexOfQuestion}
              key={indexOfAnswer}
              indexOfAnswer={indexOfAnswer}
            />
          );
        }
      )}

      {answerNumber.length < 5 && (
        <div className="add-answer" onClick={handleAddAnswer}>
          <IconContext.Provider value={{ color: "#000000", size: "1rem" }}>
            <IoIosAdd />
            <span>add answer</span>
          </IconContext.Provider>
        </div>
      )}
    </>
  );
};

export default Question;
