import { IconContext } from "react-icons";
import { useState, useEffect } from "react";
import { MdLocationOn } from "react-icons/md";
import "./style/search-result-community-message.css";
import { sliceText } from "../../utils/sliceText";
const SearchResultCommunityMessage = ({ message }) => {
  const [MessageDate, setMessageDate] = useState("");
  const [showFullMessage, setShowFullMessage] = useState(false);
  useEffect(() => {
    const date = new Date(message.created_at);
    const formattedDate = date.toISOString().slice(0, 10);
    setMessageDate(formattedDate);
  }, [message]);

  return (
    <>
      <div className="message-result">
        <div className="message-header">
          <div className="message-date">{MessageDate}</div>
          {message.location && (
            <div className="message-location">
              <IconContext.Provider
                value={{
                  color: "black",
                  className: "global-class-name",
                  size: "1.5em",
                }}
              >
                <MdLocationOn />
              </IconContext.Provider>
              <span>{message.location}</span>
            </div>
          )}
        </div>

        <div className="message-body">
          <div className="message-title">{message.title}</div>
          {showFullMessage ? message.message : sliceText(message.message, 80)}
          <div
            className="message-btn"
            onClick={() => setShowFullMessage(!showFullMessage)}
          >
            <button>{showFullMessage ? "Close" : "Read"}</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchResultCommunityMessage;
