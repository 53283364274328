import { useState, useEffect } from "react";
import EditQuizAnswers from "./edit-quiz-answers";
import "./style/question-container.css";
import { BsTrashFill } from "react-icons/bs";
import { IconContext } from "react-icons";
import { IoIosAdd } from "react-icons/io";
// import { v4 as uuid4 } from "uuid";

const EditQuizPopupQuestionContainer = ({
  indexOfQuestion,
  questionId,
  question,
  lessonId,
  setError,
  quizData,
  setQuizData,
  currentQuestionObject,
  amountOfQuestions,
}) => {
  const [answerNumber, setAnswerNumber] = useState([]);

  useEffect(() => {
    setAnswerNumber([]);
    currentQuestionObject?.answers?.forEach((answer, index) => {
      setAnswerNumber((prevData) => [...prevData, index]);
    });
  }, [currentQuestionObject]);

  useEffect(() => {
    if (answerNumber.length > currentQuestionObject?.answers?.length) {
      setQuizData((prevData) => {
        const newQuizData = [...prevData];
        const lastAnswerId =
          newQuizData[indexOfQuestion]?.answers[
            newQuizData[indexOfQuestion]?.answers.length - 1
          ]?.answerId;
        newQuizData[indexOfQuestion] = {
          ...newQuizData[indexOfQuestion],
          answers: [
            ...(newQuizData[indexOfQuestion]?.answers || []),
            {
              answer: "",
              correct: false,
              answerId: lastAnswerId + 1,
            },
          ],
        };
        return newQuizData;
      });
    }
  }, [answerNumber]);

  const handleQuestionEdit = (e) => {
    setQuizData((prevData) => {
      const newQuizData = [...prevData];
      newQuizData[indexOfQuestion] = {
        ...newQuizData[indexOfQuestion],
        question: e.target.value,
      };
      return newQuizData;
    });
  };
  const handleRemoveQuestion = () => {
    setQuizData((prevData) => {
      const newQuizData = [...prevData];
      newQuizData.splice(indexOfQuestion, 1);
      return newQuizData;
    });
  };
  const handleAddAnswer = () => {
    if (quizData[indexOfQuestion]?.answers?.length >= 5) return;

    setAnswerNumber((prevData) => [...prevData, prevData.length + 1]);
  };

  if (!currentQuestionObject) {
    return null; // Return null if currentQuestionObject is undefined
  }

  return (
    <>
      <div className="edit-quiz-question-container">
        <form>
          <div className="question-title">
            <span>{`question ${indexOfQuestion + 1}`}</span>

            {amountOfQuestions > 1 && (
              <span className="trash" onClick={handleRemoveQuestion}>
                <IconContext.Provider
                  value={{
                    size: "1.3rem",
                    color: "#000000",
                  }}
                >
                  <BsTrashFill />
                </IconContext.Provider>
              </span>
            )}
          </div>
          <div className="form-wrapper-edit-question">
            <input
              type="text"
              placeholder={!question ? `Question ${indexOfQuestion + 1}` : ""}
              value={question ? question : ``}
              onChange={handleQuestionEdit}
            />
          </div>

          {quizData[indexOfQuestion]?.answers?.map((answer, indexOfAnswer) => {
            return (
              <EditQuizAnswers
                key={questionId + indexOfAnswer + 1}
                lessonId={lessonId}
                questionId={questionId}
                indexOfAnswer={indexOfAnswer}
                indexOfQuestion={indexOfQuestion}
                answer={answer.answer}
                correct={answer.correct}
                answerId={answer.answerId}
                quizData={quizData}
                setQuizData={setQuizData}
                amountOfAnswers={quizData[indexOfQuestion]?.answers?.length}
              />
            );
          })}
          {answerNumber.length < 5 && (
            <div className="add-answer" onClick={handleAddAnswer}>
              <IconContext.Provider value={{ color: "#000000", size: "1rem" }}>
                <IoIosAdd />
                <span>add answer</span>
              </IconContext.Provider>
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default EditQuizPopupQuestionContainer;
