import { useSelector, useDispatch } from "react-redux";
import {
  setNotificationsPopupIsOpen,
  setSelectedNotification,
} from "../../redux/reducer";
import { sliceText } from "../../utils/sliceText";
import bell from "../img/bell.png";
import { useEffect, useState } from "react";
import notificationsService from "../../services/notificationsService";
import {
  setNotifications,
  setListOfAlreadyReadNotifications,
} from "../../redux/reducer";
const Notification = ({ item, filterType, filterDate, handleFilter }) => {
  const dispatch = useDispatch();

  const listOfAlreadyReadNotifications = useSelector(
    (state) => state.allPermission.value.listOfAlreadyReadNotifications
  );
  const [hideBell, setHideBell] = useState(false);
  const [notificationDate, setNotificationDate] = useState("");
  const authorization = useSelector(
    (state) => state.allPermission.value.authorization
  );

  useEffect(() => {
    if (
      listOfAlreadyReadNotifications !== null &&
      listOfAlreadyReadNotifications.length
    ) {
      if (listOfAlreadyReadNotifications.includes(item.id.toString())) {
        setHideBell(true);
      }
    }
  }, [listOfAlreadyReadNotifications, item]);

  useEffect(() => {
    if (item?.created_at) {
      const formattedDate = formatCreatedAt(item.created_at);
      setNotificationDate(formattedDate);
    }
  }, [item.created_at]);

  function formatCreatedAt(createdAt) {
    const date = new Date(createdAt);
    const time = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    const formattedDate = `${date.toISOString().substring(0, 10)} ${time}`;
    return formattedDate;
  }

  const styles = {
    cursor: "pointer",
    padding: authorization === "admin" ? "15px" : "20px 10px 10px",
    minHeight: authorization === "admin" ? "160px" : "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: authorization === "admin" ? "flex-start" : "space-between",
    alignItems: authorization === "admin" ? "center" : "",
    position: "relative",
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    marginBottom: "20px",
    overflowY: "visible",
    maxWidth: "560px",
    minWidth: "150px",
  };

  const handleAdminNotificationClick = async (id) => {
    if (authorization === "admin") {
      const notifications = await notificationsService.notificationAlreadyRead(
        id,
        authorization
      );

      if (notifications.message === "success") {
        dispatch(
          setListOfAlreadyReadNotifications({
            listOfAlreadyReadNotifications:
              notifications.userListOfReadNotifications,
          })
        );

        dispatch(setNotifications(notifications));
      } else {
        console.log(notifications.message);
      }
    }
    return;
  };
  const handleNotificationPopup = async (item) => {
    dispatch(setSelectedNotification(item));
    dispatch(
      setNotificationsPopupIsOpen({
        notificationsPopupIsOpen: true,
      })
    );
  };
  return (
    <>
      <div style={styles} onClick={() => handleAdminNotificationClick(item.id)}>
        <div className="notification-header">
          <div className="date">{notificationDate}</div>
          <div className="created-by">{`Created By : ${item.created_by}`}</div>
        </div>
        <div className="notification-type">{`type : ${item.type}`}</div>
        <p className="notification-message">
          {item.message.length > 50
            ? sliceText(item.message, 50)
            : item.message}
        </p>
        {!hideBell && (
          <img
            src={bell}
            alt="bell"
            style={{ bottom: authorization === "admin" ? "30px" : "130px" }}
          />
        )}

        {authorization === "super-admin" && (
          <div
            className="notification-btn"
            onClick={() => handleNotificationPopup(item)}
          >
            <button>Read</button>
          </div>
        )}
      </div>
    </>
  );
};

export default Notification;
