import axios from "axios";

const envType = process.env.NODE_ENV;

let baseURLData =
  envType === "production"
    ? "https://meatu-api.redefinemeat.com"
    : "http://localhost:8080";
// "http://localhost:8080";

const axiosBase = axios.create({
  baseURL: baseURLData,
});

axiosBase.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["x-auth-token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosBase;
