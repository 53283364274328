import { useEffect, useState, useRef } from "react";
import lessonsService from "../../services/lessonsService";
import { setAllLessons } from "../../redux/userReducer";
import { setDeleteFileDetails, setDeleteFilePopup } from "../../redux/reducer";
import { setUploadPopupOpen } from "../../redux/superAdminReducer";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBin2Fill } from "react-icons/ri";

import { toast } from "react-toastify";
import { IconContext } from "react-icons";
import { AiOutlineEye } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BsUpload } from "react-icons/bs";
import { sliceText } from "../../utils/sliceText";
import fileService from "../../services/fileService";
const LessonEditPopup = ({ uploadForm }) => {
  const [title, setTitle] = useState(uploadForm.title);
  const [subtitle, setSubtitle] = useState(uploadForm.subtitle);
  const [description, setDescription] = useState(uploadForm.text);
  const [video, setVideo] = useState(uploadForm.video);
  const [videoLength, setVideoLength] = useState(uploadForm.video_length);
  const [error, setError] = useState("");
  const [verifyDelete, setVerifyDelete] = useState(false);
  const [lessonFiles, setLessonFiles] = useState([]);
  const [lessonLinks, setLessonLinks] = useState(uploadForm.links);
  const [newUploadedFiles, setNewUploadedFiles] = useState([]);
  const [newLessonLinks, setNewLessonLinks] = useState([
    { link: "", title: "" },
  ]);

  const [enableNewLinks, setEnableNewLinks] = useState(false);
  const checkboxRef = useRef();

  const dispatch = useDispatch();
  useEffect(() => {
    let updatedFilesArray = [];
    let id = 0;
    if (typeof uploadForm.files === "string") {
      // if string, convert to array
      if (uploadForm.files.includes(",")) {
        // if more than one file name in string
        updatedFilesArray = uploadForm.files
          .split(",")
          .filter(
            (fileName) => fileName.trim() !== "null" && fileName.trim() !== null
          )
          .map((fileName) => ({
            id: id++,
            file: fileName.replace("lesson-files\\", ""),
          }));
        setLessonFiles(updatedFilesArray);
      } else {
        // if one file name in string we check if null or not
        if (
          uploadForm.files.trim() === "null" ||
          uploadForm.files.trim() === null
        ) {
          updatedFilesArray = [];
          setLessonFiles(updatedFilesArray);
        } else {
          updatedFilesArray = [
            {
              id: id++,
              file: uploadForm.files.replace("lesson-files\\", ""),
            },
          ];

          setLessonFiles(updatedFilesArray);
        }
      }
    } else if (Array.isArray(uploadForm.files)) {
      // if array

      if (uploadForm?.files?.length) {
        const updatedFilesArray = uploadForm.files
          .filter((fileName) => fileName !== "null" && fileName !== null)
          .map((fileName) => ({
            id: id++,
            file: fileName.replace("lesson-files\\", ""),
          }));

        setLessonFiles(updatedFilesArray);
      } else {
        setLessonFiles([]);
      }
    }
  }, [uploadForm]);

  const handleFileDelete = async (fileName, deletionItem) => {
    dispatch(
      setDeleteFileDetails({
        fileName,
        id: uploadForm.id,
        context: "lesson",
        deletionContext: deletionItem, // file or link
        sectionId: uploadForm.sectionId,
      })
    );
    dispatch(setDeleteFilePopup({ deleteFilePopupOpen: true }));
  };

  const handleLessonDeletion = async (e) => {
    e.preventDefault();
    setError("");
    setVerifyDelete(true);

    if (verifyDelete) {
      if (!checkboxRef.current.checked) {
        setError("Please confirm deletion");
        return;
      } else {
        // send request to delete lesson

        const deleteLesson = await lessonsService.deleteLesson(uploadForm);
        if (deleteLesson.message === "success") {
          dispatch(setAllLessons({ lessons: deleteLesson.lessons }));
          dispatch(setUploadPopupOpen({ uploadPopupOpen: false }));
          toast.success("Lesson deleted successfully");
        } else {
          toast.error(deleteLesson.message);
        }
      }
    }
  };

  const handleLessonEdit = async (e) => {
    e.preventDefault();
    setError("");

    if (title === "") {
      setError("Title is required");
      return;
    }
    if (subtitle === "") {
      setError("Subtitle is required");
      return;
    }
    if (description === "") {
      setError("Description is required");
      return;
    }

    if (video === "") {
      setError("Video Url is required");
      return;
    }

    if (videoLength === "") {
      setError("Video length is required");
      return;
    }

    if (
      uploadForm.title === title &&
      uploadForm.subtitle === subtitle &&
      uploadForm.text === description &&
      uploadForm.video === video &&
      uploadForm.video_length === videoLength &&
      !newUploadedFiles.length &&
      !enableNewLinks
    ) {
      setError("No changes were made");
      return;
    }
    const linkRegex =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

    if (enableNewLinks) {
      for (let index = 0; index < newLessonLinks.length; index++) {
        const link = newLessonLinks[index];
        if (link.title === "") {
          setError(`${index + 1} Link Title Is Required`);
          return;
        }
        if (!linkRegex.test(link.link) || link.link === "") {
          setError(`${index + 1} Enter a valid Link`);
          return;
        }
      }
    }

    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("subtitle", subtitle);
      formData.append("description", description);
      formData.append("video", video);
      formData.append("video_length", videoLength);
      formData.append("sectionId", uploadForm.sectionId);
      if (newUploadedFiles.length) {
        newUploadedFiles.forEach((file) => {
          const filename = file.name.replace(/\s+/g, "_");

          const fileBlob = new Blob([file], { type: file.type });

          formData.append("files", fileBlob, filename);
        });
      }

      let updatedLinks = null;
      if (enableNewLinks) {
        // if new links added
        if (lessonLinks !== null && lessonLinks?.length) {
          // check if old links exist and add them to the formData
          updatedLinks = [...lessonLinks, ...newLessonLinks];
        } else {
          // only new links exist
          updatedLinks = newLessonLinks;
        }
      } else {
        // no new links added
        if (lessonLinks !== null && lessonLinks?.length) {
          // check if old links exist and add them to the formData
          updatedLinks = lessonLinks;
        } else {
          // no links exist
          updatedLinks = null;
        }
      }

      formData.append("links", JSON.stringify(updatedLinks));

      const response = await lessonsService.editLesson(formData, uploadForm.id);

      if (response.message === "success") {
        dispatch(setAllLessons({ lessons: response.lessons }));
        toast.success("Lesson updated successfully");
      } else {
        toast.error(response.message);
      }
      dispatch(setUploadPopupOpen({ uploadPopupOpen: false }));
    } catch (err) {
      console.log(err, "err");
    }
  };

  const handleFileChange = (e) => {
    setError("");
    const selectedFiles = e.target.files;
    const filesArray = Array.from(selectedFiles);

    setNewUploadedFiles(filesArray);
  };
  return (
    <>
      <div className="section-edit-wrapper">
        <label htmlFor="edit-lesson-title">lesson title</label>
        <input
          type="text"
          name="edit-lesson-title"
          id="edit-lesson-title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="section-edit-wrapper">
        <label htmlFor="edit-lesson-subtitle">lesson subtitle</label>
        <input
          type="text"
          name="edit-lesson-subtitle"
          id="edit-lesson-subtitle"
          value={subtitle}
          onChange={(e) => setSubtitle(e.target.value)}
        />
      </div>

      <div className="section-edit-wrapper">
        <label htmlFor="edit-lesson-description">lesson description</label>

        <textarea
          name=""
          id=""
          rows="5"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
      </div>
      <div className="section-edit-wrapper">
        <label htmlFor="edit-lesson-video">lesson Video Url</label>
        <input
          type="text"
          name="edit-lesson-video"
          id="edit-lesson-video"
          value={video}
          onChange={(e) => setVideo(e.target.value)}
        />
      </div>
      <div className="section-edit-wrapper">
        <label htmlFor="edit-lesson-length">Video Length in seconds</label>
        <input
          type="number"
          name="edit-lesson-length"
          id="edit-lesson-length"
          value={videoLength}
          onChange={(e) => setVideoLength(e.target.value)}
        />
      </div>

      <div className="section-edit-wrapper">
        <p> Lesson files:</p>
        <div className="lesson-files">
          {lessonFiles !== "null" && lessonFiles?.length ? (
            <>
              {lessonFiles.map((obj) => {
                return (
                  <div className="lesson-file-wrapper" key={obj.id}>
                    <div className="file-name">{sliceText(obj.file, 10)}</div>
                    <div className="file-actions">
                      <div
                        className="file-preview"
                        onClick={() =>
                          window.open(
                            `https://s3.eu-north-1.amazonaws.com/meatu-img.redefinemeat.com/${obj.file}`,
                            "_blank"
                          )
                        }
                      >
                        <IconContext.Provider
                          className="message-delete-icon"
                          value={{
                            color: "#000000",
                            size: "1.2rem",
                          }}
                        >
                          <AiOutlineEye className="message-delete-icon" />
                        </IconContext.Provider>
                      </div>
                      <div
                        className="file-delete"
                        onClick={() => handleFileDelete(obj.file, "file")}
                      >
                        <IconContext.Provider
                          className="message-delete-icon"
                          value={{
                            color: "#000000",
                            size: "1.2rem",
                          }}
                        >
                          <RiDeleteBin6Line className="message-delete-icon" />
                        </IconContext.Provider>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <> No Files</>
          )}
        </div>
      </div>

      {newUploadedFiles?.length > 0 && (
        <>
          <div className="section-edit-wrapper files-added">
            <p className="files-added">
              {`Files added: ${newUploadedFiles.length}`}
            </p>
          </div>
        </>
      )}

      <div className="section-edit-wrapper upload   ">
        <div className="upload-wrapper">
          <IconContext.Provider
            value={{
              color: "#ffffff",
              className: "global-class-name",
              size: "20px",
            }}
          >
            <BsUpload />
          </IconContext.Provider>
          <label className="add-files">
            <input
              type="file"
              value=""
              onChange={handleFileChange}
              className="hidden"
              multiple
              // accept=".pdf"
              name="file"
              id="add-message-files"
            />
            <span>Add files</span>
          </label>
        </div>
      </div>

      {lessonLinks && lessonLinks?.length && (
        <>
          <div className="section-edit-wrapper links">
            <p>Lesson Links:</p>

            {lessonLinks.map((link, index) => {
              return (
                <div className="lesson-links" key={index}>
                  <div className="lesson-name">{link.title}</div>
                  <div className="file-actions">
                    <div
                      className="file-preview link-preview "
                      onClick={() => window.open(link.link, "_blank")}
                    >
                      <IconContext.Provider
                        className="message-delete-icon"
                        value={{
                          color: "#000000",
                          size: "1.2rem",
                        }}
                      >
                        <AiOutlineEye className="message-delete-icon" />
                      </IconContext.Provider>
                    </div>

                    <div
                      className="file-delete"
                      onClick={() => {
                        // if (newLessonLinks.length === 1) {
                        //   setEnableNewLinks(false);
                        //   return;
                        // }
                        // const newLessonLinks = [...lessonLinks];
                        // newLessonLinks.splice(index, 1);
                        // setNewLessonLinks(newLessonLinks);
                        handleFileDelete(link.title, "link");
                      }}
                    >
                      <IconContext.Provider
                        className="message-delete-icon"
                        value={{
                          color: "#000000",
                          size: "1.2rem",
                        }}
                      >
                        <RiDeleteBin6Line className="message-delete-icon" />
                      </IconContext.Provider>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}

      {!enableNewLinks ? (
        <div className="enable_links" onClick={() => setEnableNewLinks(true)}>
          Add Links
        </div>
      ) : (
        <>
          {newLessonLinks.map((lessonLink, index) => {
            const isLastItem = index === newLessonLinks.length - 1;

            return (
              <div
                className="all-lesson-wrapper"
                style={{ marginBottom: isLastItem ? "20px" : "0" }}
                key={index}
              >
                <input
                  type="text"
                  placeholder={`${index + 1} Link Title`}
                  value={lessonLink.title}
                  onChange={(e) => {
                    const newLinks = [...newLessonLinks];
                    newLinks[index].title = e.target.value;
                    setNewLessonLinks(newLinks);
                  }}
                />
                <input
                  type="text"
                  placeholder={`${index + 1} Embedded Link `}
                  value={lessonLink.link}
                  onChange={(e) => {
                    const newLinks = [...newLessonLinks];
                    newLinks[index].link = e.target.value;
                    setNewLessonLinks(newLinks);
                  }}
                />
                <div className="links">
                  <div
                    className="add_link"
                    onClick={() => {
                      setNewLessonLinks((prevState) => [
                        ...prevState,
                        { link: "", title: "" },
                      ]);
                    }}
                  >
                    Add Link
                  </div>

                  <div
                    className="remove_link"
                    onClick={() => {
                      if (newLessonLinks.length === 1) {
                        setEnableNewLinks(false);
                        setNewLessonLinks([{ link: "", title: "" }]);

                        return;
                      }
                      const newLinks = [...newLessonLinks];
                      newLinks.splice(index, 1);
                      setNewLessonLinks(newLinks);
                    }}
                  >
                    <span style={{ marginTop: "10px", display: "block" }}>
                      <RiDeleteBin2Fill size={"30px"} color="#cb2a27" />
                    </span>
                  </div>
                </div>

                <span className="error"></span>
              </div>
            );
          })}
        </>
      )}

      {verifyDelete && (
        <>
          <div className="verify-delete">
            <label htmlFor="delete-lesson">
              <input
                type="checkbox"
                ref={checkboxRef}
                name="delete-lesson"
                id="delete-lesson"
              />
              Verify Lesson Deletion
            </label>
          </div>
        </>
      )}
      <div className="error">{error}</div>
      <div className="btn-wrapper">
        <button
          className="edit-lesson-btn"
          onClick={(e) => handleLessonEdit(e)}
        >
          Submit
        </button>
        <button
          className="delete-lesson-btn"
          onClick={(e) => handleLessonDeletion(e)}
        >
          Delete
        </button>
      </div>
    </>
  );
};

export default LessonEditPopup;
