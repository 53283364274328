import "./style/feedback.css";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import lessonsService from "../../services/lessonsService";
import { useMediaQuery } from "react-responsive";
import { IconContext } from "react-icons";
import { BsArrowLeft } from "react-icons/bs";
const Feedback = ({ setShowView }) => {
  const desktopView = useMediaQuery({ query: "(min-width:750px)" });

  const ratingIDFromDB = useSelector(
    (state) => state.user.value.userRatingIDFromDB
  );
  const [message, setMessage] = useState("");
  const [textArea, setTextArea] = useState("");

  const handleFeedback = async () => {
    setMessage("");
    if (!textArea) return setMessage("Please type your feedback");

    if (textArea.length < 3)
      return setMessage("Please type at least 3 characters");

    try {
      const res = await lessonsService.sendFeedback(ratingIDFromDB, textArea);
      if (res.message === "success") {
        setShowView("lesson-finished");
      } else {
        console.log(res.message, "res message sending feedback");
      }
    } catch (err) {
      console.log(err, "err sending feedback");
    }
  };
  return (
    <>
      {desktopView ? (
        <>
          <div className="desktop-feedback-wrapper">
            <div className="box">
              <div className="title">
                Is there anything you think we should add to the class? (Salt,
                pepper, new sections etc..)
              </div>
              <div className="feedback">
                <textarea
                  value={textArea}
                  onChange={(e) => setTextArea(e.target.value)}
                  placeholder="Type here..."
                ></textarea>
              </div>
              <div className="bottom">
                <div
                  className="back"
                  onClick={() => setShowView("rate-lesson")}
                >
                  <IconContext.Provider
                    value={{
                      color: "#ffffff",
                      size: "1.4rem",
                      style: { marginTop: "1px", marginRight: "20px" },
                    }}
                  >
                    <BsArrowLeft />
                  </IconContext.Provider>
                  <span>Back</span>
                </div>
                <div className="error">{message}</div>
                <div className="submit-feedback" onClick={handleFeedback}>
                  <button>Next</button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="feedback-wrapper">
            <div className="back" onClick={() => setShowView("rate-lesson")}>
              <IconContext.Provider
                value={{
                  color: "#ffffff",
                  size: "1.4rem",
                  style: { marginTop: "1px", marginRight: "20px" },
                }}
              >
                <BsArrowLeft />
              </IconContext.Provider>
              <span>Back</span>
            </div>
            <div className="main">
              <div className="title">
                Is there anything you think we should add to the class? (Salt,
                pepper, new sections etc..)
              </div>
              <div className="user-feedback">
                <textarea
                  value={textArea}
                  onChange={(e) => setTextArea(e.target.value)}
                  placeholder="Type here..."
                ></textarea>
              </div>
              <div className="error">{message}</div>

              <div className="submit-feedback" onClick={handleFeedback}>
                <button>Next</button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Feedback;
