import { IconContext } from "react-icons";
import Accordion from "react-bootstrap/Accordion";
import { IoIosAdd } from "react-icons/io";

import { useSelector, useDispatch } from "react-redux";
import {
  setUploadPopupOpen,
  setUploadPopupForm,
} from "../../redux/superAdminReducer";
import { useState, useEffect } from "react";
import { TbChevronsDownLeft, TbEditCircle } from "react-icons/tb";

import "./style/upload.css";
import LessonEdit from "./lesson-edit";

const SectionEdit = ({ section }) => {
  // const [sectionLessons, setSectionLessons] = useState([]);
  const quizzes = useSelector((state) => state.superAdminSlice.value.quizzes);
  const dispatch = useDispatch();

  const processFiles = async (files) => {
    if (!files || files === null || files === "") {
      return [];
    }

    if (typeof files === "string") {
      return files.includes(",") ? files.split(",").filter(Boolean) : [files];
    }

    if (Array.isArray(files)) {
      return files.filter(Boolean);
    }

    console.warn("Unexpected files format:", files);
    return [];
  };

  const handlePopupTrigger = async (context, sectionId, lesson) => {
    if (context === "edit-section") {
      // edit section
      dispatch(setUploadPopupOpen({ uploadPopupOpen: true }));
      dispatch(
        setUploadPopupForm({
          context,
          sectionId,
          editSection: {
            title: section.section_title,
            description: section.section_description,
          },
        })
      );

      return;
    }

    if (context === "add-lesson") {
      // Add a new lesson
      dispatch(setUploadPopupOpen({ uploadPopupOpen: true }));

      dispatch(setUploadPopupForm({ context, sectionId }));

      return;
    }

    if (context === "edit-lesson") {
      // Edit a lesson in the section

      dispatch(setUploadPopupOpen({ uploadPopupOpen: true }));

      let { files, id, subtitle, text, title, video, video_length, links } =
        lesson;
      files = await processFiles(files);

      dispatch(
        setUploadPopupForm({
          context,
          sectionId,
          editLesson: {
            id,
            title,
            subtitle,
            text,
            video,
            files,
            video_length,
            links,
          },
        })
      );
      return;
    }
  };
  return (
    <>
      <div>
        <Accordion defaultActiveKey={"1"}>
          <Accordion.Item
            eventKey={section.section_id}
            className="upload-accordion"
          >
            <Accordion.Header
              style={{}}
              className="upload-accordion-header"
              id="upload-accordion-header"
            >
              <div className="upload-accordion-title">
                {section.section_title}
              </div>

              <div
                className="upload-accordion-header-edit-section"
                onClick={() =>
                  handlePopupTrigger("edit-section", section.section_id)
                }
              >
                <IconContext.Provider
                  value={{
                    color: "#ffffff",
                    size: "1rem",
                  }}
                >
                  <TbEditCircle />
                </IconContext.Provider>
                <span>Edit Section</span>
              </div>
              <div
                className="upload-accordion-header-add-lesson"
                onClick={() =>
                  handlePopupTrigger("add-lesson", section.section_id)
                }
              >
                <IconContext.Provider
                  value={{
                    color: "#ffffff",
                    size: "1rem",
                  }}
                >
                  <IoIosAdd />
                </IconContext.Provider>
                <span>Add Lesson</span>
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className="lesson-titles">
                <div className="lesson-title">Lesson Title</div>
                <div className="lesson-Description">Lesson Description</div>
                <div className="lesson-edit">Edit Lesson</div>
                <div className="lesson-quiz">Add/Edit Quiz</div>
                <div className="lesson-move">Change Order</div>
              </div>
              {section.lessons.map((lesson, index) => {
                if (!lesson?.id) {
                  return;
                }
                return (
                  <LessonEdit
                    indexOfLesson={index}
                    lesson={lesson}
                    key={lesson.id}
                    handlePopupTrigger={handlePopupTrigger}
                    sectionId={section.section_id}
                  />
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default SectionEdit;
