import { AiTwotonePushpin, AiOutlinePushpin } from "react-icons/ai";
import { IconContext } from "react-icons";
import { useEffect, useState } from "react";
import { MdLocationOn } from "react-icons/md";
import { sliceText } from "../../utils/sliceText";
import { useSelector, useDispatch } from "react-redux";
import messageService from "../../services/messageService";
import { setMessages } from "../../redux/reducer";
import { toast } from "react-toastify";
import FilesDownload from "./files-download";
import deleteMessageBtn from "../img/message_bin.png";
import editMessageBtn from "../img/message_edit_btn.png";
import { useMediaQuery } from "react-responsive";
import {
  setMessageEditDeletePopup,
  setMessageEditDeleteContext,
  setMessageEditDeleteDetails,
} from "../../redux/reducer";
const Message = ({ item, customClassName }) => {
  const [showFullText, setShowFullText] = useState(false);
  // const [pinned, setPinned] = useState(false);
  const desktopView = useMediaQuery({ query: "(min-width:740px)" });
  const currentUserEmail = useSelector(
    (state) => state.allPermission.value.currentUserEmail
  );
  const authorization = useSelector(
    (state) => state.allPermission.value.authorization
  );

  const dispatch = useDispatch();

  const handlePinMessage = async (id, pinnedStatus, className) => {
    if (className === "pinned") {
      const response = await messageService.pinMessage(id, pinnedStatus);
      if (response.message === "success") {
        dispatch(setMessages({ messages: response.messages }));

        if (pinnedStatus) {
          toast.success("Message Unpinned");
        } else {
          toast.success("Message Pinned To Top");
        }
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  const handleMessageEditDeletePopup = async (action) => {
    // action = edit or delete
    dispatch(setMessageEditDeleteContext({ messageEditDeleteContext: action }));

    // item = message details
    dispatch(setMessageEditDeleteDetails({ messageEditDeleteDetails: item }));

    // show popup
    dispatch(
      setMessageEditDeletePopup({
        messageEditDeletePopupOpen: true,
      })
    );
  };

  return (
    <>
      <div
        className={`${customClassName}-wrapper`}
        style={{
          padding:
            authorization === "super-admin"
              ? "4% 7%"
              : authorization === "user"
              ? "4% 7%"
              : // authorization === "admin"
                "5% 7%",
        }}
      >
        {authorization === "super-admin" && (
          <>
            <div
              className={
                customClassName === "pinned" ? "unpin-icon" : "pinned-icon"
              }
              style={{
                maxHeight: showFullText ? "none" : "350px",
                justifyContent: desktopView ? "flex-end" : "space-between",
              }}
            >
              <div className="message-header-pin">
                {customClassName === "pinned" ? "Pin To Top" : ""}

                <div
                  className={`${customClassName}-icon-trigger`}
                  onClick={() =>
                    handlePinMessage(item.id, item.pinned, customClassName)
                  }
                >
                  {customClassName === "pinned" && (
                    <>
                      <>
                        <IconContext.Provider
                          value={{
                            color: "red",
                            className: "global-class-name",
                            size: "1rem",
                          }}
                        >
                          {item.pinned ? (
                            <AiTwotonePushpin />
                          ) : (
                            <AiOutlinePushpin />
                          )}
                        </IconContext.Provider>
                      </>
                    </>
                  )}
                </div>
              </div>

              {/* edit delete message for super admin */}
              <div className="message-edit-delete">
                <img
                  src={editMessageBtn}
                  onClick={() => handleMessageEditDeletePopup("edit")}
                />
                <img
                  src={deleteMessageBtn}
                  alt=""
                  onClick={() => handleMessageEditDeletePopup("delete")}
                />
              </div>
            </div>
          </>
        )}

        {authorization === "admin" && item.created_by === currentUserEmail ? (
          <>
            <div className="message-edit-delete">
              <img
                src={editMessageBtn}
                onClick={() => handleMessageEditDeletePopup("edit")}
              />
              <img
                src={deleteMessageBtn}
                alt=""
                onClick={() => handleMessageEditDeletePopup("delete")}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {authorization !== "user" && <div className="line"></div>}
        <div className="date-location">
          <div className="date">
            <span>{item.full_name}</span> | {item.created_at.substring(0, 10)}
          </div>
          <div className="location">
            <IconContext.Provider
              value={{
                color: "black",
                className: "global-class-name",
                size: "1.5em",
              }}
            >
              <MdLocationOn />
            </IconContext.Provider>
            {item.location !== "null" ? (
              <span> {item.location}</span>
            ) : (
              <span> no location</span>
            )}
          </div>
        </div>
        <h2 className="headline">{item.title}</h2>
        <div className="main">
          {!showFullText ? (
            <p className="article">{sliceText(item.message, 80)}</p>
          ) : (
            <p className="article">{item.message}</p>
          )}

          <div className="article-btn">
            <div>
              <button onClick={() => setShowFullText(!showFullText)}>
                {showFullText ? "Close" : "Read more"}
              </button>
            </div>
            <FilesDownload files={item.files} downloadName={item.title} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Message;
