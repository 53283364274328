import "./style/upload.css";
import { toast } from "react-toastify";
import { IconContext } from "react-icons";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import SectionEdit from "./section-edit";
import lessonsService from "../../services/lessonsService";
import { setAllLessons } from "../../redux/userReducer";
const Upload = () => {
  const [sectionTitle, setSectionTitle] = useState("");
  const [sectionDescription, setSectionDescription] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [arrow, setArrow] = useState(false);
  const [sections, setSections] = useState([]);

  const sectionsRedux = useSelector((state) => state.user.value.lessons);

  const quizzesRedux = useSelector(
    (state) => state.superAdminSlice.value.quizzes
  );

  useEffect(() => {
    if (sectionsRedux?.length) {
      setSections([]);
      const updatedSections = sectionsRedux.map((section) => ({
        ...section,
        lessons: section.lessons.map((lesson) => ({
          ...lesson,
          quizzes: quizzesRedux.filter((quiz) => quiz.lesson_id === lesson.id),
        })),
      }));
      setSections(updatedSections);
    }
  }, [sectionsRedux, quizzesRedux]);

  const dispatch = useDispatch();
  const handleSectionCreation = async (e) => {
    e.preventDefault();
    setTitleError("");
    setDescriptionError("");
    if (!sectionTitle) {
      setTitleError("Title is required");
      return;
    }

    if (sectionTitle.length < 3) {
      setTitleError("Title must be atleast 3 characters long");
      return;
    }

    if (!sectionDescription) {
      setDescriptionError("Description is required");
      return;
    }

    if (sectionDescription.length < 3) {
      setDescriptionError("Title must be atleast 3 characters long");
      return;
    }

    try {
      // send request to backend to create a new section
      const newSection = await lessonsService.createSection(
        sectionTitle,
        sectionDescription
      );

      if (newSection.message === "success") {
        dispatch(setAllLessons({ lessons: newSection.lessons }));
        toast.success("Section created successfully");
      } else {
        toast.error(newSection.message);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="upload-wrapper">
        <div
          className="create-section"
          style={{
            borderRadius: arrow ? "12px" : "12px 12px 0 0",
            height: arrow ? "320px" : "fit-content",
          }}
        >
          <div onClick={() => setArrow(!arrow)}>
            <p>Create A New Course Section</p>
            <IconContext.Provider
              value={{
                className: "global-class-name",
                size: "25px",
              }}
            >
              {arrow ? <IoIosArrowDown /> : <IoIosArrowUp />}
            </IconContext.Provider>
          </div>

          {arrow && (
            <>
              <form>
                <div className="form-wrapper">
                  <input
                    type="text"
                    placeholder="Section Title:"
                    value={sectionTitle}
                    onChange={(e) => setSectionTitle(e.target.value)}
                  />

                  <span className="error">{titleError}</span>
                </div>
                <div className="form-wrapper">
                  <textarea
                    placeholder="Description:"
                    value={sectionDescription}
                    onChange={(e) => setSectionDescription(e.target.value)}
                  ></textarea>
                  <div className="error">{descriptionError}</div>
                </div>
                <div
                  className="btn-wrapper"
                  onClick={(e) => handleSectionCreation(e)}
                >
                  <button>Create</button>
                </div>
              </form>
            </>
          )}
        </div>

        <div className="sections">
          {sections.length && (
            <>
              {sections.map((section) => {
                return (
                  <SectionEdit section={section} key={section.section_id} />
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Upload;
