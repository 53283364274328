import React from "react";
import "./style/lesson-finished.css";
import { useMediaQuery } from "react-responsive";
import { IconContext } from "react-icons";
import { BsArrowLeft } from "react-icons/bs";
const LessonFinished = ({ setShowView }) => {
  const desktopView = useMediaQuery({ query: "(min-width:650px)" });

  return (
    <>
      {desktopView ? (
        <>
          <div className="desktop-lesson-finished-wrapper">
            <div className="box">
              <div className="desktop-lesson-finished-content">
                <p>Cow wonderful! Thanks for answering.</p>
              </div>
              <div className="desktop-lesson-finished-btn">
                <div className="back" onClick={() => setShowView("feedback")}>
                  <IconContext.Provider
                    value={{
                      color: "#ffffff",
                      size: "1.4rem",
                      style: { marginTop: "1px", marginRight: "20px" },
                    }}
                  >
                    <BsArrowLeft />
                  </IconContext.Provider>
                  <span>Back</span>
                </div>

                <button
                  className="finish-lesson"
                  onClick={() => setShowView("all-section")}
                >
                  Finish
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="lesson-finished-wrapper">
            <div className="back" onClick={() => setShowView("feedback")}>
              <IconContext.Provider
                value={{
                  color: "#ffffff",
                  size: "1.4rem",
                  style: { marginTop: "1px", marginRight: "20px" },
                }}
              >
                <BsArrowLeft />
              </IconContext.Provider>
              <span>Back</span>
            </div>
            <div className="lesson-finished-content">
              <p>Cow wonderful! Thanks for answering.</p>
              <div className="lesson-finished-btn">
                <button onClick={() => setShowView("user-mobile-start")}>
                  Finish
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LessonFinished;
