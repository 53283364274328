import "./style/video-player.css";
import ReactPlayer from "react-player";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useRef } from "react";

// import { IoMdSpeedometer } from "react-icons/io";
// import { FaPlay, FaPause, FaFastForward, FaFastBackward } from "react-icons/fa";
import { IconContext } from "react-icons";
import { BsArrowLeft } from "react-icons/bs";
import lessonsService from "../../services/lessonsService";
import { setCurrentQuiz, setAllLessons } from "../../redux/userReducer";
import quizService from "../../services/quizService";

const VideoPlayer = ({ setShowView, desktopView }) => {
  const [videoUrl, setVideoUrl] = useState("");
  const [lessonId, setLessonId] = useState(0);
  const dispatch = useDispatch();
  const playerRef = useRef(null);
  const [videoDuration, setVideoDuration] = useState(0);
  const [isVideoEnding, setIsVideoEnding] = useState(false); // New state to track video ending
  const [triggerTime, setTriggerTime] = useState(0); // Time to trigger handleFinishLesson

  const currentLessonDetails = useSelector(
    (state) => state.user.value.currentLessonDetails
  );

  const currentQuiz = useSelector((state) => state.user.value.currentQuiz);
  useEffect(() => {
    if (lessonId) {
      const fetchQuiz = async () => {
        const checkIfQuizExistForCurrentLesson =
          await quizService.fetchQuizByLessonId(lessonId);

        if (checkIfQuizExistForCurrentLesson.message === "success") {
          dispatch(
            setCurrentQuiz({
              currentQuiz: checkIfQuizExistForCurrentLesson.quiz,
            })
          );
        }
      };
      fetchQuiz();
    }
  }, [lessonId]);

  useEffect(() => {
    setVideoUrl(currentLessonDetails.videoUrl);
    setLessonId(currentLessonDetails.lessonId);
  }, [currentLessonDetails]);

  const handleDuration = (duration) => {
    setVideoDuration(duration);
    setTriggerTime(duration * 0.97); // Set trigger time to 90% of video duration
  };

  const handleProgress = (progress) => {
    if (progress.playedSeconds >= triggerTime) {
      setIsVideoEnding(true);
    } else {
      setIsVideoEnding(false);
    }
  };
  useEffect(() => {
    if (isVideoEnding) {
      handleFinishLesson();
    }
  }, [isVideoEnding]);

  const handleBack = () => {
    if (!desktopView) {
      if (currentLessonDetails?.from === "search") {
        setShowView("mobile-search-results");
      } else {
        setShowView("all-section");
      }
    } else {
      // mobile
      setShowView("all-section");

      // if (currentLessonDetails?.from === "search") {
      //   // user came from search
      //   setShowView("mobile-search-results");
      // } else {
      //   // user came from video intro
      //   setShowView("user-mobile-video-intro");
      // }
    }
  };
  const handleFinishLesson = async () => {
    if (lessonId) {
      const lessonEnded = await lessonsService.lessonEnded(lessonId);

      if (lessonEnded.message === "success") {
        // insert to db a message that lesson is finished for admin and super admin
        // toast.success("Lesson finished");
        const lessons = await lessonsService.fetchLessons();

        if (lessons.message === "success") {
          dispatch(setAllLessons({ lessons: lessons.lessons }));
        }

        if (currentQuiz.length) {
          setShowView("quiz");
        } else {
          setShowView("rate-lesson");
        }
      }
    }
  };
  return (
    <>
      <div className="player-container">
        <ReactPlayer
          ref={playerRef}
          className="react-player"
          url={videoUrl}
          playing={true}
          controls
          onDuration={handleDuration}
          onProgress={handleProgress}
          onEnded={handleFinishLesson}
        />

        <div className="back-button" onClick={handleBack}>
          <IconContext.Provider
            value={{
              color: "#ffffff",
              size: "1.4rem",
              style: { marginRight: "10px" },
            }}
          >
            <BsArrowLeft />
          </IconContext.Provider>
          <span>Back</span>
        </div>
      </div>
    </>
  );
};

export default VideoPlayer;
