import Accordion from "react-bootstrap/Accordion";
import { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import services from "../../services/userService";
import { setUsersFromDb } from "../../redux/adminReducer";
import { phoneNoRegex } from "../../utils/regex";
import { setDistributorsAndUsers } from "../../redux/superAdminReducer";

const MyAccordionItem = ({ item, errorMessage }) => {
  const desktopView = useMediaQuery({ query: "(min-width:740px)" });
  // create state and pass onchange functions
  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [nameError, setNameError] = useState("");
  const [verifyDeletion, setVerifyDeletion] = useState(false);
  const [verifyDeletionError, setVerifyDeletionError] = useState("");
  const [deletionCheckbox, setDeletionCheckbox] = useState(false);
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [language, setLanguage] = useState("");
  const [languageError, setLanguageError] = useState("");
  const [isOpen, setIsOpen] = useState(false); // Add this state

  const [completionPercentage, setCompletionPercentage] = useState(0);

  const authorization = useSelector(
    (state) => state.allPermission.value.authorization
  );

  const currentUsersCompletion = useSelector(
    (state) => state.adminReducer.value.currentUsersCompletion
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentUsersCompletion.length) {
      const correctUser = currentUsersCompletion.find(
        (user) => user.userId === item.id
      );

      if (correctUser) {
        setCompletionPercentage(correctUser.completionPercentage);
      }
    }

    setFullName(`${item.first_name} ${item.last_name}`);
    setFirstName(item.first_name);
    setLastName(item.last_name);
    setEmail(item.email);
    setLanguage(item.language);

    if (item.phone) {
      setPhone(item.phone);
    } else {
      setPhone("No Phone");
    }
    if (item.last_name) {
      setLastName(item.last_name);
    }
  }, [currentUsersCompletion, item]);

  useEffect(() => {
    // if accordion is closed, reset verify deletion and all possible errors
    if (!isOpen) {
      setVerifyDeletion(false);
      setVerifyDeletionError("");
      setNameError("");
    }
  }, [isOpen]);

  let localTimeString;
  if (item.last_seen) {
    const dateStr = item.last_seen;
    const date = new Date(dateStr);
    localTimeString = date.toLocaleString();
  } else {
    localTimeString = "Waiting for user log in";
  }

  const handleDelete = async (email) => {
    if (!verifyDeletion) {
      setVerifyDeletion(true);
      return;
    }

    if (!deletionCheckbox) {
      setVerifyDeletionError("Please Verify Deletion");
    }

    try {
      const deleteUser = await services.deleteMember(email);
      if (deleteUser.message === "success") {
        dispatch(
          setUsersFromDb({
            users: deleteUser.users,
          })
        );
      }
    } catch (err) {
      console.log(err, "error deleting user from admin view");
    }
  };
  const handleSave = async () => {
    setVerifyDeletion(false);

    setNameError("");
    setPhoneError("");
    setLanguageError("");
    const phoneMatch = phone.match(phoneNoRegex);
    if (phone === "" || phone === "No Phone" || !phoneMatch) {
      setPhoneError("Enter A Valid Phone");
      return;
    }
    if (language === "") {
      setLanguageError("Language Is Required");
      return;
    } else {
      setLanguageError("");
    }

    const fullNameValue = `${firstName} ${lastName}`;
    let userNameChanged;
    let isFullNameEmpty = false;

    if (fullName === fullNameValue) {
      userNameChanged = false;
    } else {
      userNameChanged = true;

      if (fullName === "") {
        isFullNameEmpty = true;
      }
    }

    if (userNameChanged) {
      if (isFullNameEmpty) {
        setNameError("Name Is Required");
        return;
      }
    }

    if (isFullNameEmpty) {
      setNameError("Name Is Required");
      return;
    }

    if (
      !userNameChanged &&
      phone === item.phone &&
      language === item.language
    ) {
      setNameError("No Changes Were Made");
      return;
    } else {
      setNameError("");
    }
    try {
      const response = await services.updateMember(
        fullName,
        email,
        phone,
        language,
        authorization
      );
      if (response.message === "success") {
        dispatch(setUsersFromDb({ users: response.users }));
      } else {
        console.log(response, "response update member error");
      }
    } catch (err) {
      console.log(err, "error updating user");
    }
  };

  useEffect(() => {
    if (deletionCheckbox) {
      // if user checked the checkbox, reset verify deletion error
      setVerifyDeletionError("");
    }
  }, [deletionCheckbox]);

  return (
    <div>
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey={item.id} className="admin-accordion-item">
          <Accordion.Header
            className="admin-accordion-header"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span style={{ color: "#ffffff" }}>
              {firstName === "null" || !firstName ? "First Name" : firstName}
            </span>
            <span>
              {lastName === "null" || !lastName ? "Last Name" : lastName}
            </span>
            <span style={{ color: "#ffffff", fontSize: "0.7rem" }}>
              {localTimeString}
            </span>
          </Accordion.Header>
          <Accordion.Body
            // style={{ height: verifyDeletion ? "220px" : "200px" }}
            className={
              desktopView
                ? "admin-accordion-body"
                : "mobile-admin-accordion-body"
            }
          >
            <div className="form-container">
              <form
                action=""
                name="admin-user-details-form"
                id={`admin-details-form-${item.id}`}
              >
                <div className="form-wrapper">
                  <input
                    type="text"
                    name="admin-user-details-name"
                    defaultValue={
                      lastName ? `${firstName}  ${lastName}` : `${firstName}`
                    }
                    id={`admin-user-details-name-${item.id}`}
                    onInput={(e) => setFullName(e.target.value)}
                  />
                </div>
                <div className="form-wrapper">
                  <input
                    type="email"
                    name="admin-user-details-email"
                    defaultValue={email}
                    disabled
                    id={`admin-user-details-email-${item.id}`}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-wrapper">
                  <input
                    type="number"
                    name="admin-user-details-phone"
                    value={phone}
                    id={`admin-user-details-phone-${item.id}`}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <span className="error">{phoneError}</span>
                </div>
                <div className="form-wrapper">
                  <select
                    name="admin-user-details-language"
                    value={language}
                    id={`admin-user-details-language-${item.id}`}
                    className="admin-user-details-language"
                    onChange={(e) => setLanguage(e.target.value)}
                  >
                    <option value="english">English</option>
                    <option value="hebrew">Hebrew</option>
                    <option value="arabic">Arabic</option>
                    <option value="arabic">Russian</option>
                  </select>
                  <span className="error">{languageError}</span>
                </div>
              </form>
              <span
                className="error"
                style={{ fontSize: "15px", textAlign: "center" }}
              >
                {nameError}
              </span>
            </div>
            <div className="accordion-bottom">
              <div className="status-wrapper">
                <div className="info">
                  <p>Lessons Status</p>
                  <p>{completionPercentage}%</p>
                </div>
                <div className="status">
                  <ProgressBar
                    animated
                    className="progress"
                    now={completionPercentage}
                  />
                </div>
              </div>
              <div className="buttons-wrapper">
                <button
                  className="delete"
                  onClick={() => handleDelete(item.email)}
                >
                  Delete
                </button>
                <button className="save" onClick={handleSave}>
                  Save
                </button>
              </div>
            </div>
            {verifyDeletion && (
              <>
                <div className="verify-user-deletion">
                  <label>Are You Sure You Want To Delete This User?</label>
                  <input
                    type="checkbox"
                    checked={deletionCheckbox}
                    onChange={(e) => setDeletionCheckbox(e.target.checked)}
                  />
                </div>
                <div className="checkbox-not-checked">
                  {verifyDeletionError}
                </div>
              </>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default MyAccordionItem;
