import { TbEditCircle } from "react-icons/tb";
import { RiFileEditLine } from "react-icons/ri";

import { IconContext } from "react-icons";
import { sliceText } from "../../utils/sliceText";
import { useState, useEffect, useRef } from "react";
import { IoIosAdd } from "react-icons/io";
import { BsArrowUpSquareFill, BsArrowDownSquareFill } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import {
  setCreateQuizPopup,
  setLessonId,
  editQuizPopupOpen,
} from "../../redux/superAdminReducer";
import { setAllLessons } from "../../redux/userReducer";
import lessonsService from "../../services/lessonsService";
const LessonEdit = ({
  lesson,
  handlePopupTrigger,
  sectionId,
  indexOfLesson,
}) => {
  const dispatch = useDispatch();
  const desktopView = useMediaQuery({ query: "(min-width:1000px)" });
  const handleQuizCreationPopup = (context) => {
    dispatch(setLessonId({ lessonId: lesson.id }));
    if (context === "create") {
      dispatch(setCreateQuizPopup({ createQuizPopupOpen: true }));
    } else if (context === "edit") {
      dispatch(editQuizPopupOpen({ editQuizPopupOpen: true }));
    }
  };
  // const upButton = useRef(null);
  // const downButton = useRef(null);
  const changeOrder = async (direction) => {
    // upButton.current.disable = true;
    // downButton.current.disable = true;
    try {
      const change = await lessonsService.changeLessonOrder(
        lesson,
        sectionId,
        direction,
        indexOfLesson
      );
      if (change.message === "success") {
        dispatch(setAllLessons({ lessons: change.lessons }));
        // upButton.current.disable = false;
        // downButton.current.disable = false;
      }
    } catch (err) {
      console.log(err, "change order of lesson error");
    }
  };
  return (
    <>
      {!lesson.id ? (
        <>
          <div className="no-lesson">No lessons yet</div>
        </>
      ) : (
        <>
          <div className="lesson-wrapper">
            <div className="lesson-title">{sliceText(lesson.title, 12)}</div>

            <div className="lesson-description">
              <p>{sliceText(lesson.subtitle, 12)}</p>
            </div>
            <div
              className="lesson-edit"
              onClick={() => {
                handlePopupTrigger("edit-lesson", sectionId, lesson);
              }}
            >
              <IconContext.Provider
                value={{
                  color: "#000000",
                  size: !desktopView ? "1rem" : "1.2rem",
                }}
              >
                <TbEditCircle />
              </IconContext.Provider>
            </div>
            {!lesson.quizzes.length ? (
              <div
                className="add-quiz"
                onClick={() => handleQuizCreationPopup("create")}
              >
                <IconContext.Provider
                  value={{
                    color: "#000000",
                    size: !desktopView ? "1rem" : "1.5rem",
                  }}
                >
                  <IoIosAdd />
                </IconContext.Provider>
              </div>
            ) : (
              <div
                className="add-quiz edit-quiz"
                onClick={() => handleQuizCreationPopup("edit")}
              >
                <IconContext.Provider
                  value={{
                    color: "#000000",
                    size: !desktopView ? "1rem" : "1.2rem",
                  }}
                >
                  <RiFileEditLine />
                </IconContext.Provider>
              </div>
            )}
            <div className="change-order">
              <div className="arrow-up" onClick={() => changeOrder("up")}>
                <IconContext.Provider
                  value={{
                    color: "#000000",
                    size: !desktopView ? "1rem" : "1.6rem",
                  }}
                >
                  <BsArrowUpSquareFill />
                </IconContext.Provider>
              </div>
              <div className="arrow-down" onClick={() => changeOrder("down")}>
                <IconContext.Provider
                  value={{
                    color: "#000000",
                    size: !desktopView ? "1rem" : "1.6rem",
                  }}
                >
                  <BsArrowDownSquareFill />
                </IconContext.Provider>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LessonEdit;
