import { IconContext } from "react-icons";
import "./style/user-video-intro-mobile.css";
import { RiClosedCaptioningLine } from "react-icons/ri";
import { FiDownload } from "react-icons/fi";
import { BsFillPlayFill } from "react-icons/bs";
import BackButton from "../common/backButton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fileService from "../../services/fileService";
import { toast } from "react-toastify";

const VideoIntroMobile = ({ setShowView }) => {
  const dispatch = useDispatch();
  const [sectionName, setSectionName] = useState("");
  const [files, setFiles] = useState([]);
  const [lessonTitle, setLessonTitle] = useState("");
  const [lessonDescription, setLessonDescription] = useState("");
  const currentLessonDetails = useSelector(
    (state) => state.user.value.currentLessonDetails
  );

  useEffect(() => {
    if (currentLessonDetails.sectionName) {
      setSectionName(currentLessonDetails.sectionName);
      setLessonTitle(currentLessonDetails.lessonTitle);
      setLessonDescription(currentLessonDetails.lessonDescription);
      if (currentLessonDetails.files) setFiles(currentLessonDetails.files);
    }
  }, [currentLessonDetails]);

  const handleLessonFiles = async () => {
    try {
      const response = await fileService.downloadFiles(files, "lesson-files");
      const anchor = document.createElement("a");
      anchor.href = URL.createObjectURL(response);
      anchor.download = `redefine-section-${sectionName}-lesson-${lessonTitle}.zip`;
      document.body.appendChild(anchor);
      anchor.click();
      URL.revokeObjectURL(anchor.href);
      toast.success(
        `${sectionName}-${lessonTitle}-files downloaded successfully`
      );
    } catch (err) {
      console.log(err, "error downloading lesson files");
    }
  };
  return (
    <>
      <div className="video-intro-image">
        <div className="back">
          <BackButton context="user" setShowView={setShowView} />
        </div>
        {/* What makes the New-MeatTM so real? */}
        <h4 className="title">{lessonTitle}</h4>
      </div>
      <div className="video-intro-main">
        <p className="explanation">
          {/* Our understanding of meat to the deepest level. The cutting edge tech
          we use. Or maybe it's just our smile! anyway, you're about to find
          out. */}
          {lessonDescription}
        </p>

        <div className="section">
          <div>{sectionName}</div>
          <div>7:34 min</div>
        </div>
        <div className="footer">
          <div className="subtitles">
            <IconContext.Provider value={{ size: "1.5rem" }}>
              <RiClosedCaptioningLine />
            </IconContext.Provider>
          </div>
          <div
            className="start-btn"
            onClick={() => setShowView("video-player")}
            // onClick={handleStart}
          >
            <div>Start</div>
            <div>
              <IconContext.Provider value={{ size: "1.2rem" }}>
                <BsFillPlayFill />
              </IconContext.Provider>
            </div>
          </div>
          <div className="download-icon">
            <IconContext.Provider value={{ size: "1.5rem" }}>
              <FiDownload />
            </IconContext.Provider>
          </div>
        </div>
        <div className="attached-files" onClick={handleLessonFiles}>
          Download attached files
        </div>
      </div>
    </>
  );
};

export default VideoIntroMobile;
