import React, { useState, useEffect } from "react";
import "./style/notifications.css";
import cow from "../img/cow.png";
import pig from "../img/pig.png";
import sheep from "../img/sheep.png";
import { useMediaQuery } from "react-responsive";
// import NotificationsPopup from "./notifications-popup";
import { v4 as uuid4 } from "uuid";

// import { useSelector, useDispatch } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import Notification from "./notification";

const NotificationsContainer = () => {
  const desktopView = useMediaQuery({ query: "(min-width:740px)" });
  const [filterType, setFilterType] = useState("all");
  const [filterDate, setFilterDate] = useState("all");
  const listOfAlreadyReadNotifications = useSelector(
    (state) => state.allPermission.value.listOfAlreadyReadNotifications
  );
  const notificationsRedux = useSelector(
    (state) => state.allPermission.value.notifications
  );
  // const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    if (notificationsRedux?.length) {
      setNotifications(notificationsRedux);
    }
  }, [notificationsRedux, listOfAlreadyReadNotifications]);

  useEffect(() => {
    handleFilter();
  }, [filterType, filterDate]);
  const handleFilter = () => {
    let filteredNotifications = notificationsRedux;
    if (filterType.trim() !== "all") {
      filteredNotifications = filteredNotifications
        .filter((notification) => notification.type === filterType)
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }

    if (filterDate.trim() !== "all") {
      const currentDate = new Date();
      const filterDateInMilliseconds =
        parseInt(filterDate) * 24 * 60 * 60 * 1000;
      const filteredDate = new Date(currentDate - filterDateInMilliseconds);

      filteredNotifications = filteredNotifications.filter(
        (notification) => new Date(notification.created_at) >= filteredDate
      );
    }

    setNotifications(filteredNotifications);
  };

  return (
    <>
      {/* {showNotification && <div>Popup content here !!</div>} */}

      <div
        className="notifications"
        style={{
          width: desktopView ? "30%" : "90%",
          margin: desktopView ? "0" : "auto",
        }}
      >
        <h3>Ah, we meat again</h3>
        <p>
          This is your personal area, you are a provider and can make various
          changes here
        </p>
        <div className="filter-notifications">
          <div className="filter-title">
            <h1>Filter notifications</h1>
          </div>
          <div className="filters">
            <div className="filter-type">
              <select
                className="filter-notification-select"
                value={filterType}
                onChange={(e) => setFilterType(e.target.value)}
              >
                <option value="all">All Types</option>
                <option value="message">Message</option>
                <option value="registration">Registration</option>
                <option value="feedback">Feedback</option>
                <option value="section_completed">Section Completed</option>
              </select>
            </div>
            <div className="filter-date">
              <select
                value={filterDate}
                className="filter-notification-select"
                onChange={(e) => setFilterDate(e.target.value)}
              >
                <option value="all">Time</option>
                <option value="30 Days">30 Days</option>
                <option value="60 Days">60 Days</option>
                <option value="90 Days">90 Days</option>
              </select>
            </div>
          </div>
        </div>
        <div className="data-wrapper">
          {notifications?.length && (
            <>
              {notifications.map((item) => {
                return (
                  <Notification
                    filterDate={filterDate}
                    filterType={filterType}
                    key={uuid4()}
                    item={item}
                    handleFilter={handleFilter}
                    notificationsRedux={notificationsRedux}
                  />
                );
              })}
            </>
          )}
        </div>
        <div className="animal-wrapper">
          <div>
            <img src={sheep} alt="sheep" />
          </div>
          <div>
            <img src={pig} alt="pig" />
          </div>
          <div>
            <img src={cow} alt="cow" />
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationsContainer;
