import "./style/user-search-result-files.css";
import { useState, useEffect } from "react";
import { sliceText } from "../../utils/sliceText";
const SearchResultsFiles = ({ file }) => {
  const [fullMessage, setFullMessage] = useState(false);

  return (
    <>
      <div className="file-container">
        <div className="header">
          <div className="title">{file.lesson_title}</div>
          <div className="file_size">{file.files_size}</div>
        </div>
        <div
          className="body"
          style={{
            height: !fullMessage ? "120px" : "fit-content",
            cursor: "pointer",
          }}
        >
          <div className="message" onClick={() => setFullMessage(!fullMessage)}>
            {fullMessage ? file.lesson_text : sliceText(file.lesson_text, 40)}
          </div>
        </div>
        <div className="footer">
          <button> Download</button>
        </div>
      </div>
    </>
  );
};

export default SearchResultsFiles;
