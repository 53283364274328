import "./style/create-quiz-popup.css";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { editQuizPopupOpen } from "../../redux/superAdminReducer";
import { IconContext } from "react-icons";
import { IoIosClose, IoIosAdd } from "react-icons/io";
import EditQuizPopupQuestionContainer from "./edit-quiz-question-container";
import { useMediaQuery } from "react-responsive";
import quizService from "../../services/quizService";
import { toast } from "react-toastify";
import { setQuizzes } from "../../redux/superAdminReducer";

const EditQuizPopup = () => {
  const desktopView = useMediaQuery({ query: "(min-width:740px)" });

  const lessonId = useSelector((state) => state.superAdminSlice.value.lessonId);
  const [editQuiz, setEditQuiz] = useState([]);
  const [questionNumber, setQuestionNumber] = useState([]);

  const [error, setError] = useState("");
  const [confirmQuizDelete, setConfirmQuizDelete] = useState(false);
  const [approveQuizDelete, setApproveQuizDelete] = useState(false);
  const dispatch = useDispatch();

  const quizzesRedux = useSelector(
    (state) => state.superAdminSlice.value.quizzes
  );

  useEffect(() => {
    setEditQuiz(quizzesRedux.filter((quiz) => quiz.lesson_id === lessonId));
  }, [quizzesRedux]);

  useEffect(() => {
    if (questionNumber.length > editQuiz.length) {
      setEditQuiz((prevData) => {
        const lastQuestionId = prevData[prevData.length - 1].id;
        const questionObject = {
          id: lastQuestionId + 1,
          question: "",
          lesson_id: lessonId,
          answers: [
            { answer: "", correct: false, answerId: 1 },
            { answer: "", correct: false, answerId: 2 },
          ],
        };
        return [...prevData, questionObject];
      });
    }
  }, [questionNumber]);

  const handleAddQuestion = () => {
    if (questionNumber.length < 5) {
      setQuestionNumber([...questionNumber, questionNumber.length + 1]);
    }
  };

  useEffect(() => {
    if (editQuiz.length) {
      setQuestionNumber([...Array(editQuiz.length).keys()]);
    }
  }, [editQuiz]);

  useEffect(() => {
    if (!desktopView) {
      dispatch(editQuizPopupOpen({ editQuizPopupOpen: false }));
    }
  }, [desktopView]);
  const handleQuizUpdate = async () => {
    setError("");
    let nothingChanged = true;
    // Compare editQuiz with quizzesRedux

    const filteredQuizRedux = quizzesRedux.filter(
      (quiz) => quiz.lesson_id === lessonId
    );

    if (editQuiz.length !== filteredQuizRedux.length) {
      nothingChanged = false;
    }

    for (let i = 0; i < editQuiz.length; i++) {
      const quiz = editQuiz[i];

      const originalQuiz = quizzesRedux.find((q) => q.id === quiz.id);

      if (!originalQuiz || quiz.question !== originalQuiz.question) {
        // Question text has changed or originalQuiz not found, so changes were made
        nothingChanged = false;
        break;
      }

      let emptyQuestionWithAnswers = false;

      //check if a answer is added to a question

      if (quiz.answers.length !== originalQuiz.answers.length) {
        nothingChanged = false;
        break;
      }

      for (let j = 0; j < quiz.answers.length; j++) {
        const answer = quiz.answers[j];
        if (answer.answer !== "") {
          emptyQuestionWithAnswers = true;
          break;
        }
      }

      // Compare answers
      for (let j = 0; j < quiz.answers.length; j++) {
        const answer = quiz.answers[j];
        const originalAnswer = originalQuiz.answers[j];

        if (
          answer.answer !== originalAnswer.answer ||
          answer.correct !== originalAnswer.correct
        ) {
          // Answer text or correctness has changed, so changes were made
          nothingChanged = false;
          break;
        }
      }
    }

    if (nothingChanged) {
      setError("No changes made to the quiz");
      return;
    }

    let isValid = true;
    let questionId = 0;

    // Check if all questions have at least two answers with one correct option
    for (let i = 0; i < editQuiz.length; i++) {
      const question = editQuiz[i];
      if (question.question === "") {
        let numAnswers = 0;
        let numCorrect = 0;

        for (let j = 0; j < question.answers.length; j++) {
          const option = question.answers[j];

          if (option.answer !== "") {
            numAnswers++;

            if (option.correct) {
              numCorrect++;
            }
          }
        }

        if (numAnswers < 2 || numCorrect === 0) {
          isValid = false;
          questionId = i + 1;
          break;
        }
      } else {
        let numAnswers = 0;
        let numCorrect = 0;

        for (let j = 0; j < question.answers.length; j++) {
          const option = question.answers[j];

          if (option.answer !== "") {
            numAnswers++;

            if (option.correct) {
              numCorrect++;
            }
          }
        }

        if (numAnswers < 2 || numCorrect === 0) {
          isValid = false;
          questionId = i + 1;
          break;
        }
      }
    }

    if (!isValid) {
      setError(
        `Please provide a question and minimum two answers with at least one correct option for question ${questionId}`
      );
      return;
    }

    try {
      const updateQuiz = await quizService.updateQuiz(editQuiz, lessonId);
      if (updateQuiz.message === "success") {
        toast.success("Quiz updated successfully");
        dispatch(setQuizzes({ quizzes: updateQuiz.quizzes }));
        dispatch(editQuizPopupOpen({ editQuizPopupOpen: false }));
      } else {
        console.log("error updating quiz", updateQuiz);
        toast.error("Quiz not updated");
      }
    } catch (err) {
      console.log(err, "error updating quiz");
    }
  };

  const handleQuizDelete = async () => {
    setError("");
    if (!confirmQuizDelete) {
      setConfirmQuizDelete(true);
      return;
    }

    if (!approveQuizDelete) {
      setError("Must confirm quiz deletion");
      return;
    }

    try {
      const deleteQuiz = await quizService.deleteQuiz(lessonId);
      if (deleteQuiz.message === "success") {
        toast.success("Quiz deleted successfully");
        dispatch(setQuizzes({ quizzes: deleteQuiz.quizzes }));
        dispatch(editQuizPopupOpen({ editQuizPopupOpen: false }));
      } else {
        console.log("error deleting quiz", deleteQuiz);
        toast.error("Quiz not deleted");
      }
    } catch (err) {
      console.log(err, "error deleting quiz");
    }
  };

  return (
    <>
      <div className="edit-quiz-popup-wrapper">
        <div className="header">
          <h1 className="title">Edit Quiz</h1>
          <div
            className="close"
            onClick={() =>
              dispatch(editQuizPopupOpen({ editQuizPopupOpen: false }))
            }
          >
            <IconContext.Provider
              value={{
                size: "3.2rem",

                color: "#000000",
                style: { strokeWidth: "15px" },
              }}
            >
              <IoIosClose />
            </IconContext.Provider>
          </div>
        </div>
        <div className="body">
          {editQuiz.map((quiz, index) => (
            <EditQuizPopupQuestionContainer
              amountOfQuestions={editQuiz.length}
              key={quiz.id}
              currentQuestionObject={quiz}
              indexOfQuestion={index}
              questionId={quiz.id}
              question={quiz.question}
              lessonId={lessonId}
              setError={setError}
              quizData={editQuiz}
              setQuizData={setEditQuiz}
            />
          ))}
          {editQuiz.length < 5 && (
            <div className="add-question" onClick={handleAddQuestion}>
              <IconContext.Provider value={{ color: "#000000", size: "2rem" }}>
                <IoIosAdd />
                <span>add question</span>
              </IconContext.Provider>
            </div>
          )}

          <div
            className="error"
            style={{ margin: "30px", textAlign: "center", fontSize: "1.2rem" }}
          >
            {error}
          </div>
        </div>
        {confirmQuizDelete && (
          <div className="confirm-quiz-delete">
            <input
              type="checkbox"
              name="confirm-quiz-delete"
              id="confirm-quiz-delete"
              checked={approveQuizDelete}
              onChange={(e) => setApproveQuizDelete(e.target.checked)}
            />
            <label htmlFor="confirm-quiz-delete">
              Are you sure you want to delete this quiz?
            </label>
          </div>
        )}
        <div className="quizEdit-buttons">
          <div className="update-btn">
            <button onClick={handleQuizUpdate}>Save</button>
          </div>
          <div className="delete-quiz">
            <button onClick={handleQuizDelete}>Delete</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditQuizPopup;
