import "./style/create-quiz-popup.css";
import { IconContext } from "react-icons";
import { IoIosClose, IoIosAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { setCreateQuizPopup, setQuizzes } from "../../redux/superAdminReducer";
import { toast } from "react-toastify";
import QuestionContainer from "./question-container";
import { useEffect, useState } from "react";
import { v4 as uuid4 } from "uuid";
import { useMediaQuery } from "react-responsive";
import quizService from "../../services/quizService";
const CreateQuizPopup = () => {
  const desktopView = useMediaQuery({ query: "(min-width:740px)" });
  const [questionNumber, setQuestionNumber] = useState([1]);
  const [error, setError] = useState("");
  const [quizData, setQuizData] = useState([]);
  const { lessonId } = useSelector((state) => state.superAdminSlice.value);

  useEffect(() => {
    if (questionNumber.length === 1) {
      setQuizData([]);
      setQuizData((prevData) => {
        const updatedData = [...prevData];
        const questionObject = {
          questionId: 1,
          question: "",
          options: [
            { answer: "", correct: false, answerId: questionNumber.length },
            { answer: "", correct: false, answerId: questionNumber.length + 1 },
          ],
        };
        updatedData.push(questionObject);
        return updatedData;
      });
    } else if (questionNumber.length > 1) {
      setQuizData((prevData) => {
        const lastQuestionId = prevData[prevData.length - 1].questionId;
        const questionObject = {
          questionId: lastQuestionId + 1,
          question: "",
          options: [
            { answer: "", correct: false, answerId: 1 },
            { answer: "", correct: false, answerId: 2 },
          ],
        };
        return [...prevData, questionObject];
      });
    }
  }, [questionNumber]);
  useEffect(() => {
    if (!desktopView) {
      dispatch(setCreateQuizPopup({ quizPopupOpen: false }));
    }
  }, [desktopView]);

  const handleAddQuestion = () => {
    if (questionNumber.length < 5) {
      setQuestionNumber([...questionNumber, questionNumber.length + 1]);
    }
  };

  const handleQuizSubmit = async () => {
    setError("");
    let hasValidQuestion = false;
    let isValid = true;
    let questionId = 0;
    for (let i = 0; i < quizData.length; i++) {
      const question = quizData[i];

      if (question.question !== "") {
        hasValidQuestion = true;
        questionId = question.questionId;
        let numAnswers = 0;
        let numCorrect = 0;

        for (let j = 0; j < question.options.length; j++) {
          const option = question.options[j];

          if (option.answer !== "") {
            numAnswers++;

            if (option.correct) {
              numCorrect++;
            }
          }
        }

        if (numAnswers < 2 || numCorrect === 0) {
          isValid = false;
          break;
        }
      }
    }

    if (!hasValidQuestion) {
      setError("At least one question is required");
      return;
    } else if (!isValid) {
      setError(
        `Please provide at least two answers with at least one correct option for question ${questionId}`
      );
      return;
    }
    // }
    try {
      const response = await quizService.createQuiz(lessonId, quizData);
      if (response.message === "success") {
        dispatch(setQuizzes({ quizzes: response.quizzes }));
        dispatch(setCreateQuizPopup({ createQuizPopupOpen: false }));
        toast.success("Quiz created successfully");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err, "error in quiz submit");
    }
  };

  const dispatch = useDispatch();
  return (
    <>
      <div className="create-quiz-popup-wrapper">
        <div className="header">
          <h1 className="title">Create Quiz</h1>
          <div
            className="close"
            onClick={() =>
              dispatch(setCreateQuizPopup({ createQuizPopupOpen: false }))
            }
          >
            <IconContext.Provider
              value={{
                size: "2.5rem",
                color: "#000000",
              }}
            >
              <IoIosClose />
            </IconContext.Provider>
          </div>
        </div>
        <div className="body">
          {quizData.length && (
            <>
              {quizData.map((question, index, arr) => {
                return (
                  <QuestionContainer
                    key={index}
                    indexOfQuestion={index}
                    questionId={question.questionId}
                    question={question.question}
                    lessonId={lessonId}
                    setError={setError}
                    quizData={arr}
                    setQuizData={setQuizData}
                  />
                );
              })}
            </>
          )}

          {questionNumber.length < 5 && (
            <div className="add-question" onClick={handleAddQuestion}>
              <IconContext.Provider value={{ color: "#000000", size: "2rem" }}>
                <IoIosAdd />
                <span>add question</span>
              </IconContext.Provider>
            </div>
          )}

          <div
            className="error"
            style={{ margin: "30px", textAlign: "center", fontSize: "1rem" }}
          >
            {error}
          </div>

          <div className="quiz-submit" onClick={handleQuizSubmit}>
            <button>Submit</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateQuizPopup;
