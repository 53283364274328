import { ReactSearchAutocomplete } from "react-search-autocomplete";
// import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import "./style/searchBar-mobile.css";
import { setSearchItemMobile } from "../../redux/reducer";
const SearchBarMobile = ({ context, searchContext, setShowView }) => {
  //   const [showSelect, setShowSelect] = useState(false);
  const autoCompleteRef = useRef(null);
  const [messages, setMessages] = useState([]);
  // const [searchedMessages, setSearchedMessages] = useState({});
  // const [messagesIds, setMessagesIds] = useState([]);
  const [itemsForSearch, setItemsForSearch] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);

  const messagesRedux = useSelector(
    (state) => state.allPermission.value.messages
  );
  const filesRedux = useSelector((state) => state.user.value.files);
  const lessonsRedux = useSelector((state) => state.user.value.lessons);

  useEffect(() => {
    updateItemsForSearch();
  }, [context, searchContext, messagesRedux, filesRedux, lessonsRedux]);
  const updateItemsForSearch = () => {
    let newItems = [];
    if (context === "user") {
      switch (searchContext) {
        case "messages":
          newItems = messagesRedux.map((message) => ({
            id: message.id,
            name: message.title,
            context: "messages",
            userContext: "user",
          }));
          break;
        case "files":
          newItems = filesRedux.map((file) => ({
            id: file.lesson_id,
            name: file.lesson_title,
            context: "files",
            userContext: "user",
          }));
          break;
        case "lessons":
          newItems = lessonsRedux
            .reduce((acc, curr) => acc.concat(curr.lessons), [])
            .map((lesson) => ({
              id: lesson.id,
              name: lesson.title,
              context: "lessons",
              userContext: "user",
            }));
          break;
        default:
          break;
      }
    } else {
      // context admin or super admin both can only search in messages
      newItems = messagesRedux.map((message) => ({
        id: message.id,
        name: message.title,
        context: "messages",
        userContext: "admin/superAdmin",
      }));
    }
    setItemsForSearch(newItems);
  };
  const dispatch = useDispatch();
  const handleOnSearch = (string, results) => {
    if (string.trim() === "") {
      updateItemsForSearch();
      return;
    }

    if (!itemsForSearch || itemsForSearch.length === 0) {
      return;
    }

    let filteredItems = [];

    try {
      if (searchContext === "files") {
        filteredItems = itemsForSearch.filter((item) => {
          return (
            item.name && item.name.toLowerCase().includes(string.toLowerCase())
          );
        });
      } else {
        filteredItems = itemsForSearch.filter((item) => {
          return (
            item.name && item.name.toLowerCase().includes(string.toLowerCase())
          );
        });
      }

      setItemsForSearch(filteredItems);
    } catch (error) {
      console.error("Error during search filtering:", error);
    }
  };

  const handleDelete = (e) => {
    // Rest of your code
    // console.log(e, "e on delete");
  };

  const handleKeyUp = (e) => {
    const value = e.target.value;
    if (value === "" && e.key === "Backspace") {
      dispatch(setSearchItemMobile({ searchItemMobile: [] }));
    }
  };
  const handleOnSelect = (item) => {
    // the item selected
    let selected;
    switch (item.context) {
      case "messages":
        selected = messagesRedux.filter((message) => message.id === item.id);
        const modifiedMessage = {
          ...selected[0],
          userContext: item.userContext,
          context: item.context,
        };
        dispatch(setSearchItemMobile({ searchItemMobile: [modifiedMessage] }));
        break;

      case "files":
        selected = filesRedux.filter((file) => file.lesson_id === item.id);

        const modifiedFile = {
          ...selected[0],
          userContext: item.userContext,
          context: item.context,
        };
        dispatch(setSearchItemMobile({ searchItemMobile: [modifiedFile] }));
        break;

      case "lessons":
        selected = lessonsRedux
          .reduce((accumulator, currentObject) => {
            const lessonsInCurrentObject = currentObject.lessons;
            return accumulator.concat(lessonsInCurrentObject);
          }, [])
          .filter((lesson) => lesson.id === item.id);
        const modifiedLesson = {
          ...selected[0],
          userContext: item.userContext,
          context: item.context,
        };
        dispatch(setSearchItemMobile({ searchItemMobile: [modifiedLesson] }));
        break;

      default:
        return;
    }

    setShowView("mobile-search-results");
  };

  const formatResult = (item) => {
    return (
      <>
        <div
          style={{
            display: "block",
            textAlign: "left",
            cursor: "pointer",
          }}
        >
          {`result: ${item.name}`}
        </div>
      </>
    );
  };
  // default styles for ReactSearchAutocomplete
  const styles = {
    height: "44px",
    border: "1px solid #dfe1e5",
    borderRadius: "24px",
    backgroundColor: "white",
    boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
    hoverBackgroundColor: "#eee",
    color: "#212121",
    fontSize: "16px",
    fontFamily: "Arial",
    iconColor: "black",
    lineColor: "#cb2a27",
    placeholderColor: "black",
    clearIconMargin: "3px 14px 0 0",
    searchIconMargin: "0 0 0 16px",
  };

  return (
    <>
      <div
        className="search-bar-mobile"
        onKeyUp={(e) => handleKeyUp(e)}
        ref={autoCompleteRef}
      >
        <ReactSearchAutocomplete
          onClear={(e) => handleDelete(e)}
          items={itemsForSearch}
          onSearch={handleOnSearch}
          onSelect={handleOnSelect}
          placeholder={`Search in ${searchContext}`}
          formatResult={formatResult}
          styling={styles}
        />
      </div>
    </>
  );
};

export default SearchBarMobile;
