import NavBar from "../common/navbar";
import "./style/index.css";
import { useMediaQuery } from "react-responsive";
import { useState, useEffect } from "react";
import Community from "../common/community";
import Statistics from "../common/statistics";
import AdminUsers from "./admin-users";
import NotificationsContainer from "../common/notificationsContainer";
import MobileSideNav from "../common/mobile-header";
import InviteMemberForm from "../common/invite-member-form";
import MobileFooter from "../common/mobile-footer";
import BackButton from "../common/backButton";
import SearchBarMobile from "../../Components/common/searchBar-mobile";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setUsersFromDb,
  setAdminStatistics,
  setRating,
  setCurrentUsersCompletion,
  setPendingUsers,
} from "../../redux/adminReducer";
import services from "../../services/userService";
import messageService from "../../services/messageService";
import notificationsService from "../../services/notificationsService";
import {
  setNotifications,
  setListOfAlreadyReadNotifications,
  setAuthorization,
  setCurrentUserEmail,
  setMessages,
} from "../../redux/reducer";
import userService from "../../services/userService";
import SearchResultsMobile from "../common/search-results-mobile";
const AdminHomePage = () => {
  const desktopView = useMediaQuery({ query: "(min-width:740px)" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showView, setShowView] = useState("");
  const authorization = useSelector(
    (state) => state.allPermission.value.authorization
  );
  const adminStatisticsRedux = useSelector(
    (state) => state.adminReducer.value.adminStatistics
  );
  const ratingFromRedux = useSelector(
    (state) => state.adminReducer.value.adminRating
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchAdminStatistics = await userService.fetchAdminStats();
        const fetchAverageLessonRating =
          await userService.fetchAverageLessonRating();
        const data = await services.fetchUsers();
        /* redux */
        const messages = await messageService.fetchMessages();

        const notifications = await notificationsService.fetchNotifications(
          authorization
        );
        return {
          data,
          messages,
          notifications,
          fetchAdminStatistics,
          fetchAverageLessonRating,
        };
      } catch (err) {
        console.log(err, "err fetch users ");
        throw err;
      }
    };

    const checkTokenAndFetchUsers = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        return navigate("/login");
      } else {
        const checkAuth = await services.checkAuth();
        if (checkAuth.message === "authorized" && checkAuth.auth === 2) {
          dispatch(setAuthorization({ authorization: "admin" }));
        } else {
          return navigate("/login");
        }
        try {
          if (authorization === "admin") {
            const {
              data,
              messages,
              notifications,
              fetchAdminStatistics,
              fetchAverageLessonRating,
            } = await fetchData();
            if (fetchAverageLessonRating.message === "success") {
              dispatch(
                setRating({
                  rating: fetchAverageLessonRating.rating,
                })
              );
            } else {
              console.log(
                fetchAverageLessonRating,
                "fetchAverageLessonRating errors"
              );
            }

            if (fetchAdminStatistics.message === "success") {
              function calculateSectionCompletionPercentage(arrayOfUsers) {
                const userStats = [];
                arrayOfUsers.forEach((user) => {
                  const { userId, stats, userEmail, userName } = user;

                  let totalLessons = 0;
                  let totalEndedLessons = 0;

                  stats.forEach((section) => {
                    const { amountOfLessons, lessonsEnded } = section;
                    totalLessons += amountOfLessons;
                    totalEndedLessons += lessonsEnded;
                  });

                  const completionPercentage =
                    (totalEndedLessons / totalLessons) * 100;
                  userStats.push({
                    userId,
                    userEmail,
                    userName,
                    completionPercentage: parseFloat(
                      completionPercentage.toFixed(2)
                    ),
                  });
                });

                return userStats;
              }

              const userCompletionPercentages =
                calculateSectionCompletionPercentage(
                  fetchAdminStatistics.stats
                );

              dispatch(
                setAdminStatistics({
                  stats: fetchAdminStatistics.stats,
                })
              );
              dispatch(
                setCurrentUsersCompletion({
                  currentUsersCompletion: userCompletionPercentages,
                })
              );
            } else {
              setAdminStatistics({ stats: [] });
            }
            if (notifications?.message === "success") {
              dispatch(
                setListOfAlreadyReadNotifications({
                  listOfAlreadyReadNotifications:
                    notifications.userListOfReadNotifications,
                })
              );
              dispatch(
                setNotifications({ notifications: notifications.notifications })
              );
            }

            if (data.message === "users") {
              dispatch(setUsersFromDb({ users: data.users }));

              // if pending users exist, set them in redux
              if (data.pendingUsers !== null) {
                dispatch(setPendingUsers({ pendingUsers: data.pendingUsers }));
              }
            } else {
              console.log(data, "admin error fetching users");
            }
            if (messages.message === "success") {
              dispatch(setMessages({ messages: messages.messages }));
              dispatch(
                setCurrentUserEmail({
                  currentUserEmail: messages.currentUserEmail,
                })
              );
            } else {
              console.log(messages, "admin error fetching messages");
            }
          }
        } catch (err) {
          console.log(err, "errrrr");
          throw err;
        }
      }
    };

    checkTokenAndFetchUsers();

    if (desktopView) {
      setShowView("community");
      return;
    }
    setShowView("mobile-start-screen");
  }, [authorization, desktopView]);

  useEffect(() => {
    if (!desktopView) {
      if (showView === "community") {
        setShowView("mobile-start-screen");
      }
      if (showView === "users") {
        setShowView("mobile-users");
      }
      if (showView === "statistics") {
        setShowView("mobile-statistics");
      }
    }
  }, [desktopView, showView]);

  return (
    <>
      {/* DESKTOP VIEW */}

      {desktopView ? (
        <>
          <main className="admin-home-page">
            <NavBar
              setShowView={setShowView}
              context="admin"
              showView={showView}
            />
            <div className="main-content-container">
              <NotificationsContainer />
              <div
                className={showView === "statistics" ? "board-black" : "board"}
              >
                {showView === "community" ? (
                  <Community />
                ) : showView === "statistics" ? (
                  <Statistics
                    rating={ratingFromRedux}
                    adminStats={adminStatisticsRedux}
                  />
                ) : (
                  <AdminUsers />
                )}
              </div>
            </div>
          </main>
        </>
      ) : (
        <>
          {/* MOBILE VIEW */}
          <MobileSideNav setShowView={setShowView} />
          <div
            className={
              showView === "mobile-community"
                ? "admin-home-page-mobile-community"
                : "admin-home-page-mobile"
            }
          >
            {showView === "mobile-start-screen" && (
              <>
                <NotificationsContainer permission="admin" />
                <h3 className="admin-invite-member">Invite New Member:</h3>
                <InviteMemberForm context="admin" />
              </>
            )}
            {/* mobile search results view */}
            {showView === "mobile-search-results" && (
              <>
                <SearchResultsMobile setShowView={setShowView} />
              </>
            )}
            <div className="admin-mobile-wrapper">
              {showView === "mobile-community" && (
                <>
                  <div
                    className="mobile-community-top"
                    style={{
                      borderRadius:
                        showView === "mobile-community" ? "0" : "0 0 12px 12px",
                    }}
                  >
                    <BackButton
                      context="admin"
                      setShowView={setShowView}
                      title="Community Messages"
                    />
                    <div className="search">
                      <SearchBarMobile
                        setShowView={setShowView}
                        context={authorization}
                        searchContext="messages"
                      />
                    </div>
                    <Community
                      permission={"admin"}
                      // showMessagePopup={showMessagePopup}
                      // setMessagePopup={setMessagePopup}
                    />
                  </div>
                </>
              )}

              {showView === "mobile-users" && (
                <>
                  {
                    <BackButton
                      setShowView={setShowView}
                      title="Users"
                      context="admin"
                    />
                  }
                  <AdminUsers />
                </>
              )}
              {showView === "mobile-statistics" && (
                <>
                  {
                    <BackButton
                      setShowView={setShowView}
                      context="admin"
                      title="Statistics"
                    />
                  }
                  <Statistics
                    adminStats={adminStatisticsRedux}
                    rating={ratingFromRedux}
                  />
                </>
              )}
            </div>
          </div>
        </>
      )}
      {/* </main> */}
      {!desktopView && <MobileFooter showView={showView} />}
    </>
  );
};

export default AdminHomePage;
