import { useState, useRef, useEffect } from "react";
import "./style/login.css";
import axiosBaseUrl from "../../utils/axios";
import { emailRegex, passwordRegex } from "../../utils/regex";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import services from "../../services/userService";
import { setAuthorization } from "../../redux/reducer";
import { useMediaQuery } from "react-responsive";

const LoginForm = ({ formClass, inputClass }) => {
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const desktopView = useMediaQuery({ query: "(min-width:600px)" });

  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogin = async (e) => {
    e.preventDefault();
    setPasswordErrorMessage("");
    setEmailErrorMessage("");
    const emailMatch = userEmail.match(emailRegex);
    if (userEmail === "" || !emailMatch) {
      setEmailErrorMessage("Enter A Valid Email");
      return;
    } else {
      setEmailErrorMessage("");
    }
    const passwordMatch = userPassword.match(passwordRegex);

    if (userPassword === "") {
      setPasswordErrorMessage("Please Enter A Password");
      return;
    }
    if (!passwordMatch) {
      setPasswordErrorMessage(
        "Must contain at least 9  characters, including 1 uppercase letter and 1 number"
      );
      return;
    } else {
      setPasswordErrorMessage("");
    }

    try {
      const res = await services.login(userEmail, userPassword);

      if (res?.token) {
        localStorage.setItem("token", res.token);
        if (res.permission == 1) {
          dispatch(setAuthorization({ authorization: "user" }));
          return navigate("/user-home");
        } else if (res.permission == 2) {
          dispatch(setAuthorization({ authorization: "admin" }));

          return navigate("/admin-home");
        } else if (res.permission == 3) {
          dispatch(setAuthorization({ authorization: "super-admin" }));

          return navigate("/super-home");
        }
      } else {
        setPasswordErrorMessage(res.message);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleForgotPassword = (e) => {
    e.preventDefault();
    return navigate("/forgot-password");
  };
  return (
    <>
      <form className={`login-${formClass}`} id="login-form">
        <div className="form-wrapper">
          <input
            value={userEmail}
            onChange={(e) => {
              setEmailErrorMessage("");
              setPasswordErrorMessage("");
              setUserEmail(e.target.value);
            }}
            className={inputClass}
            type="email"
            placeholder="Email:"
            name="login-email"
            id="login-email"
            autoComplete="on"
          />
          <span className="error">{emailErrorMessage}</span>
        </div>
        <div className="form-wrapper">
          <input
            value={userPassword}
            onChange={(e) => {
              setEmailErrorMessage("");
              setPasswordErrorMessage("");
              setUserPassword(e.target.value);
            }}
            className={inputClass}
            placeholder="Password:"
            type="password"
            name="login-password"
            id="login-password"
            autoComplete="off"
          />
          <span className="error">{passwordErrorMessage}</span>
        </div>
        {desktopView && (
          <>
            <div className="forgot-password">
              <div
                className="forgot-password-btn"
                onClick={(e) => handleForgotPassword(e)}
              >
                Forgot Password?
              </div>
            </div>
          </>
        )}
        <div className="form-wrapper">
          <input
            className={`login-${inputClass}`}
            type="button"
            value="Enter"
            id="login-btn"
            onClick={(e) => {
              handleLogin(e);
            }}
          />
        </div>
      </form>
    </>
  );
};

export default LoginForm;
