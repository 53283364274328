import { useEffect } from "react";

const Answer = ({
  answerId,
  quizData,
  setQuizData,
  indexOfQuestion,
  indexOfAnswer,
  correct,
  answer,
  setError,
}) => {
  useEffect(() => {}, []);

  const handleAnswerChange = (e) => {
    const newQuizData = [...quizData];
    newQuizData[indexOfQuestion].options[indexOfAnswer].answer = e.target.value;
    setQuizData(newQuizData);
  };

  const handleCheckboxChange = (e) => {
    setError("");
    const newQuizData = [...quizData];
    newQuizData[indexOfQuestion].options[indexOfAnswer].correct =
      e.target.checked;
    setQuizData(newQuizData);
  };

  return (
    <>
      <div className="form-wrapper-answer">
        <div className="answer-input">
          <input
            type="text"
            value={answer}
            onChange={handleAnswerChange}
            name={`answer-${indexOfQuestion}-${indexOfAnswer}`}
            placeholder={`Answer ${answerId}`}
          />
        </div>

        <div className="answer-correct">
          <input
            type="checkbox"
            checked={correct}
            onChange={handleCheckboxChange}
            name={`answer-checkbox-${indexOfQuestion}-${indexOfAnswer}`}
          />
          <label
            htmlFor={`answer-checkbox-${indexOfQuestion}-${indexOfAnswer}`}
          >
            Correct answer
          </label>
        </div>
      </div>
    </>
  );
};

export default Answer;
