import "./style/index.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { setCurrentLessonDetails } from "../../redux/userReducer";
import lessonsService from "../../services/lessonsService";
import { useDispatch } from "react-redux";

const UserMobileStartScreen = ({ setShowView }) => {
  const [userName, setUserName] = useState("");
  const dispatch = useDispatch();
  const [mainProgressBar, setMainProgressBar] = useState(0);
  const mainProgressRedux = useSelector(
    (state) => state.user.value.lessonProgressAverage
  );
  const userNameRedux = useSelector((state) => state.user.value.userName);

  useEffect(() => {
    if (userNameRedux) setUserName(userNameRedux);
  }, [userNameRedux]);

  useEffect(() => {
    if (mainProgressRedux) {
      setMainProgressBar(mainProgressRedux);
    }
  }, [mainProgressRedux]);

  const handleNextVideo = async () => {
    try {
      const lastUpdatedLesson = await lessonsService.fetchLastUpdatedLesson();
      if (lastUpdatedLesson.message === "success") {
        dispatch(
          setCurrentLessonDetails({
            videoUrl: lastUpdatedLesson.url,
            lessonId: lastUpdatedLesson.lessonId,
            lessonTitle: lastUpdatedLesson.lessonTitle,
            lessonDescription: lastUpdatedLesson.lessonDescription,
            sectionName: lastUpdatedLesson.sectionName,
            files: lastUpdatedLesson.lessonFiles,
          })
        );
        setShowView("user-mobile-video-intro");
      }
    } catch (error) {
      console.log(error, "error lastUpdatedLesson");
    }
  };

  return (
    <>
      <div className="user-mobile-wrapper">
        <div className="welcome">
          <h2>{`HELLO  ${userName}`} </h2>
          <p className="long-title">Welcome to MeatU</p>
          {/* <p>Welcome to MeatU</p> */}
        </div>
        <div className="progress-bar">
          <div className="progress-title">
            <span>Moooving on</span>
            <span>{`${mainProgressBar}%`}</span>
          </div>
          <ProgressBar
            animated
            min={0}
            max={100}
            // className="user-progress"
            now={mainProgressRedux}
            variant="custom-css"
          />
        </div>

        <div className="next-video">
          <button
            onClick={
              handleNextVideo
              // () => setShowView("user-mobile-video-intro")
            }
          >
            Next Video
          </button>
        </div>
      </div>
    </>
  );
};

export default UserMobileStartScreen;
