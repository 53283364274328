import "./style/login.css";
import axiosBaseUrl from "../../utils/axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../../redux/reducer";
import { emailRegex, passwordRegex, phoneNoRegex } from "../../utils/regex";
import services from "../../services/userService";
import { toast } from "react-toastify";
const RegisterForm = ({ formClass, inputClass }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [language, setLanguage] = useState("english");
  const [subscribe, setSubscribe] = useState(false);

  const firstNameErrorMessage = useRef();
  const lastNameErrorMessage = useRef();
  const emailErrorMessage = useRef();
  const phoneErrorMessage = useRef();
  const passwordErrorMessage = useRef();
  const languageErrorMessage = useRef();
  const subscribeErrorMessage = useRef();
  const dispatch = useDispatch();

  const noSpecialCharactersInput = (e) => {
    let onlyCharacters;

    switch (e.id) {
      case "register-first-name":
        // ^[\u05D0-\u05EA\u0590-\u05FFa-zA-Z]+$

        onlyCharacters = e.value.replace(/[^a-z\s]/gi, "");
        setFirstName(onlyCharacters);
        break;
      case "register-last-name":
        onlyCharacters = e.value.replace(/[^a-z\s]/gi, "");
        setLastName(onlyCharacters);
        break;

      default:
        break;
    }
  };
  const handleRegistration = async () => {
    let entryAddedToDb = false;

    // subscribeErrorMessage.current.classList.remove("d-none");
    if (firstName === "") {
      firstNameErrorMessage.current.classList.remove("d-none");
      return;
    } else {
      firstNameErrorMessage.current.classList.add("d-none");
    }

    if (lastName === "") {
      lastNameErrorMessage.current.classList.remove("d-none");
      return;
    } else {
      lastNameErrorMessage.current.classList.add("d-none");
    }

    if (email !== "") {
      const emailMatch = email.match(emailRegex);
      if (emailMatch) {
        emailErrorMessage.current.classList.add("d-none");
      } else {
        emailErrorMessage.current.classList.remove("d-none");
        return;
      }
    } else {
      emailErrorMessage.current.classList.remove("d-none");
      return;
    }

    if (phone !== "") {
      const phoneMatch = phone.match(phoneNoRegex);
      if (phoneMatch) {
        phoneErrorMessage.current.classList.add("d-none");
      } else {
        phoneErrorMessage.current.classList.remove("d-none");
        return;
      }
    }

    if (password !== "") {
      const passwordMatch = password.match(passwordRegex);
      if (passwordMatch) {
        passwordErrorMessage.current.classList.add("d-none");
      } else {
        passwordErrorMessage.current.classList.remove("d-none");
        return;
      }
    } else {
      passwordErrorMessage.current.classList.remove("d-none");
      return;
    }

    if (language === "" || language === "Choose Language") {
      languageErrorMessage.current.classList.remove("d-none");
      return;
    } else {
      languageErrorMessage.current.classList.add("d-none");
    }

    // if (subscribe) {
    //   subscribeErrorMessage.current.classList.add("d-none");
    // } else {
    //   subscribeErrorMessage.current.classList.remove("d-none");
    //   return;
    // }

    try {
      if (!entryAddedToDb) {
        const res = await services.register(
          firstName,
          lastName,
          email,
          phone,
          password,
          language
        );

        if (res.message === "Successfully Registered") {
          toast.success("Registered! Please log in.");
          entryAddedToDb = true;
        } else {
          toast.success(res.message);
        }
        //  subscribeErrorMessage.current.innerHTML = res.message;
        // subscribeErrorMessage.current.classList.remove("d-none");
      }
    } catch (err) {
      console.log(err, "registration error");
    }
  };
  return (
    <>
      <form className={`register-${formClass}`} id="register-form">
        <div className="form-wrapper">
          <input
            value={firstName}
            onChange={(e) => noSpecialCharactersInput(e.target)}
            className={inputClass}
            type="text"
            placeholder="First Name:"
            name="register-first-name"
            id="register-first-name"
          />
          <span ref={firstNameErrorMessage} className="error d-none">
            First Name Is Required
          </span>
        </div>
        <div className="form-wrapper">
          <input
            value={lastName}
            onChange={(e) => noSpecialCharactersInput(e.target)}
            className={inputClass}
            type="text"
            placeholder="Last Name:"
            name="register-last-name"
            id="register-last-name"
            // onKeyDown={(e) => e.target.value.replace (/^[A-Za-z]+$/)}
          />
          <span ref={lastNameErrorMessage} className="error d-none">
            Last Name Is Required
          </span>
        </div>
        <div className="form-wrapper">
          <input
            value={email}
            onChange={(e) => {
              if (!emailErrorMessage.current.className.includes("d-none")) {
                emailErrorMessage.current.classList.add("d-none");
              }
              setEmail(e.target.value);
            }}
            className={inputClass}
            type="email"
            placeholder="Email:"
            name="register-email"
            id="register-email"
            autoComplete="off"
          />
          <span ref={emailErrorMessage} className="error d-none">
            Invalid Email address
          </span>
        </div>
        <div className="form-wrapper">
          <input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="number"
            className={inputClass}
            placeholder="Phone:"
            name="register-phone"
            id="register-phone"
          />
          <span ref={phoneErrorMessage} className="error d-none">
            Please enter a valid phone number.
          </span>
        </div>
        <div className="form-wrapper">
          <input
            value={password}
            onChange={(e) => {
              if (!passwordErrorMessage.current.className.includes("d-none")) {
                passwordErrorMessage.current.classList.add("d-none");
              }
              setPassword(e.target.value);
            }}
            type="password"
            className={inputClass}
            placeholder="Password:"
            name="register-password"
            id="register-password"
            autoComplete="off"
          />
          <span ref={passwordErrorMessage} className="error d-none">
            Password must contain at least 9 characters, including 1 uppercase
            letter and 1 number
          </span>
        </div>
        <div className="form-wrapper">
          <select
            name="language"
            value={language}
            id="language"
            className={inputClass}
            onChange={(e) => setLanguage(e.target.value)}
          >
            <option value="english">English</option>
            <option value="hebrew">Hebrew</option>
            <option value="arabic">Arabic</option>
          </select>
          <span ref={languageErrorMessage} className="error d-none">
            Language Is Required
          </span>
        </div>

        <div className="form-wrapper checkbox">
          <label htmlFor="subscribe" className={inputClass}>
            Your information will be handled in accordance with our
            <span
              onClick={() =>
                window.open(
                  "https://www.redefinemeat.com/privacy-policy/",
                  "_blank"
                )
              }
            >
              {" "}
              Privacy Policy
            </span>
          </label>
          {/* <span ref={subscribeErrorMessage} className="error d-none">
            You must agree to subscribe to MeatU
          </span> */}
        </div>

        {/* <div className="form-wrapper checkbox">
          <label htmlFor="subscribe" className={inputClass}>
            <input
              checked={subscribe}
              onChange={(e) => setSubscribe(e.target.checked)}
              className={inputClass}
              type="checkbox"
              name="subscribe"
              id="subscribe"
            />
            <span> Subscribe to MeatU</span>
          </label>
          <span ref={subscribeErrorMessage} className="error d-none">
            You must agree to subscribe to MeatU
          </span>
        </div> */}
        <div className="form-wrapper mobile-btn">
          <input
            onClick={handleRegistration}
            className={`register-${inputClass}`}
            type="button"
            value="Register"
            id="register-btn"
          />
        </div>
      </form>
    </>
  );
};

export default RegisterForm;
