import "./style/navbar.css";
import leftLogo from "../img/logo2.png";
import rightLogo from "../img/logo.png";
import { BiMessageDetail, BiUser } from "react-icons/bi";
import { AiOutlinePieChart, AiOutlineUpload } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { MdOutlineAttachEmail } from "react-icons/md";
import { SlLogout } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";

import { RiDownloadFill } from "react-icons/ri";
// import { MdOutlineMessage } from "react-icons/md";
import { TiThList } from "react-icons/ti";
import { useState } from "react";
import {
  handleSearchPopup,
  setSearchResultsView,
} from "../../redux/userReducer";
import { useEffect } from "react";
const NavBar = ({ setShowView, context, showView }) => {
  const fontSizeBreakPoint = useMediaQuery({ query: "(min-width:1150px)" });

  // const [showSearch, setShowSearch] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDesktopSearchPopup = useSelector(
    (state) => state.user.value.searchPopupOpen
  );
  const searchResultsInView = useSelector(
    (state) => state.user.value.searchResultsView
  );
  const handleContactUs = (e) => {
    const tempLink = document.createElement("a");
    tempLink.href = "mailto:meatu@redefinemea.com";
    tempLink.style.display = "none"; // Hide the element
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  };
  const handleSignOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    navigate("/login");
  };
  return (
    <>
      <nav
        className={context === "user" ? "navbar user" : "navbar"}
        style={{
          background: context === "user" ? "#CB2A27" : "",
          padding: context === "user" ? "20px 0" : "",
        }}
      >
        <div
          className={context === "user" ? "user-nav-left" : "nav-left"}
          // style={{ width: fontSizeBreakPoint ? "25%" : "15%" }}
        >
          <img src={leftLogo} className="nav-logo" alt="redefine logo" />
        </div>
        <div className={context === "user" ? "user-nav-center" : "nav-center"}>
          {context === "user" && (
            <>
              <div className="contact_us" onClick={handleContactUs}>
                {/* <a href="mailto:dfff@ggg.com">sss</a> */}
                <MdOutlineAttachEmail />

                <span>Contact Us</span>
              </div>

              {!searchResultsInView && (
                <div
                  className={
                    userDesktopSearchPopup ? "search-icon open" : "search-icon"
                  }
                  onClick={() =>
                    dispatch(
                      handleSearchPopup({
                        searchPopupOpen: !userDesktopSearchPopup,
                      })
                    )
                  }
                >
                  <IconContext.Provider
                    value={{
                      size: "20px",

                      color: "#ffffff",
                      background: "#000000",
                    }}
                  >
                    <BsSearch />
                  </IconContext.Provider>
                </div>
              )}
            </>
          )}

          {context === "super-admin" && (
            <>
              <button
                onClick={() => setShowView("upload")}
                style={{
                  fontSize: " 0.8rem",
                }}
                className={showView === "upload" ? "active" : ""}
              >
                <IconContext.Provider
                  value={{
                    className: "global-class-name",
                    size: fontSizeBreakPoint ? "20px" : "15px",
                  }}
                >
                  <AiOutlineUpload />
                </IconContext.Provider>
                <span>Upload</span>
              </button>
            </>
          )}

          <button
            onClick={
              context === "user"
                ? () => setShowView("all-section")
                : () => setShowView("community")
            }
            className={
              showView === "all-section" || showView === "community"
                ? "active"
                : ""
            }
            style={{ fontSize: " 0.8rem" }}
          >
            <IconContext.Provider
              value={{
                className: "global-class-name",
                size: fontSizeBreakPoint ? "20px" : "15px",
              }}
            >
              {context === "user" ? <TiThList /> : <BiMessageDetail />}
            </IconContext.Provider>
            <span>{context === "user" ? "All sections" : "Community"}</span>
          </button>
          <button
            onClick={
              context === "user"
                ? () => setShowView("files")
                : () => setShowView("statistics")
            }
            style={{
              fontSize: " 0.8rem",
            }}
            className={
              showView === "files" || showView === "statistics" ? "active" : ""
            }
          >
            <IconContext.Provider
              value={{
                className: "global-class-name",
                size: fontSizeBreakPoint ? "20px" : "15px",
              }}
            >
              {context === "user" ? <RiDownloadFill /> : <AiOutlinePieChart />}
            </IconContext.Provider>
            <span>
              {context === "user" ? "Files and downloads" : "Statistics"}
            </span>
          </button>
          <button
            onClick={
              context === "user"
                ? () => setShowView("user-community")
                : () => setShowView("users")
            }
            style={{
              fontSize: " 0.8rem",
            }}
            className={
              showView === "user-community" || showView === "users"
                ? "active"
                : ""
            }
          >
            <IconContext.Provider
              value={{
                className: "global-class-name",
                size: fontSizeBreakPoint ? "20px" : "15px",
              }}
            >
              {context === "user" ? <BiMessageDetail /> : <BiUser />}
            </IconContext.Provider>
            <span> {context === "user" ? "Community" : "Users"} </span>
          </button>

          <div className="sign_out" onClick={handleSignOut}>
            <SlLogout size={fontSizeBreakPoint ? "20px" : "15px"} />

            <span>Sign Out</span>
          </div>
        </div>
        <div className={context === "user" ? "user-nav-right" : "nav-right"}>
          <img
            src={rightLogo}
            id="right-logo-navbar"
            className="nav-logo"
            alt="redefine logo"
          />
        </div>
      </nav>
    </>
  );
};

export default NavBar;
