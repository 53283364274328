import "../common/style/mobile-header.css";
import "../common/style/mobile-footer.css";
import "./style/index.css";
import { useMediaQuery } from "react-responsive";
import { useState, useEffect } from "react";
import MobileSideNav from "../common/mobile-header";
import NotificationsContainer from "../common/notificationsContainer";
import MobileFooter from "../common/mobile-footer";
import NavBar from "../common/navbar";
import Community from "../common/community";
import Statistics from "../common/statistics";
import BackButton from "../common/backButton";
import SearchBarMobile from "../../Components/common/searchBar-mobile";

import { useDispatch, useSelector } from "react-redux";
import { setAuthorization } from "../../redux/reducer";
import { SuperAdminUsers } from "./super-admin-users";
import InviteDistributor from "./invite-distributor";
import { useNavigate } from "react-router-dom";
import {
  setDistributorsAndUsers,
  setDistributorsList,
  setQuizzes,
  setSuperAdminRating,
} from "../../redux/superAdminReducer";
import services from "../../services/userService";
import InviteMemberForm from "../common/invite-member-form";
import {
  checkEditFormIsOpen,
  setMessages,
  setNotifications,
  setListOfAlreadyReadNotifications,
  setCurrentUserEmail,
} from "../../redux/reducer";
import messageService from "../../services/messageService";
import lessonService from "../../services/lessonsService";
import { setAllLessons } from "../../redux/userReducer";
import notificationsService from "../../services/notificationsService";
import Upload from "./upload";
import quizService from "../../services/quizService";
import {
  setSuperAdminStats,
  setAmountOfLessons,
  setAmountOfRegisteredUsers,
} from "../../redux/superAdminReducer";
import SearchResultsMobile from "../common/search-results-mobile";

const SuperAdminHomePage = ({
  setIsNotificationsPopupOpen,
  setIsEditFormOpen,
}) => {
  const desktopView = useMediaQuery({ query: "(min-width:740px)" });
  const isOpen = useSelector(
    (state) => state.allPermission.value.notificationsPopupIsOpen
  );
  const distributorsListForSelect = useSelector(
    (state) => state.superAdminSlice.value.distributorsList
  );
  const editFormOpen = useSelector(
    (state) => state.allPermission.value.editFormIsOpen
  );
  const authorization = useSelector(
    (state) => state.allPermission.value.authorization
  );
  const superAdminStats = useSelector(
    (state) => state.superAdminSlice.value.superAdminStats
  );
  const amountOfLessons = useSelector(
    (state) => state.superAdminSlice.value.amountOfLessons
  );
  const amountOfRegisteredUsers = useSelector(
    (state) => state.superAdminSlice.value.amountOfRegisteredUsers
  );
  const ratingRedux = useSelector(
    (state) => state.superAdminSlice.value.superAdminRating
  );

  const [showView, setShowView] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [inviteMemberFormOpenDesktop, setInviteMemberFormOpenDesktop] =
    useState(false);
  const [inviteDistributorFormOpen, setInviteDistributorFormOpen] =
    useState(false);
  const [memberArrow, setMemberArrow] = useState(false);
  const [distributorArrow, setDistributorArrow] = useState(false);
  const [distributorSelectOptions, setDistributorSelectOptions] = useState([]);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const notifications = await notificationsService.fetchNotifications(
          authorization
        );
        const quizzes = await quizService.fetchQuizzes();
        const lessons = await lessonService.fetchLessons();
        console.log(lessons, "lessons");
        //  fetch only distributors
        const distributors = await services.fetchAllDistributors();
        //  fetch distributors and associated users
        const data = await services.fetchDistributorsAndUsers();
        const messages = await messageService.fetchMessages();
        const fetchSuperAdminStats = await services.fetchSuperAdminStats();
        const fetchAllUsersAverage = await services.fetchAllUsersAverage();
        return {
          data,
          distributors,
          messages,
          notifications,
          lessons,
          quizzes,
          fetchSuperAdminStats,
          fetchAllUsersAverage,
        };
      } catch (err) {
        console.log(err, "err fetch distributors");
        throw err;
      }
    };

    const processDistributors = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        return navigate("/login");
      } else {
        // check if user is super admin
        const checkAuth = await services.checkAuth();
        if (checkAuth.message === "authorized" && checkAuth.auth === 3) {
          dispatch(setAuthorization({ authorization: "super-admin" }));
        } else {
          return navigate("/login");
        }
        try {
          if (authorization) {
            const {
              data,
              distributors,
              messages,
              notifications,
              lessons,
              quizzes,
              fetchSuperAdminStats,
              fetchAllUsersAverage,
            } = await fetchAllData();

            if (fetchAllUsersAverage.message === "success") {
              dispatch(
                setSuperAdminRating({
                  rating: fetchAllUsersAverage.rating,
                })
              );
            } else {
              console.log(fetchAllUsersAverage, "error while getting average");
            }

            if (fetchSuperAdminStats.message === "success") {
              dispatch(
                setSuperAdminStats({
                  stats: fetchSuperAdminStats.stats,
                })
              );
              dispatch(
                setAmountOfLessons({
                  amountOfLessons: fetchSuperAdminStats.amountOfLessons,
                })
              );
              dispatch(
                setAmountOfRegisteredUsers({
                  amountOfRegisteredUsers:
                    fetchSuperAdminStats.amountOfRegisteredUsers,
                })
              );
            } else {
              console.log(fetchSuperAdminStats, "error while getting stats");
            }
            if (quizzes.message === "success") {
              dispatch(setQuizzes({ quizzes: quizzes.quizzes }));
            } else {
              console.log(quizzes, "error while getting quizzes");
            }

            if (lessons.message === "success") {
              dispatch(setAllLessons({ lessons: lessons.lessons }));
            } else {
              console.log(lessons, "error while getting lessons");
            }
            if (notifications.message === "success") {
              dispatch(
                setListOfAlreadyReadNotifications({
                  listOfAlreadyReadNotifications:
                    notifications.userListOfReadNotifications,
                })
              );

              dispatch(
                setNotifications({ notifications: notifications.notifications })
              );
            } else {
              console.log(notifications, "error while getting notifications");
            }
            if (messages.message === "success") {
              dispatch(setMessages({ messages: messages.messages }));
              dispatch(
                setCurrentUserEmail({
                  currentUserEmail: messages.currentUserEmail,
                })
              );
            } else {
              console.log(messages, "error while getting messages");
            }
            if (data.message === "success") {
              dispatch(
                setDistributorsAndUsers({
                  distributorsAndAssociatedUsers: data.distributors,
                })
              );
            } else {
              console.log(data, "error while getting distributors");
            }

            if (distributors.message === "success") {
              dispatch(
                setDistributorsList({
                  distributorsList: distributors.distributors,
                })
              );
            } else {
              console.log(data, "error while getting distributors list");
            }
          }
        } catch (err) {
          console.log(err, "super admin error fetching data");

          throw err;
        }
      }
    };
    processDistributors();
    setDistributorSelectOptions(distributorsListForSelect);
    if (desktopView) {
      setShowView("community");
    } else {
      setShowView("mobile-community");
    }
  }, [authorization]);

  useEffect(() => {
    setDistributorSelectOptions(distributorsListForSelect);
  }, [distributorsListForSelect]);

  useEffect(() => {
    if (!desktopView) {
      if (showView === "community") {
        setShowView("mobile-start-screen");
      }
      if (showView === "users") {
        setShowView("mobile-users");
      }
      if (showView === "statistics") {
        setShowView("mobile-statistics");
      }
    } else {
      if (showView === "mobile-start-screen") {
        setShowView("community");
      }
      if (showView === "mobile-users") {
        setShowView("users");
      }
      if (showView === "mobile-statistics") {
        setShowView("statistics");
      }
    }
  }, [desktopView, showView]);

  useEffect(() => {
    setIsNotificationsPopupOpen(isOpen);
  }, [isOpen]);

  const handleInvitationForm = (context) => {
    if (context === "member") {
      setInviteMemberFormOpenDesktop(!inviteMemberFormOpenDesktop);
      setMemberArrow(!memberArrow);
    } else {
      setInviteDistributorFormOpen(!inviteDistributorFormOpen);
      setDistributorArrow(!distributorArrow);
    }
  };

  return (
    <>
      {/* DESKTOP VIEW */}

      {desktopView ? (
        <>
          <main className="super-admin-home-page">
            <NavBar
              setShowView={setShowView}
              context="super-admin"
              showView={showView}
            />
            <div className="main-content-container">
              {showView !== "upload" && <NotificationsContainer />}

              <div
                className={showView === "statistics" ? "board-black" : "board"}
                style={{
                  width: showView !== "upload" ? "75%" : "100%",
                  margin: showView === "upload" ? "0 auto" : "",
                  height: showView === "upload" ? "65vh" : "75vh",
                }}
              >
                {showView === "community" ? (
                  <Community />
                ) : showView === "statistics" ? (
                  <Statistics
                    rating={ratingRedux}
                    adminStats={superAdminStats}
                    amountOfLessons={amountOfLessons}
                    amountOfRegisteredUsers={amountOfRegisteredUsers}
                  />
                ) : showView === "users" ? (
                  <SuperAdminUsers
                    showView={showView}
                    handleInvitationForm={handleInvitationForm}
                    setShowView={setShowView}
                    setIsEditFormOpen={setIsEditFormOpen}
                    inviteDistributorFormOpen={inviteDistributorFormOpen}
                    memberArrow={memberArrow}
                    distributorArrow={distributorArrow}
                    inviteMemberFormOpenDesktop={inviteMemberFormOpenDesktop}
                    // showMembersForm={showMembersForm}
                    distributorSelectOptions={distributorSelectOptions}
                    setDistributorSelectOptions={setDistributorSelectOptions}
                    // setIsNotificationsPopupOpen={setIsNotificationsPopupOpen}
                  />
                ) : (
                  // else means showView === "upload"
                  <>
                    <Upload />
                  </>
                )}
              </div>
            </div>
          </main>
        </>
      ) : (
        <>
          {/* MOBILE VIEW */}
          <MobileSideNav setShowView={setShowView} />
          {/* start screen   */}
          {showView === "mobile-start-screen" && (
            <>
              <main className="super-admin-container mobile-community-top">
                {showView === "mobile-start-screen" && <></>}
                <section className="super-main-notifications">
                  <NotificationsContainer />
                </section>
                <section className="super-main-form-wrapper">
                  <h3>Create a new distributor</h3>
                  <div className="invit-dist-mobile">
                    <InviteDistributor />
                  </div>
                </section>
              </main>
              <section className="super-main-member-form">
                <h3
                  className="super-bottom-title"
                  onClick={() =>
                    dispatch(
                      checkEditFormIsOpen({ editFormIsOpen: !editFormOpen })
                    )
                  }
                  // onClick={() => setShowMembersForm(!showMembersForm)}
                >
                  Invite a new member
                </h3>
                <InviteMemberForm
                  context="super"
                  distributorSelectOptions={distributorSelectOptions}
                  inviteMemberFormOpenDesktop={inviteMemberFormOpenDesktop}
                  // showMembersForm={showMembersForm}
                />
              </section>
            </>
          )}

          {/* community view   */}

          {showView === "mobile-community" && (
            <>
              <div className="admin-home-page-mobile-community mobile-community-top">
                <BackButton
                  context="super-admin"
                  setShowView={setShowView}
                  title="Community Messages"
                />
                <div className="search">
                  <SearchBarMobile
                    setShowView={setShowView}
                    context={authorization}
                    searchContext="messages"
                  />
                </div>
                <Community />
              </div>
            </>
          )}

          {/* mobile search results view */}
          {showView === "mobile-search-results" && (
            <>
              <SearchResultsMobile setShowView={setShowView} />
            </>
          )}

          {/* statistics view   */}
          {showView === "mobile-statistics" && (
            <>
              <BackButton
                setShowView={setShowView}
                title="Statistics"
                context="super-admin"
              />
              <Statistics
                rating={ratingRedux}
                adminStats={superAdminStats}
                amountOfLessons={amountOfLessons}
                amountOfRegisteredUsers={amountOfRegisteredUsers}
              />
            </>
          )}

          {/* users view   */}

          {showView === "mobile-users" && (
            <SuperAdminUsers
              showView={showView}
              distributorSelectOptions={distributorSelectOptions}
              setDistributorSelectOptions={setDistributorSelectOptions}
              setShowView={setShowView}
              handleInvitationForm={handleInvitationForm}
              setIsEditFormOpen={setIsEditFormOpen}
              inviteDistributorFormOpen={inviteDistributorFormOpen}
              memberArrow={memberArrow}
              distributorArrow={distributorArrow}
              inviteMemberFormOpenDesktop={inviteMemberFormOpenDesktop}
            />
          )}
          <footer
            className={
              showView === "mobile-start-screen" ||
              showView === "mobile-community"
                ? "super-admin-footer"
                : "super-admin-footer-more-space"
            }
          >
            <MobileFooter />
          </footer>
        </>
      )}
    </>
  );
};

export default SuperAdminHomePage;
