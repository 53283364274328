import "./style/community-messages.css";
import { useMediaQuery } from "react-responsive";
import { useState, useEffect } from "react";
import Message from "./message";
import { useSelector } from "react-redux";
import { v4 as uuid4 } from "uuid";
// import { AiOutlineConsoleSql } from "react-icons/ai";

const CommunityMessages = () => {
  const desktopView = useMediaQuery({ query: "(min-width:740px)" });
  const device = desktopView ? "desktop" : "mobile";
  const [archiveSectionOpen, setArchiveSectionOpen] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [archiveMessages, setArchiveMessages] = useState([]);

  const searchedMessageId = useSelector(
    (state) => state.allPermission.value.searchedMessageId
  );
  const isPopupOpen = useSelector(
    (state) => state.superAdminSlice.value.messagePopupOpen
  );
  const messages = useSelector((state) => state.allPermission.value.messages);

  useEffect(() => {
    setUnreadMessages([]);
    setArchiveMessages([]);
    if (messages?.length) {
      const sortedMessages = [...messages].sort((a, b) => {
        if (a.pinned && !b.pinned) {
          return -1;
        }
        if (!a.pinned && b.pinned) {
          return 1;
        }

        // Sort by 'created_at' date (latest date first)
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB.getTime() - dateA.getTime();
      });

      sortedMessages.forEach((message) => {
        const currentDate = new Date();
        const givenDate = new Date(message.created_at);

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = currentDate - givenDate;

        // Convert the difference in milliseconds to days
        const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);

        if (differenceInDays > 30) {
          setArchiveMessages((prevArchiveMessages) => [
            ...prevArchiveMessages,
            message,
          ]);
        } else {
          setUnreadMessages((prevUnreadMessages) => [
            ...prevUnreadMessages,
            message,
          ]);
        }
      });
      // }
    }
  }, [messages, searchedMessageId]);

  return (
    <>
      <div
        className="pinned"
        style={{
          maxHeight: archiveSectionOpen ? "220px" : "300px",
        }}
      >
        {unreadMessages.length > 0 && (
          <>
            {unreadMessages.map((item) => {
              return (
                <Message item={item} key={uuid4()} customClassName="pinned" />
              );
            })}
          </>
        )}
      </div>
      <div className="separator">
        {device === "desktop" && (
          <span onClick={() => setArchiveSectionOpen(!archiveSectionOpen)}>
            Archive
          </span>
        )}
      </div>
      <div
        className={
          isPopupOpen ? "community_bottom dark-mode" : "community_bottom"
        }
      >
        <div className="unpinned">
          {device === "mobile" && (
            <h3 onClick={() => setArchiveSectionOpen(!archiveSectionOpen)}>
              Archive:
            </h3>
          )}
          {archiveSectionOpen && (
            <>
              {archiveMessages.length > 0 ? (
                <>
                  {archiveMessages.map((item) => {
                    return (
                      <Message
                        item={item}
                        key={uuid4()}
                        customClassName="unpinned"
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  <div className="no-data"> No Messages In Archive</div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CommunityMessages;
