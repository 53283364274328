import UserMobileNavbar from "./user-sidenav-mobile";
import "./style/index.css";
import { useMediaQuery } from "react-responsive";
import UserMobileFooter from "./user-mobile-footer";
import { useState, useEffect } from "react";
import FilesForDownload from "./user-files-downloads";
import NavBar from "../common/navbar";
import { useDispatch, useSelector } from "react-redux";
import { setAuthorization, setMessages } from "../../redux/reducer";
import { setUserName, setLessonProgressAverage } from "../../redux/userReducer";
// import ReactPlayer from "react-player/youtube";
import UserCommunity from "./user-community";
import AllSections from "./user-all-sections";
import VideoIntroMobile from "./user-video-intro-mobile";
import UserMobileStartScreen from "./user-mobile-start-screen";
// import AllSectionMobile from "./user-all-sections-mobile";
import Community from "../common/community";
import BackButton from "../common/backButton";
import SearchBarMobile from "../common/searchBar-mobile";
import { useNavigate } from "react-router-dom";
import lessonsService from "../../services/lessonsService";
import messageService from "../../services/messageService";
import filesService from "../../services/fileService";
import userService from "../../services/userService";
import SearchDesktop from "./user-search-desktop";
import SearchResults from "./user-search-results-desktop";
import Quiz from "./quiz";
import {
  setFiles,
  setAllLessons,
  setUserStatistics,
} from "../../redux/userReducer";

import RateLesson from "./rate-lesson";
import Feedback from "./feedback";
// import quizService from "../../services/quizService";
// import notificationsService from "../../services/notificationsService";
import LessonFinished from "./lesson-finished";
import VideoPlayer from "./video-player";
import SearchResultsMobile from "../common/search-results-mobile";
const UsersHomePage = () => {
  const desktopView = useMediaQuery({ query: "(min-width:750px)" });
  const [showView, setShowView] = useState("");
  const navigate = useNavigate();
  const userViewSearchPopupIsOpen = useSelector(
    (state) => state.user.value.searchPopupOpen
  );
  const [sectionsAndLessons, setSectionsAndLessons] = useState([]);
  const lessonsFromRedux = useSelector((state) => state.user.value.lessons);
  // const messagesRedux = useSelector(
  //   (state) => state.allPermission.value.messages
  // );
  const userStatsRedux = useSelector(
    (state) => state.user.value.userStatistics
  );
  const authorization = useSelector(
    (state) => state.allPermission.value.authorization
  );

  const dispatch = useDispatch();
  useEffect(() => {
    const processData = async () => {
      // const data = await services.fetchLessons()

      const token = localStorage.getItem("token");
      if (!token) return navigate("/login");

      dispatch(setAuthorization({ authorization: "user" }));

      const files = await filesService.fetchUserFiles();
      const lessons = await lessonsService.fetchLessons();
      const messages = await messageService.fetchMessages();
      // const notifications = await notificationsService.fetchNotifications(
      //   authorization
      // );
      const fetchUserStats = await userService.fetchUserStats();
      if (fetchUserStats.message === "success") {
        dispatch(setUserStatistics({ stats: fetchUserStats.stats }));
      } else {
        console.log(fetchUserStats, "fetchUserStats error");
      }

      if (files.message === "success") {
        dispatch(setFiles({ files: files.files }));
      }
      if (lessons.message === "success") {
        dispatch(setAllLessons({ lessons: lessons.lessons }));
      } else {
        console.log(lessons.message, "res message fetch lessons");
      }

      if (messages.message === "success") {
        dispatch(setUserName({ userName: messages.firstName }));
        dispatch(setMessages({ messages: messages.messages }));
      } else {
        console.log(messages, "res message fetch messages");
      }

      if (desktopView) {
        setShowView("all-section");
        return;
      }
      setShowView("user-mobile-start");
    };
    processData();
  }, []);

  useEffect(() => {
    if (!lessonsFromRedux.length || !userStatsRedux.length) return;

    const sectionsWithCompletion = lessonsFromRedux.map((section) => {
      const matchingStats = userStatsRedux.find(
        (stat) => stat.sectionId === section.section_id
      );
      if (matchingStats) {
        const completion = Math.floor(
          (matchingStats.amountOfEndedLessons / matchingStats.amountOfLessons) *
            100
        );

        return { ...section, completion }; // Create a new object with the completion property
      }
      return section; // If no matching stat, return the original section object
    });
    setSectionsAndLessons(sectionsWithCompletion);
  }, [lessonsFromRedux, userStatsRedux]);

  useEffect(() => {
    if (sectionsAndLessons) {
      const totalSections = userStatsRedux.length;
      let totalProgress = 0;

      sectionsAndLessons.forEach((section) => {
        totalProgress += section.completion;
      });

      const averageProgress = Math.floor(totalProgress / totalSections);
      dispatch(setLessonProgressAverage(averageProgress));
    }
  }, [sectionsAndLessons]);
  useEffect(() => {
    if (
      showView === "all-section" ||
      showView === "user-mobile-start" ||
      showView === "search-results" ||
      showView === "mobile-search-results"
    ) {
      desktopView
        ? setShowView("all-section")
        : setShowView("user-mobile-start");
    }

    if (showView === "files" || showView === "user-mobile-files-downloads") {
      desktopView
        ? setShowView("files")
        : setShowView("user-mobile-files-downloads");
    }
    if (showView === "user-community" || showView === "user-mobile-community") {
      desktopView
        ? setShowView("user-community")
        : setShowView("user-mobile-community");
    }
    if (showView === "user-mobile-video-intro") {
      desktopView
        ? setShowView("all-section")
        : setShowView("user-mobile-video-intro");
    }
  }, [desktopView]);

  return (
    <>
      {/* desktop view */}

      {desktopView ? (
        <>
          {showView === "video-player" ? (
            <>
              <VideoPlayer
                setShowView={setShowView}
                desktopView={desktopView}
              />
            </>
          ) : (
            <>
              <NavBar
                context="user"
                setShowView={setShowView}
                showView={showView}
              />

              {showView === "quiz" && (
                <>
                  <div className="desktop-quiz">
                    <Quiz setShowView={setShowView} view="desktop" />
                  </div>
                </>
              )}

              {showView === "files" && (
                <>
                  <div
                    className={
                      userViewSearchPopupIsOpen
                        ? "desktop-files-wrapper dark"
                        : "desktop-files-wrapper"
                    }
                  >
                    <FilesForDownload setShowView={setShowView} />
                  </div>
                </>
              )}

              {showView === "user-community" && (
                <>
                  <div
                    className={
                      userViewSearchPopupIsOpen
                        ? "user-community-wrapper dark"
                        : "user-community-wrapper"
                    }
                  >
                    <UserCommunity />
                  </div>
                </>
              )}
              {showView === "all-section" && (
                <AllSections
                  lessons={sectionsAndLessons}
                  setShowView={setShowView}
                />
              )}

              {userViewSearchPopupIsOpen && (
                // desktop search popup
                <>
                  <SearchDesktop setShowView={setShowView} />
                </>
              )}
              {showView === "search-results" && (
                // search results view
                <>
                  <SearchResults setShowView={setShowView} />
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {/* mobile view */}

          {showView === "video-player" ? (
            <VideoPlayer setShowView={setShowView} desktopView={desktopView} />
          ) : (
            <>
              {showView !== "user-mobile-video-intro" && (
                // show mobile navbar
                <UserMobileNavbar setShowView={setShowView} />
              )}

              {showView === "user-mobile-start" && (
                <UserMobileStartScreen setShowView={setShowView} />
              )}
              {showView === "user-mobile-community" && (
                <>
                  <BackButton
                    context="user"
                    setShowView={setShowView}
                    title="Community Messages"
                  />
                  <SearchBarMobile
                    setShowView={setShowView}
                    context={authorization}
                    searchContext="messages"
                  />
                  <div className="user-community-mobile">
                    <Community />
                  </div>
                </>
              )}

              {/* mobile search results view */}
              {showView === "mobile-search-results" && (
                <>
                  <SearchResultsMobile setShowView={setShowView} />
                </>
              )}

              {showView === "user-mobile-files-downloads" && (
                <FilesForDownload setShowView={setShowView} />
              )}
              {showView === "user-mobile-video-intro" && (
                <VideoIntroMobile setShowView={setShowView} />
              )}

              {showView === "all-section" && (
                <AllSections
                  lessons={sectionsAndLessons}
                  setShowView={setShowView}
                />
              )}

              {showView === "quiz" && (
                <>
                  <div className="mobile-quiz">
                    <Quiz setShowView={setShowView} view="mobile" />
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
      {/* desktop and mobile */}

      {showView === "rate-lesson" && (
        <>
          <RateLesson setShowView={setShowView} desktopView={desktopView} />
        </>
      )}
      {showView === "feedback" && (
        <>
          <Feedback setShowView={setShowView} />
        </>
      )}
      {showView === "lesson-finished" && (
        <>
          <LessonFinished setShowView={setShowView} />
        </>
      )}
    </>
  );
};

export default UsersHomePage;
