import { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useMediaQuery } from "react-responsive";
import { IconContext } from "react-icons";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import services from "../../services/userService";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useSelector, useDispatch } from "react-redux";
import "./style/admin-users.css";
import InviteMemberForm from "../common/invite-member-form";
import MyAccordionItem from "./accordion-item";
import { checkEditFormIsOpen } from "../../redux/reducer";
import { v4 as uuid4 } from "uuid";

const AdminUsers = () => {
  const desktopView = useMediaQuery({ query: "(min-width:740px)" });
  const [inviteFormArrow, setInviteFormArrow] = useState(false);
  const [usersFromDb, setUsersFromDb] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);

  const distributorsListForSelect = useSelector(
    (state) => state.superAdminSlice.value.distributorsList
  );

  const editFormOpen = useSelector(
    (state) => state.allPermission.value.editFormIsOpen
  );

  const registeredUsersRedux = useSelector(
    (state) => state.adminReducer.value.users
  );
  const pendingUsersRedux = useSelector(
    (state) => state.adminReducer.value.pendingUsers
  );

  const dispatch = useDispatch();

  const handleInviteForm = () => {
    setInviteFormArrow(!inviteFormArrow);
    dispatch(checkEditFormIsOpen({ editFormIsOpen: !editFormOpen }));
  };

  useEffect(() => {
    setSelectOptions(distributorsListForSelect);
    if (registeredUsersRedux?.length) {
      setUsersFromDb(registeredUsersRedux);
    }
    if (pendingUsersRedux?.length) {
      const updatedDates = pendingUsersRedux.map((user) => {
        const dateStr = user.invitation_date;
        const date = new Date(dateStr);
        const localTimeString = date.toLocaleString();

        // Create a new object with updated 'invitation_date' property
        const updatedUser = { ...user };
        updatedUser.invitation_date = localTimeString;

        return updatedUser;
      });

      setPendingUsers(updatedDates);
    }
  }, [registeredUsersRedux, pendingUsersRedux, distributorsListForSelect]);

  return (
    <>
      <div className="admin-users">
        {desktopView && (
          <>
            <h3>Users:</h3>
            <div
              onClick={handleInviteForm}
              className="invite"
              style={{
                borderRadius: editFormOpen ? "12px 12px 0 0" : "12px",
                marginBottom: editFormOpen ? "0px" : "20px",
              }}
            >
              <div>
                <button
                  onClick={() =>
                    dispatch(
                      checkEditFormIsOpen({ editFormIsOpen: !editFormOpen })
                    )
                  }
                >
                  Invite New Member:
                </button>
                <IconContext.Provider
                  value={{
                    color: "#ffffff",
                    size: "1.3rem",
                  }}
                >
                  {inviteFormArrow ? <IoIosArrowDown /> : <IoIosArrowUp />}
                </IconContext.Provider>
              </div>
            </div>
          </>
        )}

        {editFormOpen && (
          <InviteMemberForm
            // viewForm={viewForm}
            distributorSelectOptions={selectOptions}
            context="admin"
          />
        )}

        <div
          className={
            desktopView ? "accordion-wrapper" : "mobile-accordion-wrapper"
          }
        >
          <div className="title">
            <h4>Name:</h4>
            <h4>Last Seen:</h4>
          </div>
          <div className="admin-accordion">
            {/* check length  */}
            {usersFromDb.length ? (
              usersFromDb.map((item) => {
                return (
                  <MyAccordionItem
                    key={item.id}
                    item={item}
                    errorMessage={errorMessage}
                  />
                );
              })
            ) : (
              <>
                <div className="no-users">{errorMessage} </div>
              </>
            )}

            {pendingUsers.length > 0 && (
              <>
                <div
                  className="pending-users-wrapper"
                  style={{
                    backgroundColor: desktopView ? "#000000" : "#cb2a27",
                  }}
                >
                  <div className="header">
                    <h3>Pending Users:</h3>
                    <h3 className="invitation">Invited At</h3>
                  </div>
                  {pendingUsers.map((user) => {
                    return (
                      <>
                        <div className="pending-user">
                          <span className="email">{user.email}</span>
                          <span className="invitation">
                            {user.invitation_date}
                          </span>
                        </div>
                      </>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminUsers;
