import "./style/forgot-password.css";
import Logos from "./logos";
import { useEffect, useState } from "react";
import { emailRegex } from "../../utils/regex";
import { useNavigate } from "react-router-dom";
import resetPasswordService from "../../services/reset-password";
const HandleForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  useEffect(() => {}, []);
  const handleEmail = async (e) => {
    e.preventDefault();
    e.target.disabled = true;
    const emailMatch = email.match(emailRegex);
    if (email === "" || !emailMatch) {
      e.target.disabled = false;
      return setErrorMessage("Enter A Valid Email");
    }

    try {
      const response = await resetPasswordService.checkUserEmail(email);
      if (response.message === "success") {
        setErrorMessage("Check Your Inbox For Reset Link");
      } else {
        setErrorMessage("Email Not Found");
      }
    } catch (err) {
      console.log(err, "err forgot password");
    }
  };
  return (
    <>
      <Logos />
      <h1 className="forgot-pass-title">Forgot Password ?</h1>
      <div className="forgot-password-container">
        <form className="forgot-password-form">
          <div className="form-control">
            <input
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrorMessage("");
              }}
              placeholder="Enter Email..."
            />
            <div className="back-to-login" onClick={() => navigate("/login")}>
              {`Login >>`}
            </div>
          </div>
          <div className="error">{errorMessage}</div>
          <div className="form-submission">
            <button onClick={(e) => handleEmail(e)}>Submit</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default HandleForgotPassword;
