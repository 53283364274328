import { useState, useEffect, useRef } from "react";
import axiosBaseUrl from "../../utils/axios";
import { emailRegex } from "../../utils/regex";
import { useNavigate } from "react-router-dom";
import "./style/invite-member-form.css";
import { useSelector, useDispatch } from "react-redux";
import services from "../../services/userService";
import Select from "react-select";
import { toast } from "react-toastify";
import { setPendingUsers, setUsersFromDb } from "../../redux/adminReducer";
import { useMediaQuery } from "react-responsive";
import { v4 as uuid4 } from "uuid";
/* for desktop */
const InviteMemberForm = ({
  inviteFormOpen,
  context,
  distributorSelectOptions,
  // showMembersForm,
  // viewForm,
}) => {
  const [email, setEmail] = useState("");
  const [messageForUser, setMessageForUser] = useState("");
  const dispatch = useDispatch();
  const [role, setRole] = useState(" "); // the space is for disabled option for select
  const [roleError, setRoleError] = useState("");
  const [distributor, setDistributor] = useState(" "); // the space is for disabled option for select
  const [selectError, setSelectError] = useState("");
  const desktopView = useMediaQuery({ query: "(min-width:740px)" });
  const editFormOpen = useSelector(
    (state) => state.allPermission.value.editFormIsOpen
  );
  const navigate = useNavigate();

  const SendMemberInvite = async (e) => {
    e.preventDefault();

    if (email === "") {
      setMessageForUser("Enter Email");
      return;
    } else {
      setMessageForUser("");
    }

    const emailMatch = email.match(emailRegex);

    if (!emailMatch) {
      setMessageForUser("Invalid Email");
      return;
    } else {
      setMessageForUser("");
    }

    if (!role || role === " ") {
      setRoleError("Select A Role");
      return;
    } else {
      setRoleError("");
    }

    let distributorId;
    if (context === "super") {
      if (!distributor || distributor === " ") {
        setSelectError("Select A Distributor");
        return;
      } else {
        setSelectError("");
      }

      distributorId = Number(distributor);
    }
    try {
      let data;
      const userRole = Number(role);

      if (distributorId && context === "super") {
        // invite sent by redefine

        data = await services.inviteMember(email, userRole, distributorId);
      } else {
        // invite sent by distributor
        data = await services.inviteMember(email, userRole);
      }

      if (
        data.message === "No Token Provided" ||
        data.message === "Invalid Token"
      ) {
        navigate("/login");
        return;
      }
      toast.success(data.message);
      if (data.registeredUsers !== null) {
        dispatch(setUsersFromDb(data.registeredUsers));
      }
      if (data.pendingUsers !== null) {
        dispatch(setPendingUsers(data.pendingUsers));
      }
      setEmail("");
      setRole(" ");
      /* redux */
      // setSelectError(data.message);
    } catch (err) {
      console.log(err, "err in invite member form");
      toast.error(err);
    }
  };
  return (
    <form
      className={
        desktopView
          ? "invite-member-form"
          : editFormOpen
          ? "super-invite-member"
          : context === "admin"
          ? "invite-member-admin-mobile"
          : "d-none"
      }
      style={{
        borderRadius: inviteFormOpen ? "0 0 12px 12px" : "0 0 12px 12px",
      }}
      id="invite-member-form"
    >
      <div className="form-wrapper">
        <input
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          name="invite-member-email"
          id="invite-member-email"
          className={desktopView ? "invite-member-email" : "invite-user"}
          placeholder="Email:"
          value={email}
        />
        <span
          className="error"
          style={{ color: desktopView ? "#c62622" : "#000000" }}
        >
          {messageForUser}
        </span>
      </div>

      <div className="form-wrapper">
        <select
          value={role}
          className={desktopView ? "invite-member-email" : "invite-user"}
          onChange={(e) => setRole(e.target.value)}
        >
          <option value=" " disabled>
            Role
          </option>
          <option value="1">User</option>
          <option value="2">Admin</option>
        </select>
        <span className="error">{roleError}</span>
      </div>
      {context === "super" && (
        <>
          <div className="form-wrapper">
            <select
              value={distributor}
              onChange={(e) => setDistributor(e.target.value)}
              className={desktopView ? "invite-member-email" : "invite-user"}
            >
              <option value=" " disabled>
                Distributor
              </option>

              {distributorSelectOptions?.map((option) => {
                return (
                  <option
                    key={uuid4()}
                    className="distributor-options"
                    value={option.id}
                  >
                    {option.distributor_name}
                  </option>
                );
              })}
            </select>
            <span className="error">{selectError}</span>
          </div>
        </>
      )}

      <div className={desktopView ? "form-wrapper" : "form-btn"}>
        <input
          type="submit"
          onClick={(e) => SendMemberInvite(e)}
          className="submit-btn"
          value="Send Invitation"
        />
      </div>
    </form>
  );
};

export default InviteMemberForm;
