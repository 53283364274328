import BackButton from "../common/backButton";
import "./style/user-file-downloads.css";
import { useMediaQuery } from "react-responsive";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CombinedDownloader from "./user-download";
import { v4 as uuid4 } from "uuid";
import SearchBarMobile from "../common/searchBar-mobile";

const FilesForDownload = ({ setShowView }) => {
  const desktopView = useMediaQuery({ query: "(min-width:750px)" });

  const [files, setFiles] = useState([]);
  const authorization = useSelector(
    (state) => state.allPermission.value.authorization
  );

  const filesFromRedux = useSelector((state) => state.user.value.files);

  useEffect(() => {
    if (filesFromRedux?.length) {
      setFiles(filesFromRedux);
    }
  }, [filesFromRedux]);

  return (
    <>
      <div className="user">
        {desktopView ? (
          <>
            <h3 className="user-desktop-title">Attached Files</h3>
          </>
        ) : (
          <>
            <BackButton
              title="Attached files"
              setShowView={setShowView}
              context="user"
            />

            <div style={{ marginBottom: "30px" }}>
              <SearchBarMobile
                context={authorization}
                setShowView={setShowView}
                searchContext="files"
              />
            </div>
          </>
        )}

        <div
          className={
            desktopView
              ? "desktop-downloads-wrapper"
              : "mobile-downloads-wrapper"
          }
        >
          {files.length > 0 && (
            <>
              {files.map((lessonDetails) => {
                return (
                  <>
                    <CombinedDownloader
                      lessonDetails={lessonDetails}
                      key={lessonDetails.id}
                    />
                  </>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FilesForDownload;
