import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useState, useEffect, useRef } from "react";
import "./style/edit-user-and-distributor-form.css";
import { IconContext } from "react-icons";
import { GrClose } from "react-icons/gr";
import { checkEditFormIsOpen } from "../../redux/reducer";
import { setDistributorsAndUsers } from "../../redux/superAdminReducer";
import Select from "react-select";
import services from "../../services/userService";
import { toast } from "react-toastify";
import axiosBase from "../../utils/axios";
import { phoneNoRegex } from "../../utils/regex";

const options = [
  { value: "russian", label: "Russian", id: 1 },
  { value: "english", label: "English", id: 2 },
  { value: "hebrew", label: "Hebrew", id: 3 },
  { value: "arabic", label: "Arabic", id: 4 },
];

const EditForm = ({ setIsEditFormOpen }) => {
  const userDetails = useSelector(
    (state) => state.allPermission.value.editForm
  );
  const formContext = useSelector(
    (state) => state.allPermission.value.editForm.formType
  );
  const authorization = useSelector(
    (state) => state.allPermission.value.authorization
  );
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [fullName, setFullName] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneField, setPhoneField] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const { email, language, name, phone, formTitle } = userDetails;
  const checkboxRef = useRef();
  const checkboxWrapperRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedLanguage(language);
    setPhoneField(phone);
    setFullName(name);
    setUserEmail(email);

    if (name.includes("null")) {
      let updatedName = name.replace("null", "");
      updatedName = updatedName.trim();
      setFullName(updatedName);
    }
  }, [userDetails]);
  const handleClose = () => {
    setIsEditFormOpen(false);
    dispatch(checkEditFormIsOpen({ editFormIsOpen: false }));
  };

  const handleForm = async (e, context) => {
    e.preventDefault();
    setErrorMessage("");
    setPhoneError("");
    setNameError("");
    const checkbox = checkboxRef.current;
    const wrapper = checkboxWrapperRef.current;
    if (context === "delete") {
      // if delete
      if (formContext === "Distributor:") {
        //delete  distributor

        if (companyName === "") {
          // if no value return error message
          setCompanyNameError("Must Enter Company Name");
          return;
        }

        // if(companyName ===)
        if (companyName !== userDetails.companyName) {
          setCompanyNameError("Name Must Match Distributor");
          return;
        }

        if (wrapper.classList.contains("d-none")) {
          // true = show the checkbox verify deletion
          wrapper.classList.remove("d-none");
          setCompanyNameError("");
          return;
        }

        if (checkbox.checked) {
          // send request to delete distributor and associated users
          const response = await services.deleteDistributor(companyName);
          if (response.message === "success") {
            toast.success("Distributor and associated users deleted");
            handleClose();

            dispatch(
              setDistributorsAndUsers({
                distributorsAndAssociatedUsers: response.distributors,
              })
            );
            return;
          } else {
            setCompanyNameError(response.message);
            return;
          }
        } else {
          // didn't check the checkbox
          setErrorMessage("Must confirm deletion");
          return;
        }
      } else {
        //delete  user

        if (fullName === "") {
          setNameError("Name Can't be empty");
          return;
        }
        const phoneMatch = phone.match(phoneNoRegex);

        if (phoneField === "" || !phoneMatch) {
          setPhoneError("Enter A Valid Phone");
          return;
        }

        if (wrapper.classList.contains("d-none")) {
          wrapper.classList.remove("d-none");
          return;
        }

        if (checkbox.checked) {
          try {
            const response = await services.deleteMember(email);

            if (response.message === "success") {
              handleClose();
              toast.success("User deleted");
              dispatch(
                setDistributorsAndUsers({
                  distributorsAndAssociatedUsers: response.distributors,
                })
              );
            }
          } catch (err) {
            console.log(err, "error deleting member");
            return err;
          }
        } else {
          setErrorMessage("Must confirm deletion");
          return;
        }
      }
    } else if (context === "save") {
      if (formContext === "Distributor:") {
        //   update distributor

        if (companyName === "") {
          setCompanyNameError("Field cant be empty");
          return;
        }
        if (companyName.length < 2) {
          setCompanyNameError("Enter Valid Name ");
          return;
        }
        if (companyName === userDetails.companyName) {
          setCompanyNameError("No Changes Made");
          return;
        }

        try {
          const response = await services.updateDistributor(
            userDetails.companyName,
            companyName
          );
          if (response.message === "success") {
            dispatch(
              setDistributorsAndUsers({
                distributorsAndAssociatedUsers: response.distributors,
              })
            );
            toast.success("Distributor Updated");

            handleClose();
          } else {
            setCompanyNameError(response.message);
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        //   update user

        if (fullName === "") {
          setNameError("Name cant be empty");
          return;
        }
        if (phoneField === "") {
          phoneError("Phone cant be empty");
          return;
        }
        if (
          fullName === name &&
          phoneField === phone &&
          selectedLanguage === language
        ) {
          setErrorMessage("No Changes Were Made");
          return;
        }
        // edit user form
        // send request
        try {
          const response = await services.updateMember(
            fullName,
            email,
            phoneField,
            selectedLanguage,
            authorization
          );
          if (response.message === "success") {
            dispatch(
              setDistributorsAndUsers({
                distributorsAndAssociatedUsers: response.distributors,
              })
            );
            handleClose();
            toast.success("User Updated");
          } else {
            console.log(response, "error updating member");
          }
        } catch (err) {
          console.log(err, "errrr");
        }
      }
    }
  };

  return (
    <>
      <div className="users-distributor-popup">
        <div className="close" onClick={() => handleClose()}>
          <IconContext.Provider
            value={{
              color: "#000000",
              size: "1.5rem",
            }}
          >
            <GrClose />
          </IconContext.Provider>
        </div>
        <h3>
          <span> {`Edit ${formContext ? formContext : "User"}`}</span>
        </h3>
        <form className="edit-form ">
          {formContext === "Distributor:" ? (
            <>
              <div className="form-wrapper">
                <input
                  type="text"
                  id="company-name"
                  placeholder={userDetails.companyName}
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
                <span className="error" style={{ textAlign: "center" }}>
                  {companyNameError}
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="form-wrapper">
                <input
                  type="text"
                  className=""
                  name=""
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
                <span className="error">{nameError}</span>
              </div>
              <div className="form-wrapper">
                <input
                  type="text"
                  className=""
                  name=""
                  disabled
                  defaultValue={userEmail}
                />
              </div>

              <div className="form-wrapper">
                <input
                  type="number"
                  className=""
                  name=""
                  onChange={(e) => setPhoneField(e.target.value)}
                  value={phoneField}
                />
                <span className="error">{phoneError}</span>
              </div>

              <div className="form-wrapper-select">
                <Select
                  placeholder={selectedLanguage}
                  options={options}
                  onChange={(e) => setSelectedLanguage(e.label)}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: "rgba(242, 242, 242, 1)",
                      paddingTop: "15px",
                      color: "#ffffff",
                      borderBottom: "2px solid #000000",
                      borderRight: "none",
                      borderRadius: "12px 12px 0 0",
                      fontSize: "1rem",
                      height: "60px",
                      transition: "none",
                    }),
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      color: "rgba(121, 121, 121, 1)",
                      fontSize: "1rem",
                    }),
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      color: "rgba(121, 121, 121, 1)",
                    }),
                    indicatorsContainer: (baseStyles, state) => ({
                      ...baseStyles,
                      position: "relative",
                      bottom: "10px",
                      color: "rgba(121, 121, 121, 1)",
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                      ...baseStyles,
                      color: "rgba(121, 121, 121, 1)",
                    }),
                    valueContainer: (baseStyles, state) => ({
                      ...baseStyles,
                      position: "relative",
                      bottom: "10px",
                    }),
                    indicatorSeparator: (baseStyles, state) => ({
                      ...baseStyles,
                      display: "none",
                    }),
                    container: (baseStyles, state) => ({
                      ...baseStyles,
                      flex: 1,
                    }),
                  }}
                />
                <span className="error">{errorMessage}</span>
              </div>
            </>
          )}
          <div className="form-wrapper d-none" ref={checkboxWrapperRef}>
            <label htmlFor="confirm-label">
              {`are u sure you want to delete the ${
                formContext ? "distributor" : "user"
              } ?`}
              <input
                type="checkbox"
                className="confirm-checkbox"
                id="confirm"
                ref={checkboxRef}
                name="confirm"
              />
            </label>
            <span className="error " style={{ textAlign: "center" }}>
              {errorMessage}
            </span>
          </div>

          <div className="buttons">
            <button className="delete" onClick={(e) => handleForm(e, "delete")}>
              Delete User
            </button>
            <button className="save" onClick={(e) => handleForm(e, "save")}>
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditForm;
