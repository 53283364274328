import "./style/quiz.css";
import { IconContext } from "react-icons";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setQuizFinished } from "../../redux/userReducer";
import { setCurrentLessonDetails } from "../../redux/userReducer";
// import quizService from "../../services/quizService";
import QuizAnswer from "./quiz-answer";
const Quiz = ({ setShowView, view }) => {
  const [quizStage, setQuizStage] = useState(1);
  const [currentLessonIndex, setCurrentLessonIndex] = useState(0);
  const [quizData, setQuizData] = useState([]);
  const [message, setMessage] = useState("");
  const currentQuiz = useSelector((state) => state.user.value.currentQuiz);
  const [amountOfQuestions, setAmountOfQuestions] = useState(0);
  const [quizProgress, setQuizProgress] = useState(0);
  const [amountOfAnswers, setAmountOfAnswers] = useState(0);
  const [numberOfCorrectAnswers, setNumberOfCorrectAnswers] = useState(0);
  const dispatch = useDispatch();
  // const [moveToNextQuestion, setMoveToNextQuestion] = useState(false);
  const [lessonDetails, setLessonDetails] = useState({});
  const currentLessonDetails = useSelector(
    (state) => state.user.value.currentLessonDetails
  );
  useEffect(() => {
    if (currentQuiz) {
      setQuizData(currentQuiz);

      setAmountOfQuestions(currentQuiz.length);
    }
  }, []);

  useEffect(() => {
    setQuizProgress((quizStage / amountOfQuestions) * 100);
  }, [amountOfQuestions]);

  useEffect(() => {
    setQuizProgress((quizStage / amountOfQuestions) * 100);
  }, [quizStage]);

  useEffect(() => {
    if (quizData.length > 0) {
      setAmountOfAnswers(quizData[currentLessonIndex].answers.length);
    }
  }, [quizData[currentLessonIndex]]);

  useEffect(() => {
    setLessonDetails(currentLessonDetails);
  }, [currentLessonDetails]);

  const handleChosenAnswer = (boxElement) => {
    if (quizData[currentLessonIndex].type === "multiple") {
      // Toggle the "chosen" class
      if (boxElement.classList.contains("chosen")) {
        boxElement.classList.remove("chosen");
      } else {
        boxElement.classList.add("chosen");
      }
    } else {
      // If the question is single choice,  remove the "chosen" class from all boxes

      const boxes = document.querySelectorAll(".box");
      // Remove the "chosen" class from all boxes
      boxes.forEach((box) => {
        box.classList.remove("chosen");
      });
      // Add the "chosen" class to the clicked box
      boxElement.classList.add("chosen");
    }
  };

  const delay = (ms) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  const handleQuiz = async () => {
    setMessage("");
    const boxes = document.querySelectorAll(".box");
    // Check if the user has chosen an answer
    let chosenAnswer = false;
    boxes.forEach((box) => {
      if (box.classList.contains("chosen")) {
        chosenAnswer = true;
      }
    });

    if (!chosenAnswer) {
      setMessage("A choice is required");
      return;
    }

    if (quizData[currentLessonIndex].type === "multiple") {
      // Multiple choice
      const correctAnswers = quizData[currentLessonIndex].answers.filter(
        (answer) => answer.correct
      );

      boxes.forEach((box) => {
        if (box.classList.contains("chosen")) {
          const answer = correctAnswers.find(
            (answer) => answer.answer === box.innerText
          );

          if (answer && answer.correct) {
            box.classList.add("correct");
            setMessage("Correct");
          } else {
            box.classList.add("wrong");
            setMessage("Incorrect");
          }
        } else {
          const answer = correctAnswers.find(
            (answer) => answer.answer === box.innerText
          );
          if (answer && answer.correct) {
            box.classList.add("correct");
          }
        }
      });
    } else {
      // Single choice
      let correct = false;
      boxes.forEach((box) => {
        if (box.classList.contains("chosen")) {
          // mark user chosen answer

          box.classList.remove("chosen");

          const answer = quizData[currentLessonIndex].answers.find(
            (answer) => answer.answer === box.innerText
          );

          if (answer.correct) {
            box.classList.add("correct");
            setNumberOfCorrectAnswers(numberOfCorrectAnswers + 1);
            correct = true;
          } else {
            box.classList.add("wrong");
          }
        } else {
          // mark correct answers that user didn't choose
          const answer = quizData[currentLessonIndex].answers.find(
            (answer) => answer.answer === box.innerText
          );
          if (answer?.correct) {
            box.classList.add("correct");
          }
        }
      });

      if (!correct) {
        setMessage("Incorrect");
      } else {
        setMessage("Correct");
      }
    }

    // move to next question after 2.5 seconds
    await delay(2500);

    if (quizStage <= quizData.length - 1) {
      // remove all classes from boxes

      boxes.forEach((box) => {
        box.classList.remove("chosen");
        box.classList.remove("correct");
        box.classList.remove("wrong");
      });
      setMessage("");
      setQuizStage(quizStage + 1);
      setCurrentLessonIndex(currentLessonIndex + 1);
    } else {
      // quiz finished
      dispatch(
        setQuizFinished({
          numberOfCorrectAnswersOfQuiz: numberOfCorrectAnswers,
          numberOfQuestionsOfQuiz: amountOfQuestions,
        })
      );
      setShowView("rate-lesson");
    }
  };
  const handleWatchAgain = async () => {
    const currentLessonId = lessonDetails.lessonId;
    const currentVideoUrl = lessonDetails.videoUrl;
    dispatch(
      setCurrentLessonDetails({
        videoUrl: currentVideoUrl,
        lessonId: currentLessonId,
      })
    );
    setShowView("video-player");
  };
  return (
    <>
      <div className="quiz-wrapper">
        <div className="top"></div>
        <div className={view === "desktop" ? "quiz-progress-bar" : "dots"}>
          {view === "desktop" ? (
            // desktop view
            <>
              <div className="quiz-progress">
                <span className="line">
                  <span
                    className="red"
                    style={{ width: `${quizProgress}%` }}
                  ></span>
                  <span></span>
                  <span></span>
                </span>
                <span className="question-number">{`${quizStage}/${amountOfQuestions}`}</span>
              </div>
            </>
          ) : (
            // mobile view
            <>
              {quizData.map((question, index) => (
                <div
                  key={index}
                  className={
                    index === currentLessonIndex ||
                    index === currentLessonIndex - 1 ||
                    index === currentLessonIndex - 2 ||
                    index === currentLessonIndex - 3 ||
                    index === currentLessonIndex - 4 ||
                    index === currentLessonIndex - 5
                      ? "dot active"
                      : "dot"
                  }
                ></div>
              ))}
            </>
          )}
        </div>

        {quizData.length && currentLessonIndex < quizData.length && (
          <>
            <div className="question">
              {quizData[currentLessonIndex].question}
            </div>

            <div className="error">{message}</div>

            <div className="boxes">
              {quizData[currentLessonIndex].answers.map((answer, index) => (
                <QuizAnswer
                  view={view}
                  key={index}
                  index={index}
                  answer={answer}
                  handleChosenAnswer={handleChosenAnswer}
                  amountOfAnswers={amountOfAnswers}
                  currentLessonIndex={currentLessonIndex}
                  handleQuiz={handleQuiz}
                  handleWatchAgain={handleWatchAgain}
                />
              ))}
            </div>
            {view === "mobile" && (
              <>
                <div className="watch-again" onClick={handleWatchAgain}>
                  <div>
                    <IconContext.Provider value={{ size: "1.2rem" }}>
                      <BsFillPlayCircleFill />
                    </IconContext.Provider>
                  </div>
                  <p>Watch again</p>
                </div>
                <div className="next-question" onClick={handleQuiz}>
                  <button>Next</button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Quiz;
