import { IconContext } from "react-icons";
import { BsSearch } from "react-icons/bs";
import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { handleSearchPopup, setSearchParams } from "../../redux/userReducer";
import "./style/user-search-desktop.css";
const SearchDesktop = ({ setShowView }) => {
  const [search, setSearch] = useState("");
  const [videoCheckbox, setVideoCheckbox] = useState(false);
  const [filesCheckbox, setFilesCheckbox] = useState(false);
  const [communityCheckbox, setCommunityCheckbox] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const handleSearch = () => {
    if (search === "") {
      setErrorMessage("Please enter a search term");
      return;
    }
    if (search.length < 3) {
      setErrorMessage("Please enter at least 3 characters");
      return;
    }
    if (!videoCheckbox && !filesCheckbox && !communityCheckbox) {
      setErrorMessage("Please select a search option");
      return;
    }
    dispatch(
      setSearchParams({
        inVideo: videoCheckbox,
        inFiles: filesCheckbox,
        inCommunity: communityCheckbox,
        search: search,
      })
    );
    dispatch(handleSearchPopup({ searchPopupOpen: false }));
    setShowView("search-results");
  };

  return (
    <>
      <div className="user-search-wrapper">
        <form action="" className="search-form">
          <div className="search-control">
            <label htmlFor="searchAll">Search :</label>
            <input
              type="text"
              onChange={(e) => {
                setSearch(e.target.value);
                setErrorMessage("");
              }}
              value={search}
              id="searchAll"
              name="searchAll"
            />
          </div>
          <div className="search-control-checkbox">
            <input
              type="checkbox"
              checked={videoCheckbox}
              onChange={(e) => {
                setVideoCheckbox(e.target.checked);
                setErrorMessage("");
              }}
              name="inVideo"
              id="inVideo"
            />
            <span>In Video</span>
            <input
              type="checkbox"
              value={filesCheckbox}
              onChange={(e) => {
                setFilesCheckbox(e.target.checked);
                setErrorMessage("");
              }}
              name="inFiles"
              id="inFiles"
            />
            <span>In Files</span>
            <input
              type="checkbox"
              value={communityCheckbox}
              onChange={(e) => {
                setCommunityCheckbox(e.target.checked);
                setErrorMessage("");
              }}
              name="inCommunity"
              id="inCommunity"
            />
            <span>In Community</span>
          </div>
          <div className="search-form-footer">
            <div className="search-btn" onClick={handleSearch}>
              <div>Search</div>
              <div>
                <IconContext.Provider
                  value={{
                    color: "#ffffff",
                    size: "13px",
                  }}
                >
                  <BsSearch />
                </IconContext.Provider>
              </div>
            </div>
            <span className="error">{errorMessage}</span>
          </div>
        </form>
      </div>
    </>
  );
};

export default SearchDesktop;
