import "./style/index.css";
import "./style/user-all-sections-mobile.css";
import BackButton from "../common/backButton";
import SearchBarMobile from "../common/searchBar-mobile";
import ProgressBar from "react-bootstrap/ProgressBar";
import { BsSearch } from "react-icons/bs";
import { RiDownloadFill } from "react-icons/ri";
import { IoIosPlayCircle } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import ReactPlayer from "react-player/lazy";
import { useState, useEffect } from "react";
import Section from "./section";
import { useMediaQuery } from "react-responsive";
import { v4 as uuid4 } from "uuid";
import { setLessonProgressAverage } from "../../redux/userReducer";

import lessonsService from "../../services/lessonsService";
import { setCurrentLessonDetails } from "../../redux/userReducer";
import { IconContext } from "react-icons";

const AllSections = ({ lessons, setShowView }) => {
  const userViewSearchPopupIsOpen = useSelector(
    (state) => state.user.value.searchPopupOpen
  );
  // const [lessonsFromDb, setLessonsFromDb] = useState([]);

  const desktopView = useMediaQuery({ query: "(min-width:750px)" });
  const [mainProgressBar, setMainProgressBar] = useState(0);
  const [allSections, setAllSections] = useState([]);
  const [name, setName] = useState("");
  const tradeMark = String.fromCodePoint(0x02122);
  const userNameRedux = useSelector((state) => state.user.value.userName);
  const mobileMenuIsOpen = useSelector(
    (state) => state.user.value.mobileMenuOpen
  );
  const authorization = useSelector(
    (state) => state.allPermission.value.authorization
  );
  const averageProgressRedux = useSelector(
    (state) => state.user.value.lessonProgressAverage
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setAllSections([]);
    if (lessons) {
      setAllSections(lessons);
    }
  }, [lessons]);

  useEffect(() => {
    setMainProgressBar(averageProgressRedux);
  }, [averageProgressRedux]);
  useEffect(() => {
    if (userNameRedux) {
      setName(userNameRedux);
    }
  }, [userNameRedux]);
  const handleNextVideo = async () => {
    try {
      const lastUpdatedLesson = await lessonsService.fetchLastUpdatedLesson();
      if (lastUpdatedLesson.message === "success") {
        dispatch(
          setCurrentLessonDetails({
            videoUrl: lastUpdatedLesson.url,
            lessonId: lastUpdatedLesson.lessonId,
            lessonTitle: lastUpdatedLesson.lessonTitle,
            lessonDescription: lastUpdatedLesson.lessonDescription,
            sectionName: lastUpdatedLesson.sectionName,
            files: lastUpdatedLesson.lessonFiles,
          })
        );
        setShowView("video-player");
      } else {
        console.log(
          lastUpdatedLesson.message,
          "lastUpdatedLesson.message error"
        );
      }
    } catch (error) {
      console.log(error, "error lastUpdatedLesson");
    }
  };
  return (
    <>
      {desktopView ? (
        <>
          <div
            className={
              userViewSearchPopupIsOpen ? "user-desktop dark" : "user-desktop"
            }
          >
            <div className="top">
              <div className="welcome-desktop">
                <p className="main-title">{`HELLO ${name}`}</p>
                <p className="subtitle">Welcome to MeatU</p>
                {/* <p className="bottom">
                  Welcome to
                  <span style={{ fontWeight: "bolder" }}>MeatU</span>
                </p> */}
              </div>
              <div className="separator"></div>
              <div className="progress-bar-desktop">
                <div className="next" onClick={handleNextVideo}>
                  <IconContext.Provider
                    value={{
                      color: "#ffffff",
                      className: "global-class-name",
                      size: "1.5rem",
                      style: { cursor: "pointer" },
                    }}
                  >
                    <IoIosPlayCircle />
                  </IconContext.Provider>
                  <span> Play Next Video</span>
                </div>
                <div className="percent">
                  <span>Mooooooving on:</span>
                  <span>{`${mainProgressBar}%`}</span>
                </div>
                <ProgressBar
                  // onClick={(e) => handleProgressBar(e)}
                  min={0}
                  max={100}
                  // className="user-progress"
                  now={mainProgressBar}
                  variant="desktop-custom-css"
                />
              </div>
            </div>
            <div className="user-desktop-bottom">
              {allSections.length && (
                <>
                  {allSections.map((section) => {
                    return (
                      <Section
                        key={section.id}
                        section={section}
                        setShowView={setShowView}
                      />
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        // mobile view
        <>
          <div className="back-button-wrapper">
            <BackButton
              context="user"
              setShowView={setShowView}
              title="All Sections"
            />
          </div>
          {!mobileMenuIsOpen && (
            <div className="search-section">
              <SearchBarMobile
                setShowView={setShowView}
                context={authorization}
                searchContext="lessons"
              />
            </div>
          )}

          <div className="user-accordion-wrapper">
            {allSections.map((section) => {
              return (
                <Section
                  key={section.id}
                  setShowView={setShowView}
                  section={section}
                />
              );
            })}
          </div>
          <div className="mobile-next-video-btn">
            <button onClick={handleNextVideo}>Next Video</button>
          </div>
        </>
      )}
    </>
  );
};

export default AllSections;
