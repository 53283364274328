import React, { useEffect, useState } from "react";
import "./style/notifications-popup.css";
import { useSelector } from "react-redux";
import bell from "../img/bell.png";
import { useDispatch } from "react-redux";
import {
  setListOfAlreadyReadNotifications,
  setNotifications,
  setNotificationsPopupIsOpen,
} from "../../redux/reducer";
import notificationsService from "../../services/notificationsService";
import { useMediaQuery } from "react-responsive";

export const NotificationsPopup = () => {
  const selectedNotification = useSelector(
    (state) => state.allPermission.value.selectedNotification
  );
  const authorization = useSelector(
    (state) => state.allPermission.value.authorization
  );

  const [notificationId, setNotificationId] = useState();
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [notificationType, setNotificationType] = useState("");
  useEffect(() => {
    if (selectedNotification) {
      setEmail(selectedNotification.created_by);
      setMessage(selectedNotification.message);
      setNotificationType(selectedNotification.type);
      setNotificationId(selectedNotification.id);
    }
  }, [selectedNotification]);

  const handleNotifications = async () => {
    const notifications = await notificationsService.notificationAlreadyRead(
      notificationId,
      authorization
    );

    if (notifications.message === "success") {
      dispatch(setNotifications(notifications));
      dispatch(
        setListOfAlreadyReadNotifications({
          listOfAlreadyReadNotifications:
            notifications.userListOfReadNotifications,
        })
      );
    } else {
      console.log(notifications.message, "notifications. error");
    }

    dispatch(
      setNotificationsPopupIsOpen({
        notificationsPopupIsOpen: false,
      })
    );
  };
  const desktopView = useMediaQuery({ query: "(min-width:700px)" });
  const dispatch = useDispatch();

  return (
    <>
      <>
        <div className="notification-popup-wrapper">
          <div
            className={
              desktopView
                ? "notification-popup-desktop"
                : "notification-popup-mobile"
            }
          >
            <h5 className="title">{notificationType}</h5>
            {message !== "" && <p className="message">{message}</p>}
            <div className="footer">
              <div className="user-details">
                <div className="user-email">From: {email}</div>
              </div>
              <div className="close-btn">
                <button onClick={handleNotifications}>Close</button>
              </div>
            </div>
          </div>

          <img
            src={bell}
            className={
              desktopView ? "popup-bell-img-desktop" : "popup-bell-img-mobile"
            }
            alt=""
          />
        </div>
      </>
    </>
  );
};
