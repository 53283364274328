import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import axios from "./axios";
function ProtectedRoutes() {
  const [isLoading, setIsLoading] = useState(true);
  const [authLevel, setAuthLevel] = useState(null);
  const location = useLocation();
  const token = localStorage.getItem("token");

  useEffect(() => {
    const verifyToken = async () => {
      if (token) {
        try {
          const response = await axios.get("/users/verify-token", {
            headers: { "x-auth-token": token },
          });
          if (response?.data && response?.data.auth) {
            setAuthLevel(response.data.auth);
          } else {
            localStorage.removeItem("token");
          }
        } catch (error) {
          console.error("Token verification failed", error);
          localStorage.removeItem("token");
        }
      }
      setIsLoading(false);
    };

    verifyToken();
  }, [token]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  const getRedirectPath = () => {
    switch (authLevel) {
      case 1:
        return "/user-home";
      case 2:
        return "/admin-home";
      case 3:
        return "/super-home";
      default:
        return "/login";
    }
  };

  const redirectPath = getRedirectPath();
  if (location.pathname !== redirectPath) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
}

export default ProtectedRoutes;
