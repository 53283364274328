import { FaHamburger } from "react-icons/fa";
import { IconContext } from "react-icons";
import { useMediaQuery } from "react-responsive";

const CustomRatingIcon = () => {
  const changeIconSize = useMediaQuery({ query: "(min-width:450px)" });

  return (
    <>
      <IconContext.Provider
        value={{
          style: {
            size: changeIconSize ? "45px" : "32",
          },
        }}
      >
        <FaHamburger />
      </IconContext.Provider>
    </>
  );
};

export default CustomRatingIcon;
