import "./style/backButton.css";
import { IconContext } from "react-icons";
import { BsArrowLeft } from "react-icons/bs";
// import { FiSearch } from "react-icons/fi";
import { useMediaQuery } from "react-responsive";
// import { useState } from "react";
const BackButton = ({ title, setShowView, context }) => {
  const mobileView = useMediaQuery({ query: "(max-width:400px)" });
  const handleView = () => {
    if (context.startsWith("search")) {
      // if user pressed back button from search results
      const userLocation = context.split("-")[1];

      switch (userLocation) {
        case "lessons":
          setShowView("all-section");
          break;
        case "files":
          setShowView("user-mobile-files-downloads");
          break;
        case "messages":
          setShowView("user-mobile-community");
          break;
        default:
          break;
      }
      return;
    } else {
      // if user pressed back button from any other view

      if (context === "user") {
        setShowView("user-mobile-start");
      } else {
        setShowView("mobile-start-screen");
      }
    }
  };
  return (
    <>
      <div className={`back-btn ${context}`}>
        {title && <div className={`left-title ${context}`}>{title}</div>}

        <div className="right-section" onClick={handleView}>
          <IconContext.Provider
            value={{
              color: "#ffffff",
              size: mobileView ? "1.1rem" : "1.3rem",
              style: { marginTop: "1px", marginRight: "20px" },
            }}
          >
            <BsArrowLeft />
          </IconContext.Provider>
          <span>Back</span>
        </div>
      </div>
    </>
  );
};

export default BackButton;
