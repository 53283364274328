import { useState, useEffect } from "react";
// import { IconContext } from "react-icons";
// import { IoMdClose } from "react-icons/io";
import "./App.css";
import AdminHomePage from "./Components/admin";
import SuperAdminHomePage from "./Components/super-admin";
import MessageForm from "./Components/common/messageForm";
import Login from "./Components/common/login";
import { useMediaQuery } from "react-responsive";
import { NotificationsPopup } from "./Components/common/notifications-popup";
import EditForm from "./Components/common/edit-user-and-distributor-form";
import UsersHomePage from "./Components/user";
import { useSelector, useDispatch } from "react-redux";
import { setMessagePopup } from "./redux/reducer";
import ProtectedRoute from "./utils/protected-route";
import MessageEditDeletePopup from "./Components/common/message-edit-delete";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import UploadPopup from "./Components/super-admin/upload-popup";
import CreateQuizPopup from "./Components/super-admin/create-quiz-popup";
import EditQuizPopup from "./Components/super-admin/edit-quiz-popup";
import DeleteFile from "./Components/common/delete-file-popup";
import ForgotPassword from "./Components/common/forgot-password";
import ResetPassword from "./Components/common/reset-password";
import LinksPopup from "./Components/user/user-links-popup";
// import { setMessageEditDeletePopup } from "./redux/reducer";

function App() {
  const desktopView = useMediaQuery({ query: "(min-width:750px)" });

  const [isNotificationsPopupOpen, setIsNotificationsPopupOpen] =
    useState(false);

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const createQuizPopupOpen = useSelector(
    (state) => state.superAdminSlice.value.createQuizPopupOpen
  );
  const editQuizPopupOpen = useSelector(
    (state) => state.superAdminSlice.value.editQuizPopupOpen
  );
  const authorization = useSelector(
    (state) => state.allPermission.value.authorization
  );
  const messagePopupOpen = useSelector(
    (state) => state.allPermission.value.messagePopupOpen
  );

  const notificationsPopupIsOpen = useSelector(
    (state) => state.allPermission.value.notificationsPopupIsOpen
  );

  const uploadPopupOpen = useSelector(
    (state) => state.superAdminSlice.value.uploadPopupOpen
  );
  const messageEditDeletePopupOpen = useSelector(
    (state) => state.allPermission.value.messageEditDeletePopupOpen
  );
  const deleteFilePopupOpen = useSelector(
    (state) => state.allPermission.value.deleteFilePopupOpen
  );
  const linksPopupOpen = useSelector(
    (state) => state.user.value.linksPopupOpen
  );
  // const dispatch = useDispatch();

  const setClassName = (context) => {
    let selectedClass;
    if (context === "user") {
      selectedClass = "user-mobile-wrapper";
      if (desktopView) {
        selectedClass = "user-desktop-wrapper";
      }
    } else {
      selectedClass = "App";
    }

    return selectedClass;
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/reset/:token" element={<ResetPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route element={<ProtectedRoute />}>
          <Route element={<UsersHomePage />} path="/user-home" exact />
          <Route element={<AdminHomePage />} path="/admin-home" exact />
          <Route
            element={
              <SuperAdminHomePage
                setIsNotificationsPopupOpen={setIsNotificationsPopupOpen}
                setIsEditFormOpen={setIsEditFormOpen}
              />
            }
            path="/super-home"
            exact
          />
          <Route path="/*" element={<Navigate to="/login" replace />} />
        </Route>
      </>
    )
  );

  return (
    <>
      <div
        className={
          isNotificationsPopupOpen ||
          isEditFormOpen ||
          uploadPopupOpen ||
          createQuizPopupOpen ||
          editQuizPopupOpen ||
          messageEditDeletePopupOpen ||
          linksPopupOpen
            ? `${setClassName(authorization)} dark`
            : setClassName(authorization)
        }
      >
        {/*Message creation popup TRIGGER */}
        {!desktopView && (
          <>
            {messagePopupOpen && (
              <div className="show-popup">
                <div className="popUp-container">
                  <MessageForm />
                </div>
              </div>
            )}
          </>
        )}

        <RouterProvider router={router} />
      </div>

      {/* delete message OR lesson files popup */}
      {deleteFilePopupOpen && <DeleteFile />}

      {/* message edit&delete   popup */}
      {messageEditDeletePopupOpen && <MessageEditDeletePopup />}
      {/* notification  popup */}
      {notificationsPopupIsOpen && <NotificationsPopup />}

      {isEditFormOpen && <EditForm setIsEditFormOpen={setIsEditFormOpen} />}

      {/* upload lesson or section  popup */}
      {uploadPopupOpen && <UploadPopup />}

      {/* create quiz popup */}
      {createQuizPopupOpen && <CreateQuizPopup />}

      {/* edit quiz popup */}
      {editQuizPopupOpen && <EditQuizPopup />}
      {linksPopupOpen && <LinksPopup />}
    </>
  );
}

export default App;
