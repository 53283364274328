import React, { useEffect, useState, useRef } from "react";
import lessonsService from "../../services/lessonsService";
import { setAllLessons } from "../../redux/userReducer";
import { useDispatch } from "react-redux";
import { setUploadPopupOpen } from "../../redux/superAdminReducer";
import { toast } from "react-toastify";
const SectionEditPopup = ({ uploadForm }) => {
  const [title, setTitle] = useState(uploadForm.title);
  const [description, setDescription] = useState(uploadForm.description);
  const [error, setError] = useState("");
  const [verifyDelete, setVerifyDelete] = useState(false);
  const dispatch = useDispatch();
  const checkboxRef = useRef();

  const handleDeletion = async (e) => {
    e.preventDefault();
    setError("");
    setVerifyDelete(true);

    if (verifyDelete) {
      if (!checkboxRef.current.checked) {
        setError("Please confirm deletion");
        return;
      } else {
        // send request to delete lesson
        const deleteSection = await lessonsService.deleteSection(
          uploadForm.sectionId
        );

        if (deleteSection.message === "success") {
          // dispatch(setAllLessons({ lessons: deleteSection.lessons }));
          // dispatch(setUploadPopupOpen({ uploadPopupOpen: false }));
          toast.success("Section deleted successfully");
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          toast.error(deleteSection.message);
        }
      }
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (title === "") {
      setError("Title is required");
      return;
    }

    if (description === "") {
      setError("Description is required");
      return;
    }
    if (uploadForm.title === title && uploadForm.description === description) {
      setError("No changes were made");
      return;
    }

    try {
      const response = await lessonsService.editSection(
        uploadForm.sectionId,
        title,
        description
      );
      if (response.message === "success") {
        toast.success("Section edited successfully");
        dispatch(setAllLessons({ lessons: response.lessons }));
        dispatch(setUploadPopupOpen({ uploadPopupOpen: false }));
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err, "edit section error");
    }
  };

  return (
    <>
      <div
        className={
          uploadForm.context === "edit-section"
            ? "section-edit-wrapper"
            : "all-lesson-wrapper"
        }
      >
        <label htmlFor="section-number">Section Number</label>
        <input
          type="text"
          name="section-number"
          id="section-number"
          disabled
          defaultValue={uploadForm.sectionId}
        />
      </div>
      <div
        className={
          uploadForm.context === "edit-section"
            ? "section-edit-wrapper"
            : "all-lesson-wrapper"
        }
      >
        <label htmlFor="section-title">Section Title</label>
        <input
          type="text"
          name="section-title"
          id="section-title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          // defaultValue={uploadForm.title}
        />
      </div>
      <div
        className={
          uploadForm.context === "edit-section"
            ? "section-edit-wrapper"
            : "all-lesson-wrapper"
        }
      >
        <label htmlFor="section-description">Section Description</label>
        <textarea
          name=""
          id=""
          rows="5"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
      </div>
      {verifyDelete && (
        <div className="verify-delete">
          <label htmlFor="delete-section" className="delete-section">
            <input
              type="checkbox"
              name="delete-section"
              ref={checkboxRef}
              id="delete-section"
            />
            <span>
              Deleting a section will delete all lessons in that section
            </span>
          </label>
        </div>
      )}

      <div className="error">{error}</div>
      <div className="btn-wrapper">
        <button
          className="edit-section-btn"
          onClick={(e) => handleFormSubmit(e)}
        >
          Edit Section
        </button>
        <button className="delete-section" onClick={(e) => handleDeletion(e)}>
          Delete Section
        </button>
      </div>
    </>
  );
};

export default SectionEditPopup;
