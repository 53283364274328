/* currently not in use anywhere */

import ReactPlayer from "react-player/youtube";
import { sliceText } from "../../utils/sliceText";
import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { IconContext } from "react-icons";
import { AiFillCheckCircle, AiOutlinePlayCircle } from "react-icons/ai";
import { BsPatchCheckFill } from "react-icons/bs";
import fileService from "../../services/fileService";
import { toast } from "react-toastify";
import { RiDownloadFill } from "react-icons/ri";
import { useMediaQuery } from "react-responsive";
import lessonsService from "../../services/lessonsService";
import { setCurrentLessonDetails } from "../../redux/userReducer";
// import getYouTubeVideoLength from "../../utils/you-tube-video-length";
// import axiosBase from "../../utils/axios";
// import YoutubePlayer from "./you-tube-player";
// import "./style/search-result-community-message.ss";
const Lesson = ({ lesson, setShowView, section_id }) => {
  const [showFullMessage, setShowFullMessage] = useState(false);
  const [videoClicked, setVideoClicked] = useState(false);
  const desktopView = useMediaQuery({ query: "(min-width:750px)" });
  const dispatch = useDispatch();

  const handleVideoClick = async (videoUrl) => {
    if (videoClicked) return;
    const insertWatchedVideo = await lessonsService.insertWatchedVideo(
      lesson.id,
      section_id
    );
    setVideoClicked(true);
    if (insertWatchedVideo.message === "success") {
      dispatch(setCurrentLessonDetails({ videoUrl, lessonId: lesson.id }));
      setShowView("video-player");
      return;
    }
  };

  const handleLessonFilesDownload = async (files) => {
    if (files === null || files === "null") return;
    let filesArray;
    if (files.includes(",")) {
      filesArray = files.split(",");
    } else {
      filesArray = [files];
    }

    try {
      const response = await fileService.downloadFiles(
        filesArray,
        `lesson-files`
      );

      if (response instanceof Blob) {
        if (response.size === 0) {
          throw new Error("Downloaded file is empty");
        }

        if (filesArray.length > 1 && response.type !== "application/zip") {
          throw new Error("Expected a zip file for multiple files");
        }

        const anchor = document.createElement("a");
        anchor.href = URL.createObjectURL(response);
        anchor.download = `redefine-lesson-files-${lesson.title}${
          filesArray.length > 1 ? ".zip" : ""
        }`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(anchor.href);

        toast.success(
          `${filesArray.length > 1 ? "Files" : "File"} Downloaded Successfully`
        );
      } else {
        throw new Error("Invalid response type");
      }
    } catch (error) {
      console.log(error, "error in download file");
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      {desktopView ? (
        <>
          {" "}
          <div className="video-card">
            <ReactPlayer
              config={{
                youtube: {
                  playerVars: { origin: "https://www.youtube.com" },
                },
              }}
              controls={true}
              url={lesson.video}
              width="270px"
              height="auto"
            />

            {/* <YoutubePlayer videoUrl={lesson.video} /> */}
            <div
              className="video-title"
              onClick={() => handleVideoClick(lesson.video)}
            >{` ${lesson.title}`}</div>
            <div
              className="video-message"
              onClick={() => setShowFullMessage(!showFullMessage)}
            >
              {showFullMessage ? lesson.text : sliceText(lesson.text, 100)}
            </div>
            {lesson?.ended && (
              <>
                <div className="lesson-completed">
                  <span>Completed</span>

                  <IconContext.Provider
                    value={{
                      color: "#ffffff",
                      size: "17px",
                    }}
                  >
                    <BsPatchCheckFill />
                  </IconContext.Provider>
                </div>
              </>
            )}
            <div className="video-divider"></div>
            <div
              className="video-footer"
              onClick={() => handleLessonFilesDownload(lesson.files)}
            >
              <div className="time">7:34 min</div>
              <div className="download-attached-files">
                Download attached files
              </div>
              <div className="download-video">
                <IconContext.Provider
                  value={{
                    color: "#ffffff",
                    size: "20px",
                  }}
                >
                  <RiDownloadFill />
                </IconContext.Provider>
              </div>
            </div>
          </div>
        </>
      ) : (
        // mobile view
        <>
          <div className="inner-section">
            <div className="video-number">{lesson.id}</div>
            <div className="video-details">
              <div className="video-title-wrapper">
                <div className="check">
                  {lesson?.ended && (
                    <>
                      <IconContext.Provider
                        value={{
                          size: "1rem",
                          color: "#ffffff",
                          marginTop: "5px",
                        }}
                      >
                        <AiFillCheckCircle />
                      </IconContext.Provider>
                    </>
                  )}

                  <div className="video-title"> {lesson.title}</div>
                </div>
                <div className="video-time">03:45 min</div>
              </div>
              <div
                className="play-icon"
                onClick={() => handleVideoClick(lesson.video)}
              >
                <IconContext.Provider
                  value={{
                    size: "2.5rem",
                    color: "#ffffff",
                  }}
                >
                  <AiOutlinePlayCircle />
                </IconContext.Provider>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Lesson;
