import axiosBaseUrl from "../utils/axios";
const services = {
  login: async (email, password) => {
    try {
      const { data } = await axiosBaseUrl.post("/login/user", [
        `${email}`,
        `${password}`,
      ]);

      return data;
    } catch (err) {
      console.log(err, "login error");
      return err;
    }
  },
  register: async (firstName, lastName, email, phone, password, language) => {
    try {
      const { data } = await axiosBaseUrl.post("/register/user", {
        firstName,
        lastName,
        email,
        phone,
        password,
        language,
      });
      return data;
    } catch (err) {
      console.log(err, "registration error");
      return err;
    }
  },
  inviteMember: async (email, role, distributorId = null) => {
    try {
      // if the call is made from super admin we add distributorId
      // if its made from an admin distributor will be null and we extract email from token to access admin email and enter as distributor of invited user
      const { data } = await axiosBaseUrl.post("/invite/user", {
        email,
        role,
        distributorId,
      });
      return data;
    } catch (err) {
      console.log(err, "invitation error");
      return err;
    }
  },
  createDistributor: async (distributorName) => {
    try {
      const { data } = await axiosBaseUrl.post("/invite/distributor", {
        distributorName,
      });
      return data;
    } catch (err) {
      console.log(err, "invite distributor error");
      return err;
    }
  },
  fetchUsers: async () => {
    try {
      const { data } = await axiosBaseUrl.get("/users/myUsers");
      return data;
    } catch (err) {
      console.log(err, "fetch users error");
      return err;
    }
  },
  fetchDistributorsAndUsers: async () => {
    try {
      const { data } = await axiosBaseUrl.get(
        "/fetchDistributors/usersAndDistributors"
      );
      return data;
    } catch (err) {
      console.log(err, "fetch distributors&&users error");
      return err;
    }
  },
  fetchAllDistributors: async () => {
    try {
      const { data } = await axiosBaseUrl.get(
        "/fetchDistributors/allDistributors"
      );
      return data;
    } catch (err) {
      console.log(err, "fetch distributors error");
      return err;
    }
  },
  deleteMember: async (email) => {
    try {
      const { data } = await axiosBaseUrl.delete("delete/member", {
        params: {
          email,
        },
      });
      // fetch all users again after deletion
      return data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
  deleteDistributor: async (distributorName) => {
    try {
      const { data } = await axiosBaseUrl.delete("delete/distributor", {
        params: {
          distributorName,
        },
      });
      // fetch all users again after deletion

      return data;
    } catch (err) {
      console.log(err, "delete distributor error");
      return err;
    }
  },

  updateMember: async (fullName, email, phone, language, authorization) => {
    try {
      const { data } = await axiosBaseUrl.put("/update/member", {
        fullName,
        email,
        phone,
        language,
        authorization,
      });

      return data;
    } catch (err) {
      console.log(err, "update user error");

      return err;
    }
  },
  updateDistributor: async (companyName, newName) => {
    try {
      const { data } = await axiosBaseUrl.put("/update/distributor", {
        companyName,
        newName,
      });
      return data;
    } catch (err) {
      console.log(err);
    }
  },

  fetchUserStats: async () => {
    try {
      const { data } = await axiosBaseUrl.get("/users/userStats");
      return data;
    } catch (err) {
      console.log(err, "fetch user stats error");
      return err;
    }
  },
  fetchAdminStats: async () => {
    try {
      const { data } = await axiosBaseUrl.get("/users/adminStats");
      return data;
    } catch (err) {
      console.log(err, "fetch admin stats error");
      return err;
    }
  },
  fetchSuperAdminStats: async () => {
    try {
      const { data } = await axiosBaseUrl.get("/users/superAdminStats");
      return data;
    } catch (err) {
      console.log(err, "fetch super admin stats error");
      return err;
    }
  },
  fetchAverageLessonRating: async () => {
    try {
      const { data } = await axiosBaseUrl.get("/users/averageLessonRating");
      return data;
    } catch (err) {
      console.log(err, "fetch average lesson rating error");
      return err;
    }
  },
  fetchAllUsersAverage: async () => {
    try {
      const { data } = await axiosBaseUrl.get("/users/allUsersAverage");
      return data;
    } catch (err) {
      console.log(err, "fetch all users average error");
      return err;
    }
  },
  checkAuth: async () => {
    try {
      const { data } = await axiosBaseUrl.get("/checkAuth");
      return data;
    } catch (err) {
      console.log(err, "check auth error");
      return err;
    }
  },
};
export default services;
