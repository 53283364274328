import logo2 from "../img/logo2.png";
import logo1 from "../img/logo.png";

const Logos = () => {
  return (
    <>
      <div className="login-logos">
        <img src={logo2} className="left-logo" alt="logo" />
        <img src={logo1} className="right-logo" alt="logo" />
      </div>
    </>
  );
};

export default Logos;
