import axiosBaseUrl from "../utils/axios";
import lessonsService from "./lessonsService";
import messageService from "./messageService";
const fileService = {
  downloadFiles: async (files, context) => {
    try {
      const { data } = await axiosBaseUrl.get("/files/download", {
        params: {
          files,
          context,
        },
        responseType: "blob",
      });
      return data;
    } catch (err) {
      console.log(err, "download file error");
    }
  },
  fetchUserFiles: async () => {
    try {
      const { data } = await axiosBaseUrl.get(`/files/allFiles`);
      return data;
    } catch (err) {
      console.log(err, "fetch user files error");
    }
  },
  handleFilePreview: async (fileName, context) => {
    try {
      const { data } = await axiosBaseUrl.get(
        `/files/preview/${context}/${fileName}`,
        {
          responseType: "blob", // Set responseType to 'blob' to handle binary data
        }
      );

      return data;
    } catch (err) {
      console.log(err, "preview file error");
    }
  },
  handleFileDelete: async ({ fileName, id, context, sectionId = null }) => {
    try {
      // delete file from server

      const { data } = await axiosBaseUrl.delete(
        `/files/delete/${context}/${id}/${fileName}`,
        { data: { sectionId } }
      );
      if (data.message === "success") {
        if (context === "message") {
          const message = await messageService.fetchMessages();
          return {
            messages: message.messages.length ? message.messages : [],
            message: "success",
          };
        } else if (context === "lesson") {
          const lessons = await lessonsService.fetchLessons();
          lessons.currentSectionId = data.sectionId;
          return lessons;
        }
      } else {
        // if we get an error from the server
        return { error: "error", data };
      }
      // after we fetch new data and return it to the component
    } catch (err) {
      console.log(err, "delete file error");
    }
  },

  handleLinkDeletion: async ({ linkTitle, lessonId, sectionId }) => {
    try {
      const { data } = await axiosBaseUrl.post(`/files/delete/link/`, {
        linkTitle,
        sectionId,
        lessonId,
      });
      if (data.message === "success") {
        const lessons = await lessonsService.fetchLessons();
        lessons.currentSectionId = data.sectionId;
        return lessons;
      } else {
        return { error: "error", data };
      }
    } catch (err) {
      console.log(err, "delete link error");
    }
  },
};

export default fileService;
