import { Pie } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
Chart.register(ArcElement);

const PieChart = ({ data }) => {
  // const authorization = useSelector(
  //   (state) => state.allPermission.value.authorization
  // );
  const [chartData, setChartData] = useState({
    labels: [
      "0% - 20%",
      "20% - 50%",
      "50% - 70%",
      "70% - 100%",
      "Finished 100%",
    ],
    datasets: [
      {
        data: [0, 0, 0, 0, 0],
        backgroundColor: [
          "#F20B07",
          "#ffffff",
          "#850704",
          "#cb2a27",
          "#8e8e8e",
        ],
        hoverBackgroundColor: [
          "#36a2eb",
          "#36a2eb",
          "#36a2eb",
          "#36a2eb",
          "#36a2eb",
        ],
      },
    ],
  });

  useEffect(() => {
    const categoryCounts = [0, 0, 0, 0, 0];
    // Calculate the counts for each completion percentage category
    if (data.length) {
      data.forEach((entry) => {
        const percentage = entry.completionPercentage;
        if (percentage >= 0 && percentage < 20) {
          categoryCounts[0]++;
        } else if (percentage >= 20 && percentage < 50) {
          categoryCounts[1]++;
        } else if (percentage >= 50 && percentage < 70) {
          categoryCounts[2]++;
        } else if (percentage >= 70 && percentage < 100) {
          categoryCounts[3]++;
        } else if (percentage === 100) {
          categoryCounts[4]++;
        }
      });

      setChartData((prevChartData) => ({
        ...prevChartData,
        datasets: [
          {
            ...prevChartData.datasets[0],
            data: categoryCounts,
          },
        ],
      }));
    }
  }, [data]);

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      labels: {
        render: (args) => {
          return args.label;
        },
        font: {
          size: 12,
        },
        position: "outside",
        overlap: true,
      },
    },
    layout: {
      padding: {
        top: 20,
      },
    },
    maintainAspectRatio: false,
    responsive: false,
    width: 220,
    height: 220,
    tooltips: {
      enabled: true,
    },
  };

  return (
    <div style={{ width: "220px", height: "220px" }}>
      <Pie data={chartData} options={chartOptions} width={220} height={220} />
    </div>
  );
};

export default PieChart;
