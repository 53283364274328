import axiosBaseUrl from "../utils/axios";
const quizService = {
  createQuiz: async (lessonId, quiz) => {
    try {
      const { data } = await axiosBaseUrl.post(`/quiz/create`, {
        quiz,
        lessonId,
      });
      return data;
    } catch (err) {
      console.log(err, "create quiz error");
    }
  },
  fetchQuizzes: async () => {
    try {
      const { data } = await axiosBaseUrl.get("/quiz/all");
      return data;
    } catch (err) {
      console.log(err, "fetch quizzes error");
    }
  },
  fetchQuizByLessonId: async (lessonId) => {
    try {
      console.log(lessonId, "lessonId");
      const { data } = await axiosBaseUrl.get(`/quiz/lesson/${lessonId}`);
      return data;
    } catch (err) {
      console.log(err, "fetch quiz by lesson id error");
    }
  },
  deleteQuiz: async (lessonId) => {
    try {
      const { data } = await axiosBaseUrl.delete(`/quiz/delete/${lessonId}`);
      return data;
    } catch (err) {
      console.log(err, "delete quiz error");
    }
  },
  updateQuiz: async (quiz, lessonId) => {
    try {
      const { data } = await axiosBaseUrl.put(`/quiz/update/${lessonId}`, quiz);
      return data;
    } catch (err) {
      console.log(err, "update quiz error");
    }
  },
};

export default quizService;
