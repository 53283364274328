import { combineReducers, configureStore } from "@reduxjs/toolkit";
import allPermission from "./reducer";
import user from "./userReducer";
import superAdminSlice from "./superAdminReducer";
import adminReducer from "./adminReducer";

const rootReducer = combineReducers({
  allPermission,
  user,
  superAdminSlice,
  adminReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
