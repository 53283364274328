import "./style/community.css";
import { IconContext } from "react-icons";
import MessageForm from "./messageForm";
import { TbEditCircle } from "react-icons/tb";

import { useMediaQuery } from "react-responsive";
import { useState, useEffect } from "react";
import CommunityMessages from "./community-messages";
import { useDispatch, useSelector } from "react-redux";
import { setMessagePopup } from "../../redux/reducer";
import messageService from "../../services/messageService";
const Community = () => {
  // const [showDesktopMessageForm, setShowDesktopMessageForm] = useState(false);

  const desktopView = useMediaQuery({ query: "(min-width:740px)" });
  const device = desktopView ? "desktop" : "mobile";
  const dispatch = useDispatch();
  const messagePopupOpen = useSelector(
    (state) => state.allPermission.value.messagePopupOpen
  );

  const authorization = useSelector(
    (state) => state.allPermission.value.authorization
  );
  const distributorsRedux = useSelector(
    (state) => state.superAdminSlice.value.distributorsList
  );

  return (
    <>
      <div className="information">
        {desktopView && <h3>Community Messages:</h3>}

        {authorization !== "user" && (
          <div className="create-message-btn">
            <div
              className="btn"
              onClick={() =>
                dispatch(
                  setMessagePopup({
                    messagePopupOpen: !messagePopupOpen,
                  })
                )
              }
            >
              <h3 className="create-message">Create new message</h3>

              <IconContext.Provider
                value={{
                  color: "#ffffff",
                  size: "1.2em",
                }}
              >
                <TbEditCircle />
              </IconContext.Provider>
            </div>
          </div>
        )}
        {desktopView && (
          <>
            {messagePopupOpen && (
              <div className="message-form-wrapper">
                <MessageForm distributorsRedux={distributorsRedux} />
              </div>
            )}
          </>
        )}

        <CommunityMessages />
      </div>
    </>
  );
};

export default Community;
