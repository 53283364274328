import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: {
      lessons: [],
      userStatistics: [],
      lessonProgressAverage: 0,
      files: [],
      currentQuiz: [],
      searchPopupOpen: false,
      userName: "",
      quizFinished: {
        numberOfCorrectAnswersOfQuiz: 0,
        numberOfQuestionsOfQuiz: 0,
      },
      userRatingIDFromDB: 0,
      mobileMenuOpen: false,
      currentLessonDetails: {
        videoUrl: "",
        lessonId: "",
        sectionName: "",
        files: [],
        lessonTitle: "",
        lessonDescription: "",
        from: "",
      },
      searchParams: {
        search: "",
        inVideo: false,
        inFiles: false,
        inCommunity: false,
      },
      searchResultsView: false,
      linksPopupOpen: false,
      links: [],
    },
  },
  reducers: {
    setAllLessons: (state, action) => {
      const { lessons } = action.payload;

      state.value.lessons = lessons;
    },
    handleSearchPopup: (state, action) => {
      const { searchPopupOpen } = action.payload;
      state.value.searchPopupOpen = searchPopupOpen;
    },
    setUserName: (state, action) => {
      const { userName } = action.payload;
      state.value.userName = userName;
    },
    setSearchParams: (state, action) => {
      const { inVideo, inFiles, inCommunity, search } = action.payload;
      state.value.searchParams = {
        inVideo,
        inFiles,
        inCommunity,
        search,
      };
    },
    setSearchResultsView: (state, action) => {
      const { searchResultsView } = action.payload;
      state.value.searchResultsView = searchResultsView;
    },
    setFiles: (state, action) => {
      const { files } = action.payload;

      state.value.files = files;
    },
    setCurrentQuiz: (state, action) => {
      const { currentQuiz } = action.payload;

      state.value.currentQuiz = currentQuiz;
    },
    setQuizFinished: (state, action) => {
      const { numberOfCorrectAnswersOfQuiz, numberOfQuestionsOfQuiz } =
        action.payload;
      state.value.quizFinished.numberOfCorrectAnswersOfQuiz =
        numberOfCorrectAnswersOfQuiz;
      state.value.quizFinished.numberOfQuestionsOfQuiz =
        numberOfQuestionsOfQuiz;
    },
    setUserRatingIDFromDB: (state, action) => {
      const { userRatingIDFromDB } = action.payload;
      state.value.userRatingIDFromDB = userRatingIDFromDB;
    },
    setMobileMenu: (state, action) => {
      const { mobileMenuOpen } = action.payload;
      state.value.mobileMenuOpen = mobileMenuOpen;
    },
    setCurrentLessonDetails: (state, action) => {
      const { videoUrl, lessonId } = action.payload;
      let sectionName = "";
      let lessonTitle = "";
      let lessonDescription = "";
      let files = [];
      if (action?.payload?.sectionName) {
        sectionName = action.payload.sectionName;
        if (action.payload.files) {
          if (action.payload.files.includes(",")) {
            files = action.payload.files.split(",");
          } else {
            files.push(action.payload.files);
          }
        }
        lessonTitle = action.payload.lessonTitle;
        lessonDescription = action.payload.lessonDescription;

        state.value.currentLessonDetails = {
          videoUrl,
          lessonId,
          sectionName,
          files,
          lessonTitle,
          lessonDescription,
        };
      } else if (action?.payload?.from === "search") {
        // if user came from search mobile view we add from: search to the payload
        state.value.currentLessonDetails = {
          videoUrl,
          lessonId,
          from: "search",
        };
      } else {
        state.value.currentLessonDetails = {
          videoUrl,
          lessonId,
        };
      }
    },
    setUserStatistics: (state, action) => {
      const { stats } = action.payload;
      state.value.userStatistics = stats;
    },
    setLessonProgressAverage: (state, action) => {
      state.value.lessonProgressAverage = action.payload;
    },
    setLinksPopupOpen: (state, action) => {
      state.value.linksPopupOpen = action.payload;
    },
    setLinksRedux: (state, action) => {
      console.log(action, "action2");
      state.value.links = action.payload;
    },
  },
});

export const {
  handleSearchPopup,
  setAllLessons,
  setUserName,
  setSearchParams,
  setSearchResultsView,
  setFiles,
  setCurrentQuiz,
  setQuizFinished,
  setUserRatingIDFromDB,
  setMobileMenu,
  setCurrentLessonDetails,
  setUserStatistics,
  setLessonProgressAverage,
  setLinksPopupOpen,
  setLinksRedux,
} = userSlice.actions;
export default userSlice.reducer;
