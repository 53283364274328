import "./style/question-container.css";
import { BsTrashFill } from "react-icons/bs";
import { IconContext } from "react-icons";
import { useEffect, useState } from "react";
const EditQuizAnswers = ({
  answer,
  correct,
  answerId,
  quizData,
  setQuizData,
  lessonId,
  questionId,
  indexOfAnswer,
  indexOfQuestion,
  amountOfAnswers,
}) => {
  const handleAnswerChange = (e, context) => {
    if (context === "answer") {
      const newQuizData = JSON.parse(JSON.stringify(quizData)); // Create a deep copy of the quizData object
      newQuizData[indexOfQuestion].answers[indexOfAnswer].answer =
        e.target.value;
      setQuizData(newQuizData);
    } else if (context === "correct") {
      const newQuizData = JSON.parse(JSON.stringify(quizData)); // Create a deep copy of the quizData object
      newQuizData[indexOfQuestion].answers[indexOfAnswer].correct =
        e.target.checked;
      setQuizData(newQuizData);
    }
  };
  const handleRemoveAnswer = () => {
    const newQuizData = JSON.parse(JSON.stringify(quizData)); // Create a deep copy of the quizData object
    newQuizData[indexOfQuestion].answers.splice(indexOfAnswer, 1);
    setQuizData(newQuizData);
  };

  return (
    <>
      <div className="form-wrapper-edit-answer">
        <div className="answer-input">
          <input
            type="text"
            placeholder={!answer ? `answer ${indexOfAnswer + 1}` : ""}
            value={answer ? answer : ""}
            onChange={(e) => handleAnswerChange(e, "answer")}
          />
        </div>
        <div className="answer-correct">
          <input
            type="checkbox"
            checked={correct}
            onChange={(e) => handleAnswerChange(e, "correct")}
          />
          <label>Correct answer</label>
        </div>
        {amountOfAnswers > 2 && (
          <div className="remove-answer" onClick={handleRemoveAnswer}>
            <IconContext.Provider
              value={{
                size: "1rem",
                color: "#000000",
              }}
            >
              <BsTrashFill />
            </IconContext.Provider>
          </div>
        )}
      </div>
    </>
  );
};

export default EditQuizAnswers;
