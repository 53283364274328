import Logos from "./logos";
import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import { BsEyeFill } from "react-icons/bs";
import { passwordRegex } from "../../utils/regex";
import { toast } from "react-toastify";
// css for this component is in forgot-password.css
import "./style/forgot-password.css";
// additionally, we need to add the following to the forgot-password.css file:
import "./style/reset-password.css";
import resetPasswordService from "../../services/reset-password";
const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { token } = useParams();
  const firstPasswordField = useRef(null);
  const secondPasswordField = useRef(null);
  const navigate = useNavigate();

  const handlePasswordVisibility = (passwordField) => {
    if (passwordField === "top") {
      if (firstPasswordField.current.type === "password") {
        firstPasswordField.current.type = "text";
      } else {
        firstPasswordField.current.type = "password";
      }
    } else {
      if (secondPasswordField.current.type === "password") {
        secondPasswordField.current.type = "text";
      } else {
        secondPasswordField.current.type = "password";
      }
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    const isPasswordValid = passwordRegex.test(password);
    const isConfirmPasswordValid = passwordRegex.test(confirmPassword);

    if (password === "" || confirmPassword === "") {
      return setErrorMessage("Please Fill In Both Fields");
    }
    if (password !== confirmPassword) {
      return setErrorMessage("Passwords Do Not Match");
    }
    if (!isPasswordValid || !isConfirmPasswordValid) {
      return setErrorMessage("at least 9 characters 1 uppercase and a number");
    }

    try {
      const response = await resetPasswordService.resetPassword(
        password,
        token
      );
      if (response.message === "success") {
        toast.success("Password Reset Successfully");
        navigate("/login");
      } else {
        console.log(response, "response reset password");
        toast.error("Password Reset Failed");
      }
    } catch (err) {
      console.log(err, "err reset password");
    }
  };
  return (
    <>
      <Logos />
      <div className="reset-password-title">
        <h1>Reset Password</h1>
      </div>
      <div className="reset-password-container">
        <form className="reset-password-form">
          <div className="form-control">
            <label htmlFor="">
              <input
                ref={firstPasswordField}
                value={password}
                onChange={(e) => {
                  setErrorMessage("");
                  setPassword(e.target.value);
                }}
                type="password"
                placeholder="Enter New Password..."
              />
              <div
                className="icon"
                onClick={() => handlePasswordVisibility("top")}
              >
                <IconContext.Provider
                  value={{
                    color: "#ffffff",
                    size: "1rem",
                  }}
                >
                  <BsEyeFill />
                </IconContext.Provider>
              </div>
            </label>
          </div>
          <div className="form-control">
            <label htmlFor="">
              <input
                ref={secondPasswordField}
                type="password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setErrorMessage("");
                }}
                placeholder="confirm new password..."
              />
              <div
                className="icon"
                onClick={() => handlePasswordVisibility("bottom")}
              >
                <IconContext.Provider
                  value={{
                    color: "#ffffff",
                    size: "1rem",
                  }}
                >
                  <BsEyeFill />
                </IconContext.Provider>
              </div>
            </label>
          </div>
          <div className="error">{errorMessage}</div>
          <div className="form-submission">
            <button onClick={(e) => handleResetPassword(e)}> Submit</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ResetPassword;
