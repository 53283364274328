import { useEffect, useState } from "react";
import BackButton from "./backButton";
import { useSelector } from "react-redux";
import "./style/search-results-mobile.css";
import { sliceText } from "../../utils/sliceText";
import { IconContext } from "react-icons";
import { BsDownload } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";

import { useMediaQuery } from "react-responsive";
import lessonsService from "../../services/lessonsService";
import { setCurrentLessonDetails } from "../../redux/userReducer";
import { useDispatch } from "react-redux";
import ReactPlayer from "react-player";
import { file } from "jszip";
import fileService from "../../services/fileService";

import { toast } from "react-toastify";
const SearchResultsMobile = ({ setShowView }) => {
  const widthLessThen500Px = useMediaQuery({ query: "(max-width:450px)" });
  const [readMore, setReadMore] = useState(false);
  const [searchedItem, setSearchedItem] = useState({});

  const dispatch = useDispatch();

  const searchItemRedux = useSelector(
    (state) => state.allPermission.value.searchItemMobile
  );
  useEffect(() => {
    if (searchItemRedux.length) {
      setSearchedItem(searchItemRedux[0]);
    }
  }, [searchItemRedux]);

  const handleVideoClick = async (lessonId, videoUrl) => {
    if (!videoUrl && !lessonId) return;

    const insertWatchedVideo = await lessonsService.insertWatchedVideo(
      lessonId
    );
    if (insertWatchedVideo.message === "success") {
      const from = "search";
      dispatch(setCurrentLessonDetails({ videoUrl, lessonId, from }));
      setShowView("video-player");
      return;
    }
  };

  const handleDownload = async (files, title) => {
    if (files === null || files === "null" || files === "") return;

    let filesArray = [];
    if (files.includes(",")) {
      filesArray = files.split(",");
    } else {
      filesArray = [files];
    }

    if (!filesArray.length) return;

    try {
      const response = await fileService.downloadFiles(
        filesArray,
        `lesson-files`
      );
      console.log(response, "response SearchResultsMobile");
      if (response instanceof Blob) {
        if (response.size === 0) {
          throw new Error("Downloaded file is empty");
        }

        if (files.length > 1 && response.type !== "application/zip") {
          throw new Error("Expected a zip file for multiple files");
        }

        const anchor = document.createElement("a");
        anchor.href = URL.createObjectURL(response);
        anchor.download = `redefine-lesson-files-${title}${
          filesArray.length > 1 ? ".zip" : ""
        }`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(anchor.href);

        toast.success(
          `${filesArray.length > 1 ? "Files" : "File"} Downloaded Successfully`
        );
      } else {
        throw new Error("Invalid response type");
      }
    } catch (error) {
      console.log(error, "error in download file");
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <BackButton
        title={`Search in ${searchedItem.context}`}
        setShowView={setShowView}
        context={`search-${searchedItem.context}`}
      />

      {searchItemRedux.length && (
        <>
          <div className="search-term">{`Result For: ${searchedItem.title}`}</div>
        </>
      )}

      <div className="search-results-wrapper-mobile">
        {searchedItem.context === "lessons" && (
          <>
            <div className="lessons-search-results-mobile">
              <div className="main">
                <div className="left">
                  <div
                    className="title"
                    onClick={() =>
                      handleVideoClick(searchedItem.id, searchedItem.video)
                    }
                  >
                    {searchedItem.title}
                  </div>
                  <div className="description">
                    {searchedItem.text && sliceText(searchedItem.text, 80)}
                  </div>
                </div>
                <div className="right">
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    className="react-player"
                    url={searchedItem.video}
                    controls
                  />
                </div>
              </div>
              <div className="line"></div>
              <div className="bottom">
                <div className="video-length">7:34</div>

                <div className="download-attached-files">
                  Download attached files
                </div>

                <div className="download-btn">
                  <IconContext.Provider
                    value={{
                      color: "#ffffff",
                      size: widthLessThen500Px ? "18px" : "20px",
                    }}
                  >
                    <BsDownload />
                  </IconContext.Provider>
                </div>
              </div>
            </div>
          </>
        )}
        {searchedItem.context === "files" && (
          <>
            <div className="files-search-result-mobile">
              <div className="title">
                <span>{searchedItem.lesson_title}</span>
                <span className="download-weight">
                  {searchedItem.files_size}
                </span>
              </div>
              <div className="message">
                {searchedItem.lesson_text &&
                  sliceText(searchedItem.lesson_text, 80)}
              </div>

              <div
                className="download-btn"
                onClick={() =>
                  handleDownload(
                    searchedItem.lesson_files,
                    searchItemRedux.lesson_title
                  )
                }
              >
                <button>Download</button>
              </div>
            </div>
          </>
        )}
        {searchedItem.context === "messages" && (
          <>
            <div className="message-search-results-mobile">
              <div className="top">
                <div className="date-and-name">
                  <span>{searchedItem.full_name}</span> |{" "}
                  {searchedItem.created_at.substring(0, 10)}
                </div>

                {searchedItem.location !== "null" && (
                  <>
                    <div className="location">
                      <IconContext.Provider
                        value={{
                          color: "black",
                          className: "global-class-name",
                          size: "1.5em",
                        }}
                      >
                        <MdLocationOn />
                      </IconContext.Provider>
                      <span>{searchedItem.location} </span>
                    </div>
                  </>
                )}
              </div>
              <div className="title">
                <div>{searchedItem.title}</div>
              </div>

              <div className="message">
                {readMore
                  ? searchedItem.message
                  : sliceText(searchedItem.message, 30)}
              </div>
              <div className="bottom">
                <div className="left">
                  <button onClick={() => setReadMore(!readMore)}>
                    {readMore ? "Close" : "Read more"}
                  </button>
                </div>
                <div className="right">
                  <span className="files">
                    {searchedItem?.files?.length} | Files
                  </span>
                  <span>
                    <IconContext.Provider
                      value={{
                        color: "#CB2A27",
                        size: widthLessThen500Px ? "12px" : "18px",
                      }}
                    >
                      <BsDownload />
                    </IconContext.Provider>
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SearchResultsMobile;
