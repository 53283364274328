import axiosBaseUrl from "../utils/axios";
const messageService = {
  createMessage: async (message, authorization) => {
    try {
      const { data } = await axiosBaseUrl.post(
        `/messages/create/${authorization}`,
        message,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return data;
    } catch (err) {
      console.log(err, "create message error");
    }
  },
  fetchMessages: async () => {
    try {
      const { data } = await axiosBaseUrl.get("/messages/all");
      return data;
    } catch (err) {
      console.log(err, "fetch messages error");
    }
  },
  pinMessage: async (messageId, isPinned) => {
    try {
      const { data } = await axiosBaseUrl.put(
        `/messages/pinMessage/${messageId}`,
        { isPinned }
      );
      return data;
    } catch (err) {
      console.log(err, "set pinned message error");
    }
  },
  updateMessage: async (formData, messageId) => {
    try {
      const { data } = await axiosBaseUrl.put(
        `/messages/update/${messageId}`,
        formData
      );

      if (data.message === "success") {
        const { data } = await axiosBaseUrl.get("/messages/all");

        return data;
      }
    } catch (err) {
      console.log(err, "update message error");
    }
  },
  deleteMessage: async (messageId, files = null, directed_to1) => {
    try {
      const { data } = await axiosBaseUrl.post(
        `/messages/delete/${messageId}`,
        { files, directed_to1 }
      );
      if (data.message === "success") {
        const { data } = await axiosBaseUrl.get("/messages/all");
        console.log(
          data,
          "data from delete message after fetching new messages "
        );

        return data;
      }
    } catch (err) {
      console.log(err, "delete message error");
    }
  },
};

export default messageService;
