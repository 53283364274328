import { sliceText } from "../../utils/sliceText";
import React, { useState, useEffect } from "react";
import fileService from "../../services/fileService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLinksPopupOpen, setLinksRedux } from "../../redux/userReducer";
const CombinedDownloader = ({ lessonDetails }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const [showMessage, setShowMessage] = useState(false);
  const [lessonFiles, setLessonFiles] = useState([]);
  const [amountOfDownloads, setAmountOfDownloads] = useState(0);
  const [links, setLinks] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const countFilesAndLinks = (files, links) => {
      let fileCount = 0;
      let linkCount = 0;

      if (files && files !== "null") {
        fileCount = files.includes(",") ? files.split(",").length : 1;
      }

      if (Array.isArray(links)) {
        linkCount = links.length;
      }

      return { fileCount, linkCount };
    };
    const { lesson_files, lesson_links, lesson_title } = lessonDetails;
    if (lesson_files === null || lesson_files === "null") {
      setLessonFiles([]);
    } else {
      const filesArray = lesson_files.includes(",")
        ? lesson_files.split(",")
        : [lesson_files];
      setLessonFiles(filesArray);
    }

    if (Array.isArray(lesson_links)) {
      setLinks(lesson_links);
    } else {
      setLinks([]);
    }

    const { fileCount, linkCount } = countFilesAndLinks(
      lesson_files,
      lesson_links
    );
    setAmountOfDownloads(fileCount + linkCount);
  }, [lessonDetails]);
  // const openLinksInNewTabs = async (links) => {
  //   links.forEach(({ link }) => {
  //     const anchor = document.createElement("a");
  //     anchor.href = link;
  //     anchor.target = "_blank";
  //     anchor.rel = "noopener noreferrer"; // Security measure to prevent the new page from accessing window.opener
  //     document.body.appendChild(anchor);
  //     anchor.click();
  //     document.body.removeChild(anchor);
  //   });
  // };

  const handleDownload = async () => {
    const files = lessonFiles;

    if (!files.length) {
      toast.error("No Files To Download");
      return;
    }

    setIsDownloading(true);

    try {
      const response = await fileService.downloadFiles(files, `lesson-files`);

      if (response instanceof Blob) {
        if (response.size === 0) {
          throw new Error("Downloaded file is empty");
        }

        if (files.length > 1 && response.type !== "application/zip") {
          throw new Error("Expected a zip file for multiple files");
        }

        const anchor = document.createElement("a");
        anchor.href = URL.createObjectURL(response);
        anchor.download = `redefine-lesson-files-${lessonDetails.lesson_title}${
          files.length > 1 ? ".zip" : ""
        }`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(anchor.href);

        toast.success(`${files.length > 1 ? "Files" : "File"} Downloaded`);
      } else {
        throw new Error("Invalid response type");
      }
    } catch (error) {
      console.log(error, "error in download file");
      toast.error("Something went wrong");
    } finally {
      setIsDownloading(false);
    }
  };

  const handleLinks = () => {
    dispatch(setLinksPopupOpen(true));
    dispatch(setLinksRedux(links));
  };
  return (
    <>
      <div className="download">
        <div className="download-section-title">
          <span>{sliceText(lessonDetails.section_title, 16)}</span>
          <span className="download-amount">{amountOfDownloads}</span>
        </div>
        <div className="download-title">
          <span>{sliceText(lessonDetails.lesson_title, 16)}</span>
        </div>

        <div
          className={showMessage ? "download-message-open" : "download-message"}
          onClick={() => setShowMessage(!showMessage)}
        >
          {showMessage
            ? lessonDetails.lesson_text
            : sliceText(lessonDetails.lesson_text, 100)}
        </div>
        <div className="download-btn">
          <button onClick={handleDownload}>Download</button>
          {links.length ? <button onClick={handleLinks}>Links</button> : ""}
        </div>
      </div>
    </>
  );
};

export default CombinedDownloader;
