import { IconContext } from "react-icons";
import { BsUpload } from "react-icons/bs";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RiDeleteBin2Fill } from "react-icons/ri";

import lessonsService from "../../services/lessonsService";
import { setAllLessons } from "../../redux/userReducer";
import { setUploadPopupOpen } from "../../redux/superAdminReducer";
const AddLessonPopup = () => {
  const dispatch = useDispatch();
  const [focus, setFocus] = useState(null);
  const [error, setError] = useState("");
  const [files, setFiles] = useState([]);
  const [enableLessonLinks, setEnableLessonLinks] = useState(false);
  const [lessonLinks, setLessonLinks] = useState([{ link: "", title: "" }]);
  const [lessonForm, setLessonForm] = useState({
    title: "",
    subtitle: "",
    description: "",
    video: "",
    videoLength: "",
  });
  const sectionId = useSelector(
    (state) => state.superAdminSlice.value.uploadPopupForm.sectionId
  );

  useEffect(() => {}, []);

  const handleFiles = (e) => {
    const files = e.target.files;
    const filesArray = Array.from(files);
    setFiles(filesArray);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (lessonForm.title === "") {
      setFocus("title");
      setError("Title is required");
      return;
    }
    if (lessonForm.subtitle === "") {
      setFocus("subtitle");
      setError("Subtitle is required");
      return;
    }

    if (lessonForm.description === "") {
      setError("Description is required");
      setFocus("description");
      return;
    }
    const linkRegex =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

    if (enableLessonLinks) {
      for (let index = 0; index < lessonLinks.length; index++) {
        const link = lessonLinks[index];
        if (link.title === "") {
          setError(`${index + 1} Link Title Is Required`);
          return;
        }
        if (!linkRegex.test(link.link) || link.link === "") {
          setError(`${index + 1} Enter a valid Link`);
          return;
        }
      }
    }
    if (lessonForm.video === "") {
      setError("Video Url is required");
      setFocus("video");
      return;
    }

    const urlRegex =
      /^https?:\/\/(?:www\.)?(?:(?:youtube\.com\/(?:watch\?(?:.*&)?v=|embed\/|shorts\/)|youtu\.be\/)|(?:vimeo\.com\/(?:channels\/[\w]+\/|groups\/[\w]+\/videos\/|album\/\d+\/video\/|video\/)))([a-zA-Z0-9_-]{11}|\d+)(?:[?&].*)?$/;

    const isUrlValid = urlRegex.test(lessonForm.video);
    if (!isUrlValid) {
      setError("Video Url is not valid");
      setFocus("video");
      return;
    }

    if (lessonForm.videoLength === "") {
      setError("Video Length is required");
      setFocus("videoLength");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("title", lessonForm.title);
      formData.append("subtitle", lessonForm.subtitle);
      formData.append("description", lessonForm.description);
      formData.append("video", lessonForm.video);
      formData.append("videoLength", lessonForm.videoLength);

      if (files.length) {
        for (let i = 0; i < files.length; i++) {
          // const filename = files[i].name.replace(/\s+/g, "_");
          const filename = encodeURIComponent(
            files[i].name.replace(/\s+/g, "_")
          );

          const fileBlob = new Blob([files[i]], { type: files[i].type });

          formData.append(`file-${i}`, fileBlob, filename);
        }
      }

      if (enableLessonLinks) {
        formData.append("embedded_links_array", JSON.stringify(lessonLinks));
      } else {
        formData.append("embedded_links_array", JSON.stringify([]));
      }

      // if (linkArray.length) {
      //   formData.append("embedded_links_array", JSON.stringify(linkArray));
      // }

      const response = await lessonsService.createLesson(formData, sectionId);
      if (response.message === "success") {
        toast.success("Lesson added successfully");
        dispatch(setUploadPopupOpen(false));
        dispatch(setAllLessons({ lessons: response.lessons }));
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err, "add lesson");
    }
  };

  return (
    <>
      <div className="all-lesson-wrapper">
        <input
          type="text"
          placeholder="Title"
          value={lessonForm.title}
          autoFocus={focus === "title"}
          onChange={(e) =>
            setLessonForm((prevState) => ({
              ...prevState,
              title: e.target.value,
            }))
          }
        />
      </div>

      <div className="all-lesson-wrapper">
        <input
          type="text"
          placeholder="Subtitle"
          autoFocus={focus === "subtitle"}
          value={lessonForm.subtitle}
          onChange={(e) =>
            setLessonForm((prevState) => ({
              ...prevState,
              subtitle: e.target.value,
            }))
          }
        />
      </div>

      <div className="all-lesson-wrapper">
        <textarea
          type="text"
          rows="3"
          placeholder="Description"
          autoFocus={focus === "description"}
          value={lessonForm.description}
          onChange={(e) =>
            setLessonForm((prevState) => ({
              ...prevState,
              description: e.target.value,
            }))
          }
        >
          {" "}
        </textarea>
      </div>

      {files.length > 0 && (
        <>
          <div className="all-lesson-wrapper files-uploaded">
            {`Files attached: ${files.length}`}
          </div>
        </>
      )}

      <div className="upload-lesson">
        <div>
          <IconContext.Provider
            value={{
              color: "#ffffff",
              className: "global-class-name",
              size: "1em",
            }}
          >
            <BsUpload />
          </IconContext.Provider>
        </div>

        <label htmlFor="lesson-files" className="attach-files-label">
          <input
            type="file"
            className="hidden"
            multiple
            name="lesson-files"
            id="lesson-files"
            placeholder="Files"
            accept=".pdf,.jpg,.jpeg,.png,.gif"
            onChange={(e) => handleFiles(e)}
            // onChange={(e) => setFiles(e.target.files)}
          />
          Attach Files
        </label>

        <span className="error"></span>
      </div>

      {!enableLessonLinks ? (
        <>
          <div
            className="enable_links"
            onClick={() => setEnableLessonLinks(!enableLessonLinks)}
          >
            Add Links
          </div>
        </>
      ) : (
        <>
          {lessonLinks.map((lessonLink, index) => {
            const isLastItem = index === lessonLinks.length - 1;

            return (
              <div
                className="all-lesson-wrapper"
                style={{ marginBottom: isLastItem ? "20px" : "0" }}
                key={index}
              >
                <input
                  type="text"
                  placeholder={`${index + 1} Link Title`}
                  value={lessonLink.title}
                  onChange={(e) => {
                    const newLessonLinks = [...lessonLinks];
                    newLessonLinks[index].title = e.target.value;
                    setLessonLinks(newLessonLinks);
                  }}
                />
                <input
                  type="text"
                  placeholder={`${index + 1} Embedded Link `}
                  value={lessonLink.link}
                  onChange={(e) => {
                    const newLessonLinks = [...lessonLinks];
                    newLessonLinks[index].link = e.target.value;
                    setLessonLinks(newLessonLinks);
                  }}
                />
                <div className="links">
                  <div
                    className="add_link"
                    onClick={() => {
                      setLessonLinks((prevState) => [
                        ...prevState,
                        { link: "", title: "" },
                      ]);
                    }}
                  >
                    Add Link
                  </div>

                  <div
                    className="remove_link"
                    onClick={() => {
                      if (lessonLinks.length === 1) {
                        setEnableLessonLinks(false);
                        setLessonLinks([{ link: "", title: "" }]);
                        return;
                      }
                      const newLessonLinks = [...lessonLinks];
                      newLessonLinks.splice(index, 1);
                      setLessonLinks(newLessonLinks);
                    }}
                  >
                    <span style={{ marginTop: "10px", display: "block" }}>
                      <RiDeleteBin2Fill size={"30px"} color="#cb2a27" />
                    </span>
                  </div>
                </div>

                <span className="error"></span>
              </div>
            );
          })}
        </>
      )}

      {/* <div className="all-lesson-wrapper">
        <input
          type="text"
          placeholder="Embedded Links"
          value={lessonForm.video}
          autoFocus={focus === "video"}
          onChange={(e) => {
            setLessonForm((prevState) => ({
              ...prevState,
              video: e.target.value,
            }));
          }}
        />
        <span className="error"></span>
      </div> */}
      <div className="all-lesson-wrapper">
        <input
          type="text"
          placeholder="Video Url"
          value={lessonForm.video}
          autoFocus={focus === "video"}
          onChange={(e) => {
            setLessonForm((prevState) => ({
              ...prevState,
              video: e.target.value,
            }));
          }}
        />
        <span className="error"></span>
      </div>
      <div className="all-lesson-wrapper">
        <input
          type="number"
          placeholder="Video Length in seconds"
          value={lessonForm.videoLength}
          autoFocus={focus === "videoLength"}
          onChange={(e) => {
            setLessonForm((prevState) => ({
              ...prevState,
              videoLength: e.target.value,
            }));
          }}
        />
      </div>
      <div className="error">{error}</div>

      <div className="btn-wrapper">
        <button className="add-lesson-btn" onClick={(e) => handleFormSubmit(e)}>
          Submit
        </button>
      </div>
    </>
  );
};

export default AddLessonPopup;
