import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import { useMediaQuery } from "react-responsive";

// import { AiFillStar, AiOutlineStar } from "react-icons/ai";
// import { BsStarHalf } from "react-icons/bs";
// import { IconContext } from "react-icons/lib";
import ReactStars from "react-rating-stars-component";
import "./style/statistics.css";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import BackButton from "./backButton";
import PieChart from "./statistics-chart";
const Statistics = React.memo((props) => {
  const [average, setAverage] = useState(0);
  // const [reactStars, setReactStars] = useState(0);
  const [chartData, setChartData] = useState({});
  const desktopView = useMediaQuery({ query: "(min-width:740px)" });
  const [amountOfUsers, setAmountOfUsers] = useState(0);
  const [tempRating, setTempRating] = useState([]);

  const [badRatingResults, setBadRatingResults] = useState([]);
  const [listOfDistributors, setListOfDistributors] = useState([]); // [
  const [listOfUsers, setListOfUsers] = useState([]); // [
  const authorization = useSelector(
    (state) => state.allPermission.value.authorization
  );
  // const dispatch = useDispatch();
  useEffect(() => {
    setListOfDistributors([]);
    if (authorization === "super-admin") {
      setAmountOfUsers(props.amountOfRegisteredUsers);

      if (props?.adminStats?.length) {
        const results = [];
        props.adminStats.forEach((distributor) => {
          if (!distributor.currentUser) {
            const { distributor_id, user_ids, distributor_title } = distributor;

            const totalEndedLessonCount = user_ids.reduce(
              (total, user) => total + user.endedLessonCount,
              0
            );

            const distributorResult =
              (totalEndedLessonCount / props.amountOfLessons) * user_ids.length;

            results.push({
              distributor_id,
              distributor_title,
              completionPercentage: parseFloat(distributorResult.toFixed(1)),
            });
          }
        });

        setListOfDistributors((prevList) => [
          ...prevList,
          ...results.map((result) => ({
            id: result.distributor_id,
            distributor_name: result.distributor_title,
            completionPercentage: result.completionPercentage,
          })),
        ]);
        setChartData(results);
      }
    } else if (authorization === "admin") {
      if (props?.adminStats?.length) {
        function calculateSectionCompletionPercentage(adminStats) {
          const userStats = [];
          setAmountOfUsers(adminStats.length);
          adminStats.forEach((user) => {
            const { userId, stats, userEmail, userName } = user;

            let totalLessons = 0;
            let totalEndedLessons = 0;

            stats.forEach((section) => {
              const { amountOfLessons, lessonsEnded } = section;
              totalLessons += amountOfLessons;
              totalEndedLessons += lessonsEnded;
            });

            const completionPercentage =
              (totalEndedLessons / totalLessons) * 100;
            userStats.push({
              userId,
              userEmail,
              userName,
              completionPercentage: parseFloat(completionPercentage.toFixed(1)),
            });
          });

          return userStats;
        }

        const userCompletionPercentages = calculateSectionCompletionPercentage(
          props.adminStats
        );

        setListOfUsers(userCompletionPercentages);
        setChartData(userCompletionPercentages);
      }
    }
  }, [authorization, props?.adminStats]);

  const lessonIdGroups = {};
  useEffect(() => {
    if (props.rating) {
      let parsedNumber = 0;
      if (average) {
        parsedNumber = parseFloat(props.rating.average.toFixed(1));
      }

      setAverage(parsedNumber);
    }

    if (props.rating?.badRating !== "No bad rating yet") {
      setTempRating(props.rating.badRating);
    }
  }, [props?.rating?.average, props?.rating?.badRating, authorization]);

  useEffect(() => {
    if (tempRating?.length) {
      if (authorization === "admin") {
        tempRating?.forEach((lesson) => {
          const lessonId = lesson.lessonId;
          if (lessonIdGroups.hasOwnProperty(lessonId)) {
            lessonIdGroups[lessonId].amountOfVotes++;
            lessonIdGroups[lessonId].userIds.push(lesson.userId);
          } else {
            lessonIdGroups[lessonId] = {
              lessonId: lessonId,
              lessonTitle: lesson.lessonTitle,
              sectionTitle: lesson.sectionTitle,
              sectionNumber: lesson.sectionNumber,
              userIds: [lesson.userId],
              amountOfVotes: 1,
            };
          }
        });
      } else if (authorization === "super-admin") {
        tempRating.forEach((lesson) => {
          const lessonId = lesson.lesson_id;

          if (!lessonIdGroups[lessonId]) {
            lessonIdGroups[lessonId] = {
              lessonId: lessonId,
              lessonTitle: lesson.lesson_title,
              sectionTitle: lesson.section_title,
              sectionNumber: lesson.section_number,
              amountOfVotes: 1,
            };
          } else {
            lessonIdGroups[lessonId].amountOfVotes++;
          }
        });

        const superAdminBadRating = Object.values(lessonIdGroups);

        setBadRatingResults(superAdminBadRating);
      }
    }
  }, [tempRating]);

  useEffect(() => {
    if (Object.keys(lessonIdGroups).length) {
      const resultsArray = Object.values(lessonIdGroups);
      setBadRatingResults(resultsArray);
    }
  }, [lessonIdGroups]);

  return (
    <>
      <div
        className={
          desktopView ? "statistics-wrapper" : "mobile-statistics-wrapper"
        }
      >
        {desktopView && (
          <>
            <div className="statistics-desktop-title">
              <h3 className="stat-title">Statistics:</h3>

              <div className="registered-users">
                <h3>Registered users: {amountOfUsers}</h3>
                {authorization === "super-admin" && (
                  <>
                    <div className="registered-distributors">
                      <h3>Registered Partners: {chartData.length}</h3>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
        <div className={desktopView ? "chart" : "chart mobile-chart"}>
          <div className="doughnut-wrapper">
            <PieChart data={chartData} />
          </div>
          {desktopView && (
            <div className="score">
              <div className="score-title">
                <h3>{`Average score: ${average}`}</h3>
              </div>
              <div className="rating-desktop">
                <ReactStars
                  key={`stars_${average}`}
                  count={5}
                  isHalf={true}
                  size={40}
                  activeColor="#CB2A27"
                  classNames={"stars"}
                  value={average}
                  edit={false}
                />
              </div>
            </div>
          )}
        </div>
        {!desktopView && (
          <>
            <div className="registered-users-mobile">
              <h3>Registered users: {amountOfUsers}</h3>
              {authorization === "super-admin" && (
                <>
                  <h3>Registered Partners: {chartData.length}</h3>
                </>
              )}
            </div>
          </>
        )}

        <div className="finish-status">
          <div className="not-started finish">
            <div className="circle not-started-circle "></div>
            <span>finished 0%</span>
          </div>
          <div className="white finish">
            <div className="circle white-circle "></div>
            <span>finished 20%</span>
          </div>
          <div className="dark-red finish">
            <div className="circle dark-red-circle "></div>
            <span>finished 50%</span>
          </div>

          <div className="light-red finish">
            <div className="circle light-red-circle "></div>
            <span>finished 70%</span>
          </div>
          <div className="grey finish">
            <div className="circle grey-circle "></div>
            <span>finished 100%</span>
          </div>
        </div>

        <>
          {authorization === "super-admin" ? (
            <>
              <div className="list-of-users">
                <h3>Partners Lesson Progress </h3>

                <div className="list-header">
                  <span>Partner Name</span>
                  <span>Completion Percentage</span>
                </div>
                {listOfDistributors
                  .filter((dist) => dist.distributor_name !== "redefine")
                  .filter((dist) => dist.distributor_name !== "Redefine")
                  .map((distributor) => {
                    return (
                      <div key={distributor.id} className="list-body">
                        <div className="distributor-name">
                          {distributor.distributor_name}
                        </div>
                        <div className="distributor_completion">
                          {distributor.completionPercentage}%
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
          ) : (
            // for admin
            <>
              <div className="list-of-users">
                <h3>Users Lesson Progress </h3>

                <div className="list-header">
                  <span>User Email</span>
                  <span>Completion Percentage</span>
                </div>

                {listOfUsers.map((user) => {
                  return (
                    <div key={user.userId} className="list-body">
                      <div className="distributor-name">{user.userEmail}</div>
                      <div className="distributor_completion">
                        {user.completionPercentage}%
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </>

        {badRatingResults.length && desktopView && (
          <>
            <div className="lessons-with-bad-rating">
              <h3>Lessons rated under 3 stars</h3>

              <div className="list-header">
                <span>Lesson title</span>
                <span>Section name</span>
                <span>No of votes</span>
              </div>

              {badRatingResults.map((lesson) => {
                return (
                  <div key={lesson.lessonId} className="list-body">
                    <span className="lesson-title">{lesson.lessonTitle}</span>
                    <span>{lesson.sectionTitle}</span>
                    <span className="votes">{lesson.amountOfVotes}</span>
                  </div>
                );
              })}
            </div>
          </>
        )}

        {!desktopView && (
          // mobile
          <>
            <div className="score">
              <div className="score-title">
                <h3>{`Average score: ${average}`}</h3>
              </div>
              <div className="rating">
                <ReactStars
                  key={`stars_${average}`}
                  count={5}
                  isHalf={true}
                  value={average}
                  activeColor="#CB2A27"
                  classNames={"stars"}
                  edit={false}
                  size={40}
                />
              </div>
            </div>
            {badRatingResults.length && !desktopView && (
              <>
                <div className="lessons-with-bad-rating-mobile">
                  <h3>Lessons rated under 3 stars</h3>

                  <div className="list-header">
                    <span>Lesson Name </span>
                    <span>Section Name </span>
                    <span>No Of Votes</span>
                  </div>

                  {badRatingResults.map((lesson) => {
                    return (
                      <div key={lesson.lessonId} className="list-body">
                        <span className="lesson-title">
                          {lesson.lessonTitle}
                        </span>
                        <span>{lesson.sectionTitle}</span>
                        <span className="votes">{lesson.amountOfVotes}</span>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
});

export default Statistics;
